import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../../Components/Spinners/Spinner'
import Context from '../../Context';
import LoginModal from '../../Modals/LoginModal/LoginModal'
import config from '../../utils/config';
import axios from 'axios';
import './ProfileScreen.css'
import MobileVerificationModal from '../../Modals/MobileVerificationModal/MobileVerificationModal';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import sendLogs from '../../utils/telemetry';
import { useSearchParams } from 'react-router-dom';
export default function ProfileScreen() {

    const { state, setState } = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const loadData = async () => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/users/getUser`, {
                headers: {
                    "Authorization": `Bearer ${state.token}`
                }
            });
            setUser(resp.data.response.data.user);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.message || "Error getting Sessions", config.toastrOption);
        }
    }

    React.useEffect(() => {
        if (state.isLoggedIn) {
            loadData();
        }
        setState({ ...state, currentScreen: "Account" });
        sendLogs("View Profile", true, state.user, "Started Viewing Profile", null);

    }, [state.isLoggedIn])

    const degrees = [
        "Intermediate",
        "High School",
        "Bachelor of Science",
        "Bachelor of Arts",
        "Bachelor of Commerce",
        "Bachelor of Technology",
        "Bachelor of Engineering",
        "Bachelor of Medicine",
        "Bachelor of Business Administration",
        "Masters of Science",
        "Masters of Arts",
        "Masters of Commerce",
        "Masters of Technology",
        "Masters of Engineering",
        "Masters of Medicine",
        "Masters of Business Administration",
    ]

    const years = [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027];

    const handleSuccessVerify = () => {
        loadData();
    }

    const handleForgotPassword = async () => {
        try {
            setIsLoading(true);
            await sendPasswordResetEmail(getAuth(), user.email);
            toast.success("Password reset link has been sent to your email", config.toastrOption);
            setIsLoading(false);
            return;
        } catch (error) {
            setIsLoading(false);
            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).")
                return toast.error("User not found", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);

            console.log(error)
            toast.error("Something went wrong", config.toastrOption);
        }
    }

    const handleSubmit = async () => {

        if (!user.name.match(/^[a-zA-Z ]+$/)) {
            toast.error("Please enter a valid name", config.toastrOption);
            setErrorMessage("Please enter a valid name");
            return;
        }

        if (!user.mobile && !user.mobile.match(/^[+]{1}[0-9]{1}[0-9]{9,}$/)) {
            toast.error("Please enter a valid phone number", config.toastrOption);
            setErrorMessage("Please enter a valid phone number");
            return;
        }

        if (user.last_degree === "na") {
            toast.error("Please select your Highest Educational Degree", config.toastrOption);
            setErrorMessage("Please select your Highest Educational Degree");
            return;
        }

        if (!user.last_college) {
            toast.error("Please enter your Last College Attended", config.toastrOption);
            setErrorMessage("Please enter your Last College Attended");
            return;
        }

        if (user.last_degree_year === "na") {
            toast.error("Please enter your Last Year of Study", config.toastrOption);
            setErrorMessage("Please enter your Last Year of Study");
            return;
        }

        if (user.is_currently_working + "" === "true") {
            if (!user.current_company) {
                toast.error("Please enter your Current Company", config.toastrOption);
                setErrorMessage("Please enter your Current Company");
                return;
            }
            if (!user.current_position) {
                toast.error("Please enter your Current Position", config.toastrOption);
                setErrorMessage("Please enter your Current Position");
                return;
            }
        }

        try {
            const resp = await axios.put(`${config.baseUrl}/users/profile`, user, {
                headers: {
                    "Authorization": `Bearer ${state.token}`
                }
            });
            if (resp.data.response.status === 200) {
                toast.success("Profile updated successfully", config.toastrOption);
                searchParams.delete("showMessage")
                loadData();
            } else {
                toast.error(resp.data.response.message, config.toastrOption);
            }
        } catch (error) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message || "Error updating profile", config.toastrOption);
        }
    }

    React.useEffect(() => {
        return () => {
            sendLogs("Left Profile Page", false, {}, {}, null);
            return null
        }
    }, [])

    return (
        <div className="main-container">
            {
                state.isLoggedIn ? <div className='container-fluid'>
                    {
                        searchParams.get("showMessage") === "true" && <div className="alert alert-success" role="alert" style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "20px",
                            fontWeight: "bold",
                            paddingRight: "40px"
                        }}>
                            Please fill out the details to continue !
                            {/* <i className='fa fa-cross'></i> */}
                        </div>
                    }
                    <Spinner show={isLoading} />
                    <ToastContainer />
                    {
                        user && <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="card">
                                    <div className="card-header">
                                        <div data-target="#imageModal" data-toggle="modal" className='profile-img-container'>
                                            <img className='card-img-top profile-image' src={user.avatar ? `${config.baseUrl}/${user.avatar}` : "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"} alt={user.name} />
                                        </div>

                                        {/* <span className='p-small'>* Click to edit profile image</span> */}
                                    </div>
                                </div>


                                <div className="card-body profile-card-body">
                                    <h4 className='card-title'>Personal Details</h4>
                                    <div className="row mt-3">
                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <input
                                                    value={user.name}
                                                    onChange={(e) => {
                                                        const newUser = { ...user, name: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    id="name"
                                                    name='name'
                                                    type="text"
                                                    className={"form-control-input" + (user.name ? ' notEmpty' : '')}
                                                />
                                                <label className="label-control" htmlFor="name">Name</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <input
                                                    defaultValue={user.email}
                                                    id="email"
                                                    name='email'
                                                    type="text"
                                                    readOnly={true}
                                                    className={"form-control-input" + (user.email ? ' notEmpty' : '')}
                                                />
                                                <label className="label-control" htmlFor="email">Email</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <input
                                                    value={user.mobile}
                                                    id="mobile"
                                                    name='mobile'
                                                    type="text"
                                                    onChange={(e) => {
                                                        const newUser = { ...user, mobile: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    className={"form-control-input" + (user.mobile ? ' notEmpty' : '')}
                                                />
                                                <label className="label-control" htmlFor="mobile">Mobile</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            {
                                                user.is_mobile_verified ? <></>
                                                    : <button data-toggle="modal" data-target="#mobileVerifyModal" className='btn btn-primary'>Verify Mobile</button>

                                            }
                                        </div>
                                        <div className="col-md-6 text-black col-sm-12 mt-3">

                                            <button onClick={handleForgotPassword} className='btn btn-primary'>Reset Password</button>

                                        </div>
                                    </div>

                                </div>

                                <div className="card-body profile-card-body">
                                    <h4 className='card-title'>Educational Details</h4>
                                    <div className="row mt-3">
                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <select
                                                    value={user.is_current_student}
                                                    onChange={(e) => {
                                                        console.log(e.target.value)
                                                        const newUser = { ...user, is_current_student: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    className={"form-control-input" + (user.is_current_student ? ' notEmpty' : '')}>
                                                    <option disabled value="na">Select</option>
                                                    <option value={true}> Yes</option>
                                                    <option value={false}> No</option>
                                                </select>
                                                <label className="label-control" htmlFor="is_current_student">Are you a student ?</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <select
                                                    value={user.last_degree}
                                                    onChange={(e) => {
                                                        const newUser = { ...user, last_degree: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    className={"form-control-input" + (user.last_degree ? ' notEmpty' : '')}>
                                                    <option disabled value="na">Select</option>
                                                    {
                                                        degrees.map((degree, index) => {
                                                            return <option key={index} value={degree}>{degree}</option>
                                                        })
                                                    }
                                                </select>
                                                <label className="label-control" htmlFor="last_degree">Highest Educational Degree</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <input
                                                    value={user.last_college}
                                                    onChange={(e) => {
                                                        const newUser = { ...user, last_college: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    id="last_college"
                                                    name='last_college'
                                                    type="text"
                                                    className={"form-control-input" + (user.last_college ? ' notEmpty' : '')}
                                                />
                                                <label className="label-control" htmlFor="last_college">College or School Name</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <select
                                                    value={user.last_degree_year}
                                                    onChange={(e) => {
                                                        const newUser = { ...user, last_degree_year: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    className={"form-control-input" + (user.last_degree_year ? ' notEmpty' : '')}>
                                                    <option disabled value="na">Select</option>
                                                    {
                                                        years.map((degree, index) => {
                                                            return <option key={index} value={degree}>{degree}</option>
                                                        })
                                                    }
                                                </select>
                                                <label className="label-control" htmlFor="last_degree_year">Graduation Year</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="card-body profile-card-body">
                                    <h4 className='card-title'>Professional Work Experience</h4>
                                    <div className="row mt-3">
                                        <div className="col-md-6 text-black col-sm-12 mt-3">
                                            <div className="form-group">
                                                <select
                                                    value={user.is_currently_working + ""}
                                                    onChange={(e) => {
                                                        const newUser = { ...user, is_currently_working: e.target.value }
                                                        setUser(newUser);
                                                    }}
                                                    className={"form-control-input" + (user.is_currently_working ? ' notEmpty' : '')}>
                                                    <option disabled value="na">Select</option>
                                                    <option value={"true"}> Yes</option>
                                                    <option value={"false"}> No</option>
                                                </select>
                                                <label className="label-control" htmlFor="is_current_student">Are you currently working ?</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>



                                        {
                                            user.is_currently_working + "" === "true" && <>
                                                <div className="col-md-6 text-black col-sm-12 mt-3">
                                                    <div className="form-group">
                                                        <input
                                                            value={user.current_company}
                                                            onChange={(e) => {
                                                                const newUser = { ...user, current_company: e.target.value }
                                                                setUser(newUser);
                                                            }}
                                                            id="current_company"
                                                            name='current_company'
                                                            type="text"
                                                            className={"form-control-input" + (user.current_company ? ' notEmpty' : '')}
                                                        />
                                                        <label className="label-control" htmlFor="current_company">Company Name</label>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 text-black col-sm-12 mt-3">
                                                    <div className="form-group">
                                                        <input
                                                            value={user.current_position}
                                                            onChange={(e) => {
                                                                const newUser = { ...user, current_position: e.target.value }
                                                                setUser(newUser);
                                                            }}
                                                            id="current_position"
                                                            name='current_position'
                                                            type="text"
                                                            className={"form-control-input" + (user.current_position ? ' notEmpty' : '')}
                                                        />
                                                        <label className="label-control" htmlFor="current_position">Current Position</label>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                </div>

                                <div className="card-footer text-center">
                                    <button type="button" onClick={handleSubmit} className="btn btn-block btn-primary pl-4 pr-4">Update Details</button>
                                </div>

                            </div>
                        </div>
                    }

                    <div className="modal" id="mobileVerifyModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <MobileVerificationModal onSuccess={handleSuccessVerify} /> :
                    </div>

                </div> : <LoginModal />
            }



        </div>
    )
}
