import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "./Curriculum.css"

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ sections }) {
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            {
                sections.map((section, index) => {
                    return (

                        <Accordion
                            style={{
                                backgroundColor: '#fff',
                                marginBottom: '1rem',
                                border: '1px solid #e0e0e0',
                                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
                                width: '100%',
                                borderColor: expanded === `panel${index + 1}` ? '#8710d8' : '#e0e0e0',
                            }} key={index} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                            <AccordionSummary
                                style={{
                                    backgroundColor: '#fff',
                                    fontSize: '1rem',
                                    padding: '0.5rem 1.5rem',
                                    backgroundColor: expanded === `panel${index + 1}` ? "#8710d8" : "#fff",
                                }}
                                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem', color: expanded === `panel${index + 1}` ? "#fff" : "#000" }} />}
                                aria-controls={`panel${index + 1}-content`}
                                id={`panel${index + 1}-header`}
                            >

                                <Typography>
                                    <span className='text-black module-section-name' style={{

                                        color: expanded === `panel${index + 1}` ? "#fff" : "#000",
                                    }}>{section.name}
                                    </span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <ul> */}
                                {
                                    section.topics.map((topic, index) => {
                                        return <li key={index} className='row mb-4'>
                                            <div className="col-12">
                                                <p className='topic-heading'>{topic.name}</p>
                                                <span className='topic-desc'>{topic.description}</span>
                                            </div>
                                        </li>
                                    })
                                }
                                {/* </ul> */}
                            </AccordionDetails>
                        </Accordion>

                    );
                })
            }
        </div>
    );
}
