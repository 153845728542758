import axios from 'axios';
import React from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../../../Components/Spinners/Spinner';
import Context from '../../../Context';
import config from '../../../utils/config';
import { Link } from "react-router-dom";
import LoginModal from '../../../Modals/LoginModal/LoginModal';
import MobileVerificationModal from '../../../Modals/MobileVerificationModal/MobileVerificationModal';
import SessionCard from '../SessionCard/SessionCard';

export default function DemoSessions() {

    const [sessions, setSessions] = React.useState([]);
    const { state, setState } = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(false);


    const loadSessions = async () => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/sessions/demo`);
            setSessions(resp.data.response.data);
            setIsLoading(false)
        } catch (error) {
            console.log(error.response.data.message);
            setIsLoading(false);
            toast.error(error.response.data.message || "Error getting Sessions", config.toastrOption);
        }
    }

    React.useEffect(() => {
        loadSessions();
    }, [])

    return (
        <>
            <Spinner show={isLoading} />
            <ToastContainer />
            <div className="main-container">
                {/* {
                    state.isLoggedIn ? state.user.is_mobile_verified ? <div className='container-fluid'> */}
                <div className="row">
                    <div className="col-md-12">
                        <h1 className='h3'>Demo Sessions</h1>
                    </div>
                </div>
                <div className="row mt-3">
                    {
                        sessions.map((session, index) => {
                            return <SessionCard index={index} course={null} session={session} />
                        })}
                </div>
                {/* </div> : <MobileVerificationModal /> : <LoginModal hideCross={true} />
                } */}

                <div className="row mt-4">
                    <div className="col-md-12 mt-4">
                        <p className='p-large'>
                            * Sessions will be unlocked 15 minutes before the scheduled time.
                        </p>
                    </div>
                    <div className="col-md-12 mt-4">
                        <p className='p-large'>
                            Have a question ? Check our discussion forum, ask question and get help from the Skills Stack Community.
                        </p>
                        <Link to={"/discussion"} className='btn btn-primary'>Go to Discussion Forum {" "}  {" "}<i className="fas fa-arrow-right"></i></Link>
                    </div>
                </div>

            </div>

        </>
    )
}
