import React from 'react'
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

export default function Code({ config }) {
    return (
        <Editor
            height="75vh"
            language={config.language}
            value={config.chilren}
            theme={config.theme}

            options={{
                "acceptSuggestionOnCommitCharacter": true,
                "acceptSuggestionOnEnter": "on",
                "accessibilitySupport": "auto",
                "autoIndent": false,
                "automaticLayout": true,
                "codeLens": true,
                "colorDecorators": true,
                "contextmenu": true,
                "cursorBlinking": "blink",
                "cursorSmoothCaretAnimation": false,
                "cursorStyle": "line",
                "disableLayerHinting": false,
                "disableMonospaceOptimizations": false,
                "dragAndDrop": false,
                "fixedOverflowWidgets": false,
                "folding": true,
                "foldingStrategy": "auto",
                "fontLigatures": false,
                "formatOnPaste": false,
                "formatOnType": false,
                "hideCursorInOverviewRuler": false,
                "highlightActiveIndentGuide": true,
                "links": true,
                "mouseWheelZoom": true,
                "multiCursorMergeOverlapping": true,
                "multiCursorModifier": "alt",
                "overviewRulerBorder": true,
                "overviewRulerLanes": 2,
                "quickSuggestions": true,
                "quickSuggestionsDelay": 100,
                "readOnly": false,
                "renderControlCharacters": false,
                "renderFinalNewline": true,
                "renderIndentGuides": true,
                "renderLineHighlight": "all",
                "renderWhitespace": "none",
                "revealHorizontalRightPadding": 30,
                "roundedSelection": true,
                "rulers": [],
                "scrollBeyondLastColumn": 5,
                "scrollBeyondLastLine": true,
                "selectOnLineNumbers": true,
                "selectionClipboard": true,
                "selectionHighlight": true,
                "showFoldingControls": "mouseover",
                "smoothScrolling": false,
                "suggestOnTriggerCharacters": true,
                "wordBasedSuggestions": true,
                "wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
                "wordWrap": "on",
                "wordWrapBreakAfterCharacters": "\t})]?|&,;",
                "wordWrapBreakBeforeCharacters": "{([+",
                "wordWrapBreakObtrusiveCharacters": ".",
                "wordWrapColumn": 80,
                "wordWrapMinified": true,
                "wrappingIndent": "none"
            }}
        />
    )
}
