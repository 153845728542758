import React from 'react'

export default function NotFoundScreen() {
    return (
        <div className='main-container'>
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </div>
        
    )
}
