import React from 'react'
import Context from "../../Context";

export default function RefundAndCancellationPolicy() {
  const { state, setState } = React.useContext(Context);

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    document.title = `Skills Stack - Privacy Policy`;

    setState({
      ...state,
      currentScreen: "Privacy Policy"
    })
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-4">
          {/* <h3 className="text-center mb-4">Our Refund & Cancellation Policy</h3> */}
          <h1 className='text-center mb-4'>Refund & Cancellation Policy</h1>
          <p className='p-small'>Last updated: August 13, 2022</p>
          <p className='p-small'>Thank you for choosing <b>Skills Stack</b> as your learning partner.</p>

          <p className='p-small'>The following terms are applicable for any products that You purchased with Us.</p>

          <h3>General Payment Terms and Refund Policy</h3>
          <p className='p-small'> It is the sole responsibility of the user enrolling for a Program to check and confirm various program features, details, accuracy and the suitability of the Program elected. The course or workshop fee is not refundable once admission is confirmed.</p>

          <p className='p-small'> In case of any difficulty or feedback, please write to our support team at team@skillsstack.in. In most cases, we have gone to extraordinary lengths to support our learners and incorporate their feedback. Any and all payment made in advance for the course shall be non-refundable under all circumstances. Unless otherwise expressly agreed in writing by our Authorizing team, full payment for participation in a Program is required at the time of registration. </p>


          <h3>Fee Payment for Courses & Workshops</h3>
          <p className='p-small'>
            The Learner is supposed to make the Full payment for participation in a Program at the time of registration. However, You can also register for these programs by paying the registration fee (specified on the respective product pages.). You should pay the remaining program fee (Program Fee minus the registration amount) within 6 days of program registration. Extra charges will be incurred if you fail to make the payment within this period. You may choose to make the payment through EMIs as well. The EMI option is available on the payment page. The payment gateway charges will be borne by <b>Skills Stack</b>.
          </p>

          <p className='p-small'>
            Failure to pay to <b>Skills Stack</b> or its financial partners may result in withdrawal of your access to the Program. You may cancel your enrolment at any time but No refund will be made once the payment (in part or whole) for a Program has been made. We are there to support in your career endeavors and are committed to providing service to your satisfaction. Please note, We do not provide refunds for the lack of usage or discontinuation.
          </p>

          <p className='p-small'>
            <b>Skills Stack</b> does not store any of your credit card information or such other information restricted by RBI for processing payment and has partnered with payment gateways for the payment towards the services. By using a third-party payment provider such as Razorpay, you agree to abide by the terms of such a payment provider. You agree that in case <b>Skills Stack</b>'s third-party payment provider stores any such information, <b>Skills Stack</b> will not be responsible for such storage, and it will be solely at your discretion to allow the third party to store such information. Any loss of such information or any loss incurred by you due to the usage of such information will be solely a loss incurred by you, and <b>Skills Stack</b> is in no way liable for any such losses and is neither responsible to reimburse/make good such losses in any manner whatsoever. You also agree to pay the applicable fees for the payments made through the Platform.  <b>Skills Stack</b> will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold <b>Skills Stack</b> harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from the incorrect use of the online payment process or any other causes attributable to the user.
          </p>

          <p className='p-small'>
            <b>Skills Stack</b> reserves the right to change or modify the workshop or any event dates. If any session is delayed or cancelled then the that same session will be scheduled for a later time. <b>Skills Stack</b> can not be held accountable for refund in such cases if a session is rescheduled. Refund will only be applicable if the workshop/event has been cancelled by <b>Skills Stack</b>.
          </p>

          <p className='p-small'>
            <b>Skills Stack</b> will not be responsible for any refunds arising out of any failures in online payments and in case the amount gets deducted from users saving/current account and does not get credited in <b>Skills Stack</b> account before the transaction is complete. Users, in this case, will have to contact either the <b>Bank/Skills Stack</b> to clarify the same.
          </p>


          <h3>Contact Us</h3>
          <p className='p-small'>If you have any questions about our Cancellation and Refund Policy, please contact us:</p>
          <ul className='p-small'>
            <li>By email: team@skillsstack.in</li>
            <li>By Phone: 8299689821</li>
            <li>By Post: 123A/54 Swaroop Nagar, Kanpur - 208015</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
