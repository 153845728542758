import axios from 'axios';
import React from 'react'
import { useParams } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import Programs from '../../Components/Programs/Programs';
import Spinner from '../../Components/Spinners/Spinner';
import config from '../../utils/config';

export default function Certificate() {

    const params = useParams();
    const [certificate, setCertificate] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)

    const checkCertificate = async id => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/enrollments/certificate/verify/${id}`);

            if (resp.data.response.status === 200) {
                setCertificate(resp.data.response.data)
            } else {
                toast.error(resp.data.response.message);
                setCertificate(null);
            }


        } catch (err) {
            toast.error(err.response.message || "Certificate Not Found", config.toastrOption)
        } finally {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        checkCertificate(params.id)
    }, [params.id])


    return (
        <div className="main-container">
            <Spinner show={isLoading} />
            <ToastContainer />
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-12">
                        {
                            certificate ? <div className="card">
                                <div className="card-header " style={{
                                    backgroundColor: "white",
                                }}>
                                    <div className="card-title h4 bold-600">
                                        Certificate Verification
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p>
                                        Certificate ID: <b>{params.id}</b>
                                    </p>
                                    <p>
                                        Issued on: <b>{certificate?.certificate?.dateOfIssue}</b>
                                    </p>
                                    <p>
                                        Issued to: <b>{certificate?.name}</b>
                                    </p>

                                    <p>
                                        Type: <b>Certificate of {certificate?.certificate?.type}</b>
                                    </p>

                                    <p>
                                        Course: <b>{certificate?.course}</b>
                                    </p>

                                    <p>
                                        Duration: <b>{certificate?.certificate?.duration}</b>
                                    </p>

                                </div>
                            </div> : <div className="card">
                                <div className="card-header " style={{
                                    backgroundColor: "white",
                                }}>
                                    <div className="card-title h4 bold-600">
                                        Certificate Verification
                                    </div>
                                </div>
                                <div className="card-body">

                                    <p className="h4"><b>No Certificate found for this ID.</b></p>
                                    <br />
                                    <p className="9">Please rescan the QR code present on the certificate.</p>
                                    <p className="p">if you think, there is any fraud or any mistake please mail us at team@skillsstack.in</p>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
            < br />
        </div>
    )
}
