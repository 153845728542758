import React from 'react'
import AddImageModal from '../../../Modals/AddImageModal/AddImageModal';
import AddLinkModal from '../../../Modals/AddLinkModal/AddLinkModal';
import Markdown, { compiler } from 'markdown-to-jsx';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-highlight'

export default function MarkdownEditor({ setContent }) {
    const [showPreview, setShowPreview] = React.useState(false);
    const [description, setDescription] = React.useState('')

    const [link, setLink] = React.useState({
        url: '',
        title: ''
    });
    const [image, setImage] = React.useState({
        url: '',
        title: ''
    });

    const getDescriptionStartEnd = () => {
        const descritionEle = document.getElementById('description');
        var start = descritionEle.selectionStart;
        // obtain the index of the last selected character
        var finish = descritionEle.selectionEnd;
        // obtain the selected text
        var sel = descritionEle.value.substring(start, finish);
        return { text: sel, start, finish, descritionEle }
    }

    const updateDescription = (text) => {
        const { start, finish } = getDescriptionStartEnd();
        const newDescription = description.substring(0, start) + text + description.substring(finish, description.length);
        setDescription(newDescription);
    }

    React.useEffect(() => {
        setContent(description);
    }, [description])

    React.useEffect(() => {
        if (link.title && link.url) {
            const text = `[${link.title}](${link.url})`;
            updateDescription(text);
            setLink({ url: '', title: '' });
        }
    }, [link.title, link.url])

    React.useEffect(() => {
        if (image.title && image.url) {
            const text = `![${image.title}](${image.url})`;
            updateDescription(text);
            setImage({ url: '', title: '' });
        }
    }, [image.title, image.url])

    const handleChange = (e) => {
        setDescription(e.target.value)
    }

    const setCursor = (descritionEle, location) => {
        setTimeout(() => {
            descritionEle.focus();
            descritionEle.selectionEnd = location
        }, 0);
    }

    const handleKeyPress = (e) => {
        const { start, finish, text, descritionEle } = getDescriptionStartEnd();
        if (e.key === 'Enter') {
            e.preventDefault();
            const preString = description.substring(0, start);
            const postString = description.substring(finish, description.length);
            const preLines = preString.split('\n');
            const lastLine = preLines[preLines.length - 1];
            if (lastLine.length === 0) {
                const newDescription = preString + '\n' + postString;
                setDescription(newDescription);
                setCursor(descritionEle, start + 1);
            } else {
                if (lastLine.startsWith("- ") && lastLine.length === 2) {
                    const allLinesExceptLastLine = preLines.slice(0, preLines.length - 1);
                    const newPrestring = allLinesExceptLastLine.join('\n') + '\n\n';
                    const newDescription = newPrestring + postString;
                    setDescription(newDescription);
                    setCursor(descritionEle, start - 1);
                } else if (lastLine.startsWith('-')) {
                    const newDescription = preString + '\n- ' + postString;
                    setDescription(newDescription);
                    setCursor(descritionEle, start + 3);
                } else if (lastLine.match(/^[1-9]\. +/) && lastLine.length === 3) {
                    const allLinesExceptLastLine = preLines.slice(0, preLines.length - 1);
                    const newPrestring = allLinesExceptLastLine.join('\n') + '\n\n';
                    const newDescription = newPrestring + postString;
                    setDescription(newDescription);
                    setCursor(descritionEle, start - 2);
                } else if (lastLine.match(/^[1-9]+\..*/)) {
                    const lastNum = +lastLine[0];
                    const newDescription = preString + '\n' + (lastNum + 1) + ". " + postString;
                    setDescription(newDescription);
                    setCursor(descritionEle, start + 4);
                } else {
                    const newDescription = preString + '\n' + postString;
                    setDescription(newDescription);
                    setCursor(descritionEle, start + 1);
                }
            }

        }

    }


    return (
        <>
            <div className='editor-buttons-container'>
                <div className='tab-buttons'>
                    <button onClick={() => {
                        setShowPreview(!showPreview);
                    }} disabled={!showPreview} type='button' className='btn btn-primary'>Write</button>
                    <button onClick={() => {
                        setShowPreview(!showPreview);
                    }} disabled={showPreview} type='button' className='btn btn-primary'>Preview</button>
                </div>

                <div hidden={showPreview} className='editor-buttons'>
                    <button onClick={() => {
                        const { start, finish } = getDescriptionStartEnd();
                        setDescription(description.substring(0, start) + '\n---' + description.substring(finish, description.length))
                    }} type='button' title='Add A Horizontal Line' className='btn btn-grey'>
                        <i className="fa fa-minus"></i>
                    </button>
                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.startsWith("### ")) {
                                setDescription(description.substring(0, start) + text.substring(4,) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '### ' + text + description.substring(finish, description.length))
                            }
                        }
                        else {
                            setDescription(description.substring(0, start) + "### " + description.substring(finish, description.length))
                        }
                    }} type='button' title='Add A Heading' className='btn btn-grey'>
                        <i className="fas fa-heading"></i>
                    </button>
                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.startsWith("**") && text.endsWith("**")) {
                                setDescription(description.substring(0, start) + text.substring(2, text.length - 2) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '**' + text + "**" + description.substring(finish, description.length))
                            }
                        }
                        else {
                            setDescription(`${description.substring(0, start)}**Write here**${description.substring(finish, description.length)}`)
                        }
                    }} type='button' title='Bold' className='btn btn-grey'>
                        <i className="fa fa-bold"></i>
                    </button>
                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.startsWith("_") && text.endsWith("_")) {
                                setDescription(description.substring(0, start) + text.substring(1, text.length - 1) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '_' + text + "_" + description.substring(finish, description.length))
                            }
                        }
                        else {
                            setDescription(`${description.substring(0, start)}_Write here_${description.substring(finish, description.length)}`)
                        }
                    }} type='button' title='Italics' className='btn btn-grey'>
                        <i className="fa fa-italic"></i>
                    </button>
                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.startsWith("- ")) {
                                // setDescription(description.substring(0, start) + text.substring(2, text.length - 2) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '- ' + text + description.substring(finish, description.length))
                            }
                        }
                        else {
                            setDescription(description.substring(0, start) + '- ' + description.substring(finish, description.length))
                        }
                    }} type='button' title='Unordered List' className='btn btn-grey'>
                        <i className="fa fa-list-ul"></i>
                    </button>
                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.match(/^[1-9]+.*$/) || text.match(/^[1-9][0-9]+.*$/)) {
                                setDescription(description.substring(0, start) + text.substring(3,) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '\n1. ' + text + description.substring(finish, description.length))
                            }
                        }
                        else {
                            setDescription(description.substring(0, start) + '\n1. ' + description.substring(finish, description.length))
                        }
                    }} type='button' title='Ordered List' className='btn btn-grey'>
                        <i className="fa fa-list-ol"></i>
                    </button>

                    <button data-toggle="modal" title='Add A Hyperlink' data-target="#addLinkModal" type='button' className='btn btn-grey'>
                        <i className="fa fa-link"></i>
                    </button>

                    <button data-toggle="modal" title='Add an Image' data-target="#addImageModal" type='button' className='btn btn-grey'>
                        <i className="fa fa-image"></i>
                    </button>

                    <button onClick={() => {
                        const { text, start, finish } = getDescriptionStartEnd();
                        if (text) {
                            if (text.startsWith('```\n') && text.endsWith('```')) {
                                setDescription(description.substring(0, start) + text.substring(4, text.length - 5) + description.substring(finish, description.length))
                            }
                            else {
                                setDescription(description.substring(0, start) + '```\n' + text + "\n```" + description.substring(finish, description.length))
                            }
                        }
                        else {
                            // setDescription(description.substring(0, start) + '' + "Write Code here" + "\n```" + description.substring(finish, description.length))
                            setDescription(`${description.substring(0, start)}\`\`\`\nWrite here\n\`\`\`${description.substring(finish, description.length)}`)
                        }
                    }} type='button' title='Add Code' className='btn btn-grey'>
                        <i className="fa fa-code"></i>
                    </button>

                </div>
            </div>
            {
                showPreview ? <div className="preview-container" >
                    <div className='preview-body'>
                        <ReactMarkdown id="ele" className='markdown-container' skipHtml={true} children={description} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} />
                    </div>
                </div> : <textarea onKeyDown={handleKeyPress} value={description} onChange={handleChange} contentEditable={false} className="textarea-question" id="description" ></textarea>
            }

            <>
                <div className="modal" id="addLinkModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <AddLinkModal setLink={setLink} />
                </div>
                <div className="modal" id="addImageModal" tabIndex="-2" role="dialog" aria-labelledby="AddImageModalLabel" aria-hidden="true">
                    <AddImageModal setImage={setImage} />
                </div>
            </>
        </>
    )
}
