import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router";
import "./RegisterScreen.css";
import axios from "axios";
import config, { localStorageKeys } from "../../utils/config";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import Context from "../../Context";
import InlineSpinner from "../../Components/Spinners/InlineSpinner";
import { Link, useSearchParams } from "react-router-dom";
import sendLogs from "../../utils/telemetry";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function RegisterScreen() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const [arePasswordMatching, setArePasswordMatching] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { state, setState } = React.useContext(Context);
  const [checked, setChecked] = React.useState(false);
  const [referrer, setReferrer] = React.useState("");
  const [friendName, setFriendName] = React.useState("");
  const auth = getAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [phone, setPhone] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    if (errorMessage) {
      sendLogs("User Registration Attempt Failed", null, {
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        mobile: phone,
        referrer: referrer,
        friendName: friendName,
      }, "User Registration Attempt Internal Check Failed", { errorMessage });
    }
  }, [errorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    sendLogs("User Registration Attempt", null, {
      name: name,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      mobile: phone,
      referrer: referrer,
      friendName: friendName,
    }, "User Registration Attempt", null);

    if (!checked) {
      toast.error("Please agree to the terms and conditions");
      setErrorMessage("Please agree to the terms and conditions");
      return;
    }

    if (!name.match(/^[a-zA-Z ]+$/)) {
      toast.error("Please enter a valid name", config.toastrOption);
      setErrorMessage("Please enter a valid name");
      return;
    }
    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      toast.error("Please enter a valid email", config.toastrOption);
      setErrorMessage("Please enter a valid email");
      return;
    }
    if (
      !password.match(
        // /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        /^.{8,}$/
      )
    ) {
      toast.error(
        "Please enter a valid password, it shoould be of atleast 8 characters",
        config.toastrOption
      );
      setErrorMessage(
        "Please enter a valid password, it shoould be of atleast 8 characters"
      );
      return;
    }
    if (!phone || !phone.match(/^[+]{1}[0-9]{1}[0-9]{9,}$/)) {
      toast.error("Please enter a valid phone number", config.toastrOption);
      setErrorMessage("Please enter a valid phone number");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match", config.toastrOption);
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      setIsLoading(true);
      const firebaseUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(firebaseUser.user);

      const data = {
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        firebase_uid: firebaseUser.user.uid,
        referrer: referrer,
        friend_name: friendName,
        mobile: phone,
      };

      const resp = await axios.post(`${config.baseUrl}/users/register`, data);
      if (resp.status === 200) {
        toast.success(
          "Welcome to Skills Stack !",
          { ...config.toastrOption, autoClose: 5000 }
        );
        sendLogs("User Registration Attempt", true, resp.data.response.data, "User Registration Success", null);

        localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
          token: resp.data.response.data.token,
          sessionStartTime: new Date().getTime()
        }));

        setState({
          ...state,
          isLoggedIn: true,
          user: resp.data.response.data.user,
          isGuest: false,
          token: resp.data.response.data.token,
          firebaseUser: firebaseUser.user,
        });

        setTimeout(() => {
          setIsLoading(false);
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);

      sendLogs("User Registration Attempt", false, {
        name: name,
        email: email,
        password: password,
        mobile: phone,
        referrer: referrer,
        friendName: friendName,
      }, "User Registration Failed", error.message);

      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        toast.error("Email already in use", config.toastrOption);
        setErrorMessage("Email already in use");
        return;
      }


      toast.error(
        error.response?.data?.message || "Something went wrong",
        config.toastrOption
      );
      return;
    }
  };

  const handleChange = (e) => {
    setErrorMessage("");
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      if (password) {
        if (e.target.value === password) {
          setArePasswordMatching(false);
        } else {
          setArePasswordMatching(true);
        }
      }
      setConfirmPassword(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "friendName") {
      setFriendName(e.target.value);
    } else if (e.target.name === "mobile") {
      setPhone(e.target.value);
    }
  };

  React.useEffect(() => {
    setState({ ...state, currentScreen: "login" });
    window.scrollTo(0, 0);
    sendLogs("Visit Register Page", true, {}, {}, null);
    document.title = "Welcome to Skills Stack";
    return () => {
      sendLogs("Left Register Page", false, {}, {}, null);
      return null
    }
  }, []);

  React.useEffect(() => {
    if (state.isLoggedIn) {
        setRedirect(true);
    }
}, [state.isLoggedIn]);

  const howDidYouReachUs = [
    "Google Search",
    "Whatsapp Promotions",
    "Twitter Promotions",
    "Instagram/Facebook Ads",
    "LinkedIn Messages",
    "Friend Referral",
    "Other",
  ]

  return (
    <div className="main-container">
      <div className="container-fluid">
        {redirect ? <Navigate to={`${searchParams.get("redirect") ? `${searchParams.get("redirect")}?modal=true` : "/"}`} /> : null}
        <ToastContainer />

        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
            <div className="card">
              <div className="card-header text-center">
                <h3>Welcome to Skills Stack !</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      value={name}
                      onChange={handleChange}
                      type="text"
                      id="name"
                      style={{
                        textTransform: "capitalize",
                      }}
                      name="name"
                      className="form-control"
                      required
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      value={email}
                      onChange={handleChange}
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      required
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile Number</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone}
                      type="text"
                      className="form-control"
                      defaultCountry='IN'
                      id="exampleInputEmail1"
                      numberInputProps={{
                        style: {
                          border: 0,
                          outline: 0
                        },

                      }}
                      onChange={(value) => {
                        setPhone(value)
                      }}
                    />
                    {/* <input
                      value={phone}
                      onChange={handleChange}
                      type="number"
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      required
                      placeholder="Enter your 10 digit mobile number"
                    /> */}
                  </div>
                  <div className="form-group has-feedback">
                    <label htmlFor="password">Password</label>
                    <input
                      value={password}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-control"
                      required
                      placeholder="Enter your password"
                    />
                    {/* <i className="glyphicon glyphicon-user form-control-feedback"></i> */}
                    {/* <i className="fa fa-eye"></i> */}
                  </div>
                  <div className="form-group checkbox">
                    <input
                      type="checkbox"
                      id="showpassword"
                      value="Agreed-to-Terms"
                      name="rterms"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />Show Password
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      value={confirmPassword}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      required
                      className={
                        !arePasswordMatching
                          ? "form-control"
                          : "form-control red-outline"
                      }
                      placeholder="Enter your password once more"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="aboutus">How'd you get to know about us ?</label>
                    <select required id="aboutus" value={referrer} onChange={(e) => {
                      setReferrer(e.target.value)
                    }} className="form-control">
                      <option value={""} disabled>Select One</option>
                      {
                        howDidYouReachUs.map((item, index) => {
                          return <option key={index} value={item}>{item}</option>
                        })
                      }
                    </select>
                  </div>
                  {
                    referrer === 'Friend Referral' && <div className="form-group">
                      <label htmlFor="friendName">Friend (This will be used for referral incentives)</label>
                      <input
                        value={friendName}
                        onChange={handleChange}
                        type="text"
                        id="friendName"
                        name="friendName"
                        className="form-control"
                        required
                        placeholder="Enter your friend's name"
                      />
                    </div>
                  }
                  <div className="form-group checkbox">
                    <input
                      type="checkbox"
                      id="rterms"
                      value="Agreed-to-Terms"
                      name="rterms"
                      required
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />I've read and agree to Skill Stack's written {" "}
                    <Link target={"_blank"} to="/privacy-policy">Privacy Policy</Link> and {" "}
                    <Link target={"_blank"} to="/terms-conditions">Terms & Conditions.</Link>
                    <div className="help-block with-errors"></div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Register {isLoading ? <InlineSpinner /> : null}
                  </button>
                  {errorMessage && <p className="text-danger" style={{
                    marginTop: "10px",
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}>{errorMessage}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
