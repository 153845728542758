import React from "react";
import Context from "../../Context";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import config, { localStorageKeys } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
import InlineSpinner from "../../Components/Spinners/InlineSpinner";
import sendLogs from "../../utils/telemetry";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

export default function LoginScreen(props) {
    const { state, setState } = React.useContext(Context);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [redirect, setRedirect] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sendVerificationEmail, setSendVerificationEmail] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [isResetLoading, setIsResetLoading] = React.useState(false);
    const [isGooleSignInLoading, setIsGoogleSignInLoading] = React.useState(false);
    const [isResetPassword, setIsResetPassword] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [sendToProfile, setSendToProfile] = React.useState(false);
    const [userNotFoundErrorCount, setUserNotFoundErrorCount] = React.useState(0);

    const iOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    React.useEffect(() => {
        setState({ ...state, currentScreen: "login" });
        document.title = "Welcome to Skills Stack";
        if (searchParams.get("success_register") === "true") {
            toast.success("Your account has been created. Please login to continue !");

        }

        if (searchParams.get("resetPassword") === "true") {
            setIsResetPassword(true);
            setSendVerificationEmail(false);
            if (searchParams.get("email")) {
                setEmail(searchParams.get("email"));
            }
        }

        sendLogs("Visit Login Page", true, {}, {}, null);
        return () => {
            setIsLoading(false);
            setRedirect(false);
            sendLogs("Left Login Page", false, {}, {}, null);
            return null
        }
    }, []);

    React.useEffect(() => {
        if (userNotFoundErrorCount === 2) {
            document.getElementById("hiddenBtn").click();
            setUserNotFoundErrorCount(0);
        }
    }, [userNotFoundErrorCount])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const firebaseUser = await signInWithEmailAndPassword(
                getAuth(),
                email,
                password
            );
            // if (!firebaseUser.user.emailVerified) {
            //     throw new Error("Email not verified");
            // } else {
            const resp = await axios.post(`${config.baseUrl}/users/login`, {
                email: email,
                password: password,
                firebaseUser
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                }
            })
            if (resp.status === 200) {
                localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                    token: resp.data.response.data.token,
                    sessionStartTime: new Date().getTime()
                }));

                toast.success("Login Successful", config.toastrOption);
                setState({
                    ...state,
                    isLoggedIn: true,
                    user: resp.data.response.data.user,
                    isGuest: false,
                    token: resp.data.response.data.token,
                    firebaseUser: firebaseUser.user,
                });

                sendLogs("login", true, resp.data.response.data.user, "Login Successful", null);

                setTimeout(() => {
                    setRedirect(true);
                    setIsLoading(false);
                }, 3000);
            }
            // }

        } catch (error) {
            setIsLoading(false);
            console.log(error.message);

            sendLogs("login", false, { email: email, password: password }, error);

            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).") {
                setUserNotFoundErrorCount(userNotFoundErrorCount + 1);
                return toast.error("This email is not registered with us, please create an account !", config.toastrOption);
            } if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);
            toast.error(
                error.response?.data?.message || "Something went wrong",
                config.toastrOption
            );

            return;
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        } else if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    };

    const handleSendVerificationEmail = async () => {
        try {
            if (!email) {
                return toast.error("Please enter email", config.toastrOption);
            }
            if (!password) {
                return toast.error("Please enter password", config.toastrOption);
            }
            setIsLoading(true);
            const firebaseUser = await signInWithEmailAndPassword(getAuth(), email, password);
            await sendEmailVerification(firebaseUser.user);
            toast.success("Email verification link has been sent to your email", config.toastrOption);
            setIsLoading(false);
            setSendVerificationEmail(false);

            sendLogs("Send Email Verification", true, { email: email }, "Email Verification Link Sent", null);

            return;
        } catch (error) {
            console.log(error);
            setIsLoading(false);

            sendLogs("send email verification", false, { email: email }, error.message || error.response?.data?.message || "Something went wrong", error.message);

            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).")
                return toast.error("User not found", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);

            toast.error("Something went wrong", config.toastrOption);
        }
    }

    const handleForgotPassword = async () => {
        try {
            if (!email) {
                return toast.error("Please enter your email", config.toastrOption);
            }
            setIsResetLoading(true);
            await sendPasswordResetEmail(getAuth(), email);
            toast.success("Password reset link has been sent to your email", config.toastrOption);
            setIsResetLoading(false);
            sendLogs("Send Password Reset Link", true, { email: email }, "Send Password Reset Link", null);
            return;
        } catch (error) {
            setIsResetLoading(false);
            if (error.message === "Firebase: Error (auth/wrong-password).")
                return toast.error("Wrong password", config.toastrOption);
            if (error.message === "Firebase: Error (auth/user-not-found).")
                return toast.error("User not found", config.toastrOption);
            if (error.message === "Firebase: Error (auth/too-many-requests).")
                return toast.error("Too many requests for this account, please try again later !", config.toastrOption);
            if (error.message === "Email not verified")
                return toast.error("Email has not been verified, please verify then try again !", config.toastrOption);
            if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                return toast.error("Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.", config.toastrOption);
            sendLogs("Send Password Reset Link", false, { email: email }, "Send Password Reset Link Failed", error.message);
            toast.error("Something went wrong", config.toastrOption);
        }
    }

    const handleGoogleAuthLogin = async () => {
        const provider = new GoogleAuthProvider();

        const auth = getAuth();
        setIsGoogleSignInLoading(true);
        try {
            const result = await signInWithPopup(auth, provider)
            const credential = GoogleAuthProvider.credentialFromResult(result);
            // The signed-in user info.
            const user = result.user;
            const body = {
                name: user.displayName,
                email: user.email,
                firebase_uid: user.uid,
            }

            const resp = await axios.post(`${config.baseUrl}/users/loginWithGoogle`, body);
            if (resp.status === 200) {

                // console.log(resp.data.response.data);
                localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                    token: resp.data.response.data.token,
                    sessionStartTime: new Date().getTime()
                }));

                sendLogs("login by google", true, resp.data.response.data.user, "Login By Google Success", null);
                toast.success("Login Successful", config.toastrOption);
                setState({
                    ...state,
                    isLoggedIn: true,
                    user: resp.data.response.data.user,
                    isGuest: false,
                    token: resp.data.response.data.token,
                });
                if (!resp.data.response.data.user.mobile) {
                    setSendToProfile(true);
                    setIsGoogleSignInLoading(false);
                } else {
                    setTimeout(() => {
                        setRedirect(true);
                        setIsGoogleSignInLoading(false);
                    }, 3000);
                }
            }

        } catch (error) {
            console.log(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            if (errorCode === "auth/network-request-failed") {
                sendLogs("login by google failed, sending to Register Page", false, { email: email }, { errorMessage, errorCode, error, credential }, null);
                setIsGoogleSignInLoading(false);
                document.getElementById("signUp").click();
                return;
            }
            sendLogs("login by google failed", false, { email: email }, errorMessage, null);
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(errorCode, errorMessage, email, credential);
            toast.error("Could not login with Google", config.toastrOption);
            setIsGoogleSignInLoading(false);
        }
        setIsGoogleSignInLoading(false);
    }

    React.useEffect(() => {
        if (state.isLoggedIn) {
            setRedirect(true);
        }
    }, [state.isLoggedIn]);

    const clickSignUp = () => {
        document.getElementById("signUp").click();
    }

    return (
        <div className="main-container">
            <div className="container-fluid">
                <ToastContainer />
                {redirect ? <Navigate to={searchParams.get("redirect") ? `${searchParams.get("redirect")}?modal=true` : "/"} /> : null}
                {sendToProfile ? <Navigate to={"/profile?showMessage=true"} /> : null}
                <div className="row">
                    <div className="col-lg-6 col-md-6 offset-md-3 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h3>Welcome back to Skills Stack</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} autoComplete={"false"}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            value={email}
                                            onChange={handleChange}
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                        />
                                    </div>
                                    {
                                        !isResetPassword && <>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    value={password}
                                                    onChange={handleChange}
                                                    type={showPassword ? "text" : "password"}
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" name="showpass" id="showpass" onChange={(e) => {
                                                    setShowPassword(e.target.checked);
                                                }} /> Show Password
                                            </div>
                                        </>
                                    }


                                    {
                                        !isResetPassword && !sendVerificationEmail && <>
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-block"
                                                disabled={isLoading}
                                            >
                                                Login with Email <i style={{
                                                    color: "white",
                                                    marginLeft: "5px"
                                                }} className="fas fa-envelope"></i>{isLoading ? <InlineSpinner /> : null}
                                            </button>
                                            {
                                                !iOS() && <button
                                                    type="button"
                                                    role={"button"}
                                                    className="btn btn-primary btn-block"
                                                    disabled={isGooleSignInLoading}
                                                    style={{
                                                        backgroundColor: "#db4437",
                                                    }}
                                                    onClick={handleGoogleAuthLogin}
                                                >
                                                    Sign in / Sign up with google <i style={{
                                                        color: "white",
                                                        marginLeft: "5px"
                                                    }} className="fab fa-google"></i> {isGooleSignInLoading ? <InlineSpinner /> : null}
                                                </button>
                                            }
                                        </>}

                                    {
                                        !isResetPassword && sendVerificationEmail && <button
                                            type="button"
                                            className="btn btn-primary btn-block"
                                            disabled={isLoading}
                                            onClick={() => {
                                                handleSendVerificationEmail();
                                            }}
                                        >Send Verification Mail {isLoading ? <InlineSpinner /> : null}</button>
                                    }

                                    {
                                        isResetPassword && !sendVerificationEmail && <button
                                            type="button"
                                            className="btn btn-primary btn-block"
                                            disabled={isLoading}

                                            onClick={() => {
                                                handleForgotPassword();
                                            }}
                                        >Reset Password {isResetLoading ? <InlineSpinner /> : null}</button>
                                    }


                                    {
                                        !isResetPassword && !sendVerificationEmail && <button
                                            type="button"
                                            onClick={() => setSendVerificationEmail(true)}
                                            className="btn btn-primary btn-block btn-black"
                                        >
                                            Send new Verification Mail ?
                                        </button>}

                                    {
                                        (sendVerificationEmail || isResetPassword) && <button
                                            type="button"
                                            onClick={() => { setSendVerificationEmail(false); setIsResetPassword(false) }}
                                            className="btn btn-primary btn-block btn-black"
                                        >
                                            Back to Login ?
                                        </button>

                                    }

                                    {
                                        !sendVerificationEmail && !isResetPassword && <button
                                            type="button"
                                            onClick={() => {
                                                setIsResetPassword(true);
                                                setSendVerificationEmail(false);
                                            }}
                                            className="btn btn-primary btn-block btn-black"
                                        >
                                            Forgot Password ?
                                        </button>
                                    }



                                    <Link
                                        type="button"
                                        to={"/register"}
                                        id="signUp"
                                        style={{
                                            marginTop: "10px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        New to Skills Stack ? Create an Account
                                    </Link>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <button data-toggle="modal" hidden id="hiddenBtn" data-target="#confirmationModal">Open Modal</button>
            <div className="modal" id="confirmationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <ConfirmationModal onCancelCloseModal={true} onCancel={null} title={"Want to Sign up ?"} onSuccess={clickSignUp} message="You are trying to login with email, which is not registered with us. Do you want to continue with creating a new account with us ?" />
            </div>
        </div >
    );
}
