import React from 'react'
import config from '../../utils/config'
import "./CourseReview.css"

export default function CourseReview({ review }) {

    const getLastActivity = (lastActivity) => {
        const date = new Date(lastActivity)
        const now = new Date()
        const diff = now - date
        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
        const diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
        const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))

        if (diffInYears > 0) {
            return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`
        } else if (diffInMonths > 0) {
            return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`
        } else if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
        } else {
            return `${Math.floor(diff / (1000 * 60 * 60))} hour${Math.floor(diff / (1000 * 60 * 60)) > 1 ? 's' : ''} ago`
        }
    }


    return (
        <div className="col-md-3 col-sm-12 mt-4">
            <div className="card review-card">
                {
                    review.image && <div className="card-header text-center">
                        <img className='card-img-top reviewer-image' src={`${config.baseUrl}/${review.image}`} alt="Reviewer Image" />
                    </div>
                }
                <div className="card-body">
                    <div className="card-title text-center">
                        <p className='p bold-600'>{review.reviewer}</p>
                    </div>

                    <p className='p-small card-text'>“{review.review}”</p>

                    <div className="p-small ratings">
                        {
                            new Array(review.rating).fill(0).map((_, index) => {
                                return <i key={index} className="material-icons rating-color">star</i>
                            })
                        }
                        {
                            new Array(5 - review.rating).fill(0).map((_, index) => {
                                return <i key={index} className="material-icons">star</i>
                            })
                        }
                        <span className='ml-3'>{getLastActivity(review.createdAt)}</span>
                    </div>

                </div>
            </div>
        </div>
    )
}
