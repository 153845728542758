import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Context from "../../Context";
import { localStorageKeys } from "../../utils/config";
import initialState from "../../Context/initialState";
import { svgs } from "../../assets/images/svgs";

export default function Header() {
  const { state, setState } = React.useContext(Context);

  return (
    <header id="header" className="header">
      <div className="container-fluid">
        <div className="row header-container">
          <div className="col-md-7 col-12 header-left-container">
            <p className="header-heading">
              Skills Stack
            </p>
            <h2>
              Where Learning Meets Fun.
            </h2>
            <h5>
              <i className="fas fa-check-circle mr-2"></i>Explore hundreds of hands-on classes in coding, design, and more.
            </h5>
            <h5>
              <i className="fas fa-check-circle mr-2"></i>Learn from industry experts and build projects to showcase your skills.
            </h5>

            <div className="button-container">
              <Link to={"/workshop/machine-learning-bootcamp/"} target="_blank" className="btn-solid-lg">Machine Learning Workshop</Link>

              <button onClick={() => {
                const planEle = document.getElementById("programs");
                console.log(planEle);
                window.scrollTo({
                  behavior: "smooth",
                  top: planEle.offsetTop,
                });
              }} className="btn-outline-lg">Our Programs</button>

              <Link to={"/masterclass/advanced-python-masterclass/"} target="_blank" className="btn-solid-lg">Free Python Masterclass</Link>

            </div>

          </div>
          <div className="col-md-5 col-12 text-center header-image-container">
            <img className="header-image" src={require("../../assets/images/header-image.webp")} />

            <img className="header-image-mobile" src={require("../../assets/images/header-image-mobile.webp")} />
          </div>
        </div>



        <div className="row highlights-container">
          <div className="col-lg-4 col-md-4 col-sm-6 highlight-container">
            <img src={svgs.learner} className="highlight-svg-image" />
            <div className="highlight-text-container">
              <p className='heading'>2100+</p>
              <p className='muted-text'>Satisfied Students</p>
            </div>

          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 highlight-container">
            <img src={require("../../assets/images/header-session-image.webp")} className="highlight-svg-image" />
            <div className="highlight-text-container">
              <p className='heading'>500+</p>
              <p className='muted-text'>Sessions Completed</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 highlight-container">
            <img src={svgs.expert} className="highlight-svg-image" />
            <div className="highlight-text-container">
              <p className='heading'>40+</p>
              <p className='muted-text'>Industry Experts & Mentors</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
