import React from 'react'

export default function Description() {
    return (
        <div id="description" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>Why Skills Stack ?</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="list-unstyled li-space-lg first">
                            <li className="media">
                                <i className="bullet">1</i>
                                <div className="media-body">
                                    <h4>Application Based Learnings</h4>
                                    <p>
                                        Our program focuses on the practical learning for easy and
                                        fast understanding.
                                    </p>
                                </div>
                            </li>
                            <li className="media">
                                <i className="bullet">2</i>
                                <div className="media-body">
                                    <h4>Understanding Industry Needs</h4>
                                    <p>
                                        Our training specifically emphasises on the current industry
                                        requirements and the industry trends.
                                    </p>
                                </div>
                            </li>
                            <li className="media">
                                <i className="bullet">3</i>
                                <div className="media-body">
                                    <h4>Communication Skills Enhancement and Resume Building</h4>
                                    <p>
                                        Our experts will teach you basics of professional
                                        communication and will help you create different resumes for
                                        different companies based on their requirements.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="list-unstyled li-space-lg second">
                            <li className="media">
                                <i className="bullet">4</i>
                                <div className="media-body">
                                    <h4>Getting higher packages</h4>
                                    <p>
                                        Aligning with the trends and requirements of the industry,
                                        we will help you to get the higher packages.
                                    </p>
                                </div>
                            </li>
                            <li className="media">
                                <i className="bullet">5</i>
                                <div className="media-body">
                                    <h4>Expert Mentorship and Guest Lectures</h4>
                                    <p>
                                        Our experts will provide Mentorship till you get your job
                                        and we will organize guest lectures from the industry
                                        experts to help you understand the industry trends.
                                    </p>
                                </div>
                            </li>
                            <li className="media">
                                <i className="bullet">6</i>
                                <div className="media-body">
                                    <h4>
                                        Available in both Online and Offline Classroom Programs
                                    </h4>
                                    <p>
                                        Our instructors are available in both online and offline
                                        mode of training.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
