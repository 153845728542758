import React from "react";
import AboutScreen from "../../Screens/AboutScreen/AboutScreen";
import HomeScreen from "../../Screens/HomeScreen/HomeScreen";
import LoginScreen from "../../Screens/LoginScreen/LoginScreen";
import NotFoundScreen from "../../Screens/NotFoundScreen/NotFoundScreen";
import RegisterScreen from "../../Screens/RegisterScreen/RegisterScreen";
import { Routes, Route, Navigate } from "react-router-dom";
import './Main.css'
import CourseScreen from "../../Screens/CourseScreen/CourseScreen";
import CoursesScreen from "../../Screens/CoursesScreen/CoursesScreen";
import config, { localStorageKeys } from "../../utils/config";
import axios from "axios";
import Context from "../../Context";
import DiscussionBoardScreen from "../../Screens/DiscussionBoardScreen/DiscussionBoardDashboard/DiscussionBoardScreen";
import AskQuestionScreen from "../../Screens/DiscussionBoardScreen/AskQuestionScreen.jsx/AskQuestionScreen";
import Spinner from "../Spinners/Spinner";
import QuestionScreen from "../../Screens/DiscussionBoardScreen/QuestionScreen/QuestionScreen";
import Footer from "../Footer/Footer";
import LandingScreen from "../../Screens/LandingScreen/LandingScreen";
import PrivacyPolicy from "../Footer/PrivacyPolicy";
import TermsandConditions from "../Footer/TermsandConditions";
import SessionScreen from "../../Screens/SessionsScreen/SessionScreen";
import DemoSessions from "../../Screens/SessionsScreen/DemoSessions/DemoSessions";
import Download from "../../Screens/DownloadScreens/Download";
import InterviewPreperation from "../Programs/InterViewPreperation/InterviewPreperation";
import MasterClass from "../Programs/MasterClass/MasterClass";
import ProfileScreen from "../../Screens/ProfileScreen/ProfileScreen";
import MimicScreen from "../../Screens/MimicScreen/MimicScreen";
import MongoDBMasterClass from "../Programs/MasterClass/MongoDBMasterClass";
import CSSAnimationMasterClass from "../Programs/MasterClass/CSSAnimationMasterClass";
import RefundAndCancellationPolicy from "../Footer/RefundAndCancellationPolicy";
import WorkshopScreen from "../../Screens/WorkshopScreen/WorkshopScreen";
import BlogsScreen from "../../Screens/BlogsScreen/BlogsScreen";
import CSSAnimationBlog from "../../Screens/BlogScreen/CSSAnimationBlog";
import Certificate from "../../Screens/Certificate/Certificate";
import VideoScreen from "../../Screens/VideoScreen/VideoScreen";
import AdvancedPythonMasterclass from "../Programs/MasterClass/PythonMasterclass";
import CampusAmbassadorProgram from "../Footer/CampusAmbassadorProgram";
import CorporateTraining from "../../Screens/Corporate/CorporateTraining";

export default function Main() {

	const { state, setState } = React.useContext(Context);
	const [isLoading, setIsLoading] = React.useState(false);

	const checkLogin = async () => {
		const user = localStorage.getItem(localStorageKeys.userDetails);
		if (user) {
			try {
				const userDetails = JSON.parse(user);
				if (userDetails.sessionStartTime > 0) {
					setIsLoading(true)
					const resp = await axios.get(`${config.baseUrl}/users/getUser`, {
						headers: {
							"Authorization": `Bearer ${userDetails.token}`
						}
					});
					if (resp.data.response.status === 200) {
						setState({
							...state,
							isLoggedIn: true,
							user: resp.data.response.data.user,
							isGuest: false,
							token: resp.data.response.data.token,
						});
						getLocation(resp.data.response.data.user.firebase_uid);
					} else {
						getLocation(null);
					}
					setIsLoading(false)
				} else {
					getLocation(null);
					console.log("Session has been expired");
				}
			} catch (error) {
				// console.log(error);
				getLocation(null);
				setIsLoading(false)
			}
		} else {
			getLocation(null);
			setIsLoading(false)
		}
	}

	const getLocation = (user) => {
		// if (navigator.geolocation) {
		// 	navigator.geolocation.getCurrentPosition(async (position) => {
		// 		await sendLocationToServer(position.coords, user);
		// 	}, async (error) => {
		// 		await sendLocationToServer(error)
		// 	});
		// } else {
		// 	console.log("Geolocation is not supported by this browser.");
		// }
	}

	// const sendLocationToServer = async (location, user) => {
	// 	try {
	// 		await axios.post(`${config.baseUrl}/users/updateLocation`, {
	// 			latitude: location.latitude,
	// 			longitude: location.longitude,
	// 			user
	// 		});
	// 	} catch (error) {
	// 		// console.log(error);
	// 	}
	// }


	React.useEffect(() => {
		checkLogin();
		// getCountryFromIP();
		return () => {
			return null;
		};
	}, [])

	return (
		<>
			<Spinner show={isLoading} />

			{/* {
				state && state.firebaseUser && !state?.fireaseUser?.emailVerified ? <div className="main-container"> <div className="alert fixed  alert-warning">
					<strong>Warning!</strong> Please verify your email address ! To send the email verification link to your mail, please logout and go to login page.
				</div> 	</div> : null
			} */}

			<Routes>
				<Route path="/" element={<LandingScreen />} />
				<Route path="/home" element={<HomeScreen />} />
				{/* <Route path="about" element={<AboutScreen />} /> */}
				<Route path="register" element={<RegisterScreen />} />
				<Route path="login" element={<LoginScreen />} />

				{/* <Route path="course" element={<CoursesScreen />} /> */}
				<Route path="masterclass/advanced-python-masterclass" element={<AdvancedPythonMasterclass />} />
				<Route path="course/interview-preparation-program" element={<InterviewPreperation />} />
				<Route path="course/frontend-masterclass" element={<MasterClass />} />
				<Route path="course/mongodb-masterclass" element={<MongoDBMasterClass />} />
				<Route path="course/css-animation-masterclass" element={<CSSAnimationMasterClass />} />
				<Route path="course/:slug" element={<CourseScreen />} />
				<Route path="workshop/:slug" element={<WorkshopScreen />} />
				<Route path="blogs" element={<BlogsScreen />} />

				<Route path="blog/css-animation-masterclass" element={<CSSAnimationBlog />} />

				<Route path="discussion" element={<DiscussionBoardScreen />} />
				<Route path="discussion/ask" element={<AskQuestionScreen />} />
				<Route path="profile" element={<ProfileScreen />} />
				<Route path="discussion/:id" element={<QuestionScreen />} />
				<Route path="sessions" element={<SessionScreen />} />
				<Route path="sessions/demo" element={<DemoSessions />} />
				<Route path="verify/certificate/:id" element={<Certificate />} />

				<Route path="video/session/:id" element={<VideoScreen />} />

				<Route path="corporate-training" element={<CorporateTraining />} />

				{/* <Route path="download/:name" element={<Download />} /> */}
				{
					state?.user?.userType === "admin" ? <Route path="mimic/:email" element={<MimicScreen />} /> : null
				}

				{/* Footer Components */}

				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="terms-conditions" element={<TermsandConditions />} />
				<Route path="refund-cancellation-policy" element={<RefundAndCancellationPolicy />} />
				<Route path="campus-ambassador-program" element={<CampusAmbassadorProgram />} />


				<Route path="*" element={<NotFoundScreen />} />
			</Routes>
			<Footer />
		</>
	);
}
