import React from 'react'

export default function ConfirmationModal({ onCancelCloseModal, message, title, onSuccess, onCancel }) {
    return (
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                    <button type="button" id='sign-in-button' onClick={onCancel} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className='p-small'>{message}</p>
                </div>
                <div className="modal-body border">
                    <div className="row">
                        <div className="col-4">
                            <button type="button" onClick={onCancel} className="btn-block btn btn-black" data-dismiss={onCancelCloseModal ? "modal" : ""}>Cancel</button>
                        </div>
                        <div className="col-4 offset-4">
                            <button onClick={onSuccess} type="button" data-dismiss="modal" className="btn-block btn btn-primary">Yes !</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
