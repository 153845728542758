import React from 'react'

export default function MasterClassCard({ title, description, footer, image }) {
    return (
        <React.Fragment>
            <img src={require(`../../../assets/images/${image}`)} alt={"Skills Stack Interview Preparation Program"} className="card-img-top course-image" style={{
                borderBottom: "1px solid #e5e5e5"
            }} />
            <div className="card-body">
                <h5 className="card-title course-card-title">{title}</h5>
                <p className="card-text course-card-text">{description} </p>
            </div>
            <div className="card-footer">
                <small className="text-muted">{footer}</small>
            </div>
        </React.Fragment>
    )
}
