const initialState = {
    isLoading: false,
    user: null,
    isLoggedIn: false,
    isSuperAdmin: false,
    isGuest: true,
    token: "",
    currentScreen: "",
    selectedCourse: null,
    selectedVariant: null,
    errorMessage: "",
    successMessage: "",
    infoMessage: "",
    country: "India",
    events: [],
    firebaseUser: null,
}

module.exports = initialState;