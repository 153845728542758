import axios from 'axios';
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import EnrollCourseModal from '../../Modals/EnrollCourseModal/EnrollCourseModal';
import config from '../../utils/config';
import './CourseScreen.css';
import Context from '../../Context';
import LoginModal from '../../Modals/LoginModal/LoginModal';
import MobileVerificationModal from '../../Modals/MobileVerificationModal/MobileVerificationModal';
import Spinner from '../../Components/Spinners/Spinner';
import CourseReviews from '../../Components/CourseReviews/CourseReviews';
import sendLogs from '../../utils/telemetry';
import getCountryFromIP from '../../utils/locations';
import Curriculum from '../../Components/Curriculum/Curriculum';
import { Checkbox } from '@mui/material';
import Checkout from '../../Components/Checkout/Checkout';
import ContactModal from '../../Modals/ContactModal/ContactModal';
import Programs from '../../Components/Programs/Programs';


export default function CourseScreen() {

    const params = useParams();
    const [course, setCourse] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const { state, setState } = React.useContext(Context);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [variant, setVariant] = React.useState();
    const [selectedModule, setSelectedModule] = React.useState(0);
    const [country, setCountry] = React.useState();
    const [enrollmentRequest, setEnrollmentRequest] = React.useState({});

    const numberWithCommas = (x) => {
        return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getOneCourse = async (slug) => {

        try {
            setIsLoading(true)
            const res = await axios.get(`${config.baseUrl}/courses/${slug}`,
                {
                    headers: {
                        'Authorization': `Bearer ${state.token}`
                    }
                });

            if (res.data.response.data) {

                setCourse(res.data.response.data.course);
                sendLogs("Course Page Visit", true, { user: state.user || {}, course: res.data.response.data.course.name }, "Visited Session Page", null);

                document.title = `${res.data.response.data.course.name} | Skills Stack`;
                setVariant(res.data.response.data.course.variants[0]);

                if (res.data.response.data.enrollmentRequest) {
                    setEnrollmentRequest(res.data.response.data.enrollmentRequest);
                } else {
                    setEnrollmentRequest(null);
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.error("Error getting course", config.toastrOption);
        }

        const countryD = await getCountryFromIP();
        setCountry(countryD);
    }

    const handleEnroll = () => {
        setState({ ...state, selectedCourse: course, selectedVariant: variant });
    }

    React.useEffect(() => {
        // window.scrollTo(0, 0);
        setState({ ...state, currentScreen: "courses" });

        // console.log()
        return () => {
            return null
        }
    }, []);

    React.useEffect(() => {
        if (params.slug) {
            getOneCourse(params.slug);
        }
    }, [state.isLoggedIn])

    return (
        <div className='container-fluid' style={{
            margin: 0,
            padding: 0,
        }}>
            <ToastContainer />
            <Spinner show={isLoading} />

            {
                course ? <>
                    <div className="row single-course-header">
                        <div className="col-lg-4 col-md-4">
                            <img className='card-img-top single-course-image' src={course.image} alt={`Course :${course.name}`} />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">

                            <h1 className='single-course-name h3'>{course.name}</h1>
                            <p className='single-course-description p-small'>{course.description}</p>

                            <a className="btn btn-primary mt-3" download href={"/brochures/skills_stack_full_mern_brochure.pdf"}
                            >Download Brochure</a>
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            <a className="btn btn-primary mt-3 pl-4 pr-4" role={"button"} onClick={() => {
                                const planEle = document.getElementById("course-plans");

                                planEle.scrollIntoView({
                                    top: planEle.scrollHeight,
                                    behavior: "smooth"
                                })
                            }} >Our Plans</a>
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            &nbsp;&nbsp;
                            <button className='btn btn-primary mt-3 pl-4 pr-4' data-toggle="modal" data-target="#enrollCourseModal">Talk to our experts</button>

                            <div className="ratings p-small text-white">
                                <i className="material-icons rating-color">star</i>
                                <i className="material-icons rating-color">star</i>
                                <i className="material-icons rating-color">star</i>
                                <i className="material-icons rating-color">star</i>
                                <i className="material-icons rating-color">star_half</i>
                                {/* <span className="review-count text-white">(15 Reviews)</span> */}
                                <span className="review-count text-white">100+ students</span>
                            </div>



                        </div>
                    </div>

                    {
                        course.highlights &&
                        <>
                            <div className="row highlights-container">
                                {
                                    course.highlights.map((highlight, index) => {
                                        return <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                                            <p className='heading'>{highlight.value}</p>
                                            <p className='muted-text'>{highlight.name}</p>
                                        </div>

                                    })
                                }
                            </div>

                            <div className="highlights-container-mobile">
                                {
                                    course.highlights.map(highlight => {
                                        return <div key={highlight.name} className="row">
                                            <div className="col-6">
                                                <p className='muted-text'>{highlight.name}</p>
                                            </div>
                                            <div className="col-6">
                                                <p className='heading'>{highlight.value}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                        </>
                    }



                    <div className="row course-feature-container-bg-color">
                        <div className="col-12 mt-3">
                            <div className="h3 text-black bold-600">Key features</div>
                        </div>
                        <div className="col-lg-8 mt-4">
                            <div className="row">
                                {
                                    course.courseDetails.map((detail, index) => {
                                        return <div key={index} className="col-md-6 mt-3">
                                            <p><li>{detail}</li></p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 aim-container">
                            <div className="aim-text">
                                <p className='p-small'>“Our aim is simple: We strive to create high-impact, hands-on experiences that prepare students for meaningful and productive careers.”</p>

                                <p className='p-small bold-600'>- The Skills Stack Team</p>
                                <img className='img-aim' src={require("../../assets/images/logo-black-transparent.png")} alt="Skills Stack" />
                            </div>
                        </div>
                    </div>

                    <div className="row course-feature-container-bg-white">
                        <div className="col-12">
                            <p className='h4 bold-700 text-black'>
                                Projects you will build
                            </p>
                        </div>
                        <div className="projects-container">
                            {
                                course.projects.map((project, index) => {
                                    return <div key={index} className="col-md-3 col-12 mt-3">
                                        <div className="card">
                                            <div className="card-header bg-white">
                                                <img style={{
                                                    height: "200px",
                                                }} src={require("../../assets/images/cart-project.png")} alt={project.name} className='card-img-top' />
                                            </div>
                                            <div className="card-body project-body">
                                                <h5 className="card-title text-center">{project.name}</h5>
                                                <p className="card-text p-small text-black">{project.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>


                    <div className="row course-feature-container-bg-color">
                        <div className="col-md-6 mt-4">
                            <p className='h4 text-black bold-600'>Who Is This Program For?</p>
                            <ul>
                                {
                                    course.prerequisites.map(prereq => {
                                        return <li key={prereq} className='pl-4 mt-3'>{prereq}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="col-md-6 pl-4 mt-4">
                            <p className='h4 bold-600'>Job Opportunity</p>
                            <ul>
                                {
                                    course.jobOpportunities.map(prereq => {
                                        return <li key={prereq} className='pl-4 mt-3'>{prereq}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="course-feature-container-bg-white">
                        <div className="col-12 mt-3">
                            <p className='h4 text-black bold-600'>What our students say ?</p>
                        </div>
                        <CourseReviews />
                    </div>

                    <div className="row course-feature-container-bg-color" >
                        <div className="col-12 mt-3">
                            <p className='h4 text-black bold-700'>Programming Languages and Tools Covered</p>
                        </div>
                        <div className="language-tools">
                            {
                                course.languages_tools.map(tool => {
                                    return <div key={tool.name} style={{
                                        padding: '15px',
                                        marginLeft: '10px',
                                        // border: '1px solid #aaaaaa',
                                        boxShadow: '5px 0px 10px #aaaaaa',
                                        backgroundColor: '#fff',
                                    }} className={['CodeGlo', 'LogixBuilt Infotech'].includes(tool.name) ? 'background-primary' : ""}>
                                        <img key={tool.name} className='tool-image' src={tool.image} alt={tool.name} />
                                    </div>
                                })
                            }
                        </div>

                    </div>

                    <div className="row course-feature-container-bg-white" style={{
                        // backgroundColor: '#8710d8',
                    }}>
                        <div className="col-12 mt-3">
                            <p className='h4 text-black bold-700'>Our Recruitment & Referral Partners</p>
                        </div>
                        <div className="language-tools">
                            {
                                course.companies?.map(tool => {
                                    return <div key={tool.name} style={{
                                        padding: '15px',
                                        marginLeft: '10px',
                                        // border: '1px solid #aaaaaa',
                                        boxShadow: '5px 0px 10px #aaaaaa',
                                    }} className={['CodeGlo', 'LogixBuilt Infotech'].includes(tool.name) ? 'background-primary' : "bg-white"}>
                                        <img className='tool-image' src={tool.image} alt={tool.name} />
                                    </div>
                                })
                            }
                        </div>

                    </div>


                    <div className="row course-feature-container-bg-color" id='course-plans' style={{

                    }}>
                        <div className="col-12 col-md-9 mt-4">
                            <p className='h4 bold-700 text-black'>Select a Plan based on your needs</p>
                        </div>
                        <div className="col-12 col-md-3 mt-4 mb-4">
                            <button className='btn btn-primary p-small btn-block' data-toggle="modal" data-target="#enrollCourseModal">Need Help Choosing ?</button>
                        </div>
                        <br />
                        <div className="col-md-4 mt-4">
                            {
                                [...course.variants,].map((singleVariant, index) => {
                                    return <div onClick={() => {
                                        setSelectedTab(index);
                                        setVariant(course.variants[index])
                                        setSelectedModule(0);
                                    }} key={index} className={index === selectedTab ?
                                        "card variant-card card-selected" : "card variant-card"}>
                                        <div className="card-body">
                                            <span className='variant-name bold-600'>{singleVariant.name}</span>
                                            {
                                                singleVariant.is_best_seller && <span className='best-seller-badge'>Best Seller</span>
                                            }
                                            <i className={index === selectedTab ? "fas fa-check float-right check-icon-selected" : "fas fa-check float-right check-icon"}></i>

                                            <div className="star-container">
                                                <li className='p-small bold-600'>
                                                    Available in easy monthy EMIs
                                                </li>
                                                {
                                                    singleVariant.star_points?.map((star, index) => {
                                                        return <li key={index} className='p-small bold-600'>
                                                            {
                                                                star
                                                            }
                                                        </li>
                                                    })
                                                }
                                            </div>

                                        </div>
                                    </div>
                                })
                            }


                            {/* <Link to={"/course/jobcamp/professional"} target="_blank" className="card variant-card redirect-card">
                                    <div className="card-body">
                                        <span className='variant-name bold-600'>{"Skills Stack Job Guarantee Program"}</span>
                                        <div className="star-container">
                                            <li className='p-small bold-600'>
                                                Assured Placement of upto 15 LPA
                                            </li>
                                            <li className='p-small bold-600'>
                                                Average package of 9.5 LPA
                                            </li>
                                            <li className='p-small bold-600'>
                                                100% refund on course fee
                                            </li>
                                            <li className='p-small bold-600'>
                                                For Working Professionals
                                            </li>
                                            <li className='p-small bold-600'>
                                                Available in easy monthy EMIs
                                            </li>
                                        </div>
                                    </div>
                                </Link> */}

                        </div>
                        {
                            variant && <div className="col-md-8 mt-4 selected-variant-card">
                                <div className='row pt-4 text-center variant-highlight-container'>
                                    {
                                        variant.numOfHours && <div className='variant-highlight-item'>
                                            <i className="variant-highlight-item-icon fas fa-video"></i>

                                            <span className='variant-highlight-item-text bold-600'>{variant.numOfHours}+</span>

                                            <span className='variant-highlight-item-text bold-600'>Hours</span>
                                        </div>
                                    }
                                    {
                                        variant.numOfProjects && <div className='variant-highlight-item'>
                                            <i className="variant-highlight-item-icon fa fa-laptop"></i>
                                            <span className='variant-highlight-item-text bold-600'>{variant.numOfProjects}+</span>
                                            <span className='variant-highlight-item-text bold-600'>Projects</span>
                                        </div>
                                    }


                                    {
                                        variant.numOfMonths && <div className='variant-highlight-item'>
                                            <i className="variant-highlight-item-icon fas fa-clock"></i>
                                            <span className='variant-highlight-item-text bold-600'>{variant.numOfMonths}</span>
                                            <span className='variant-highlight-item-text bold-600'>Months</span>
                                        </div>
                                    }

                                    {
                                        variant.numOfAssignments && <div className='variant-highlight-item'>

                                            <i className="variant-highlight-item-icon fa fa-tasks"></i>
                                            <span className='variant-highlight-item-text bold-600'>{variant.numOfAssignments}+</span>
                                            <span className='variant-highlight-item-text bold-600'>Tasks</span>

                                        </div>
                                    }

                                    {
                                        variant.numOfProblems && <div className='variant-highlight-item'>
                                            <i className="variant-highlight-item-icon fa fa-sun"></i>
                                            <span className='variant-highlight-item-text bold-600'>{variant.numOfProblems}+</span>
                                            <span className='variant-highlight-item-text bold-600'>Problems</span>
                                        </div>
                                    }
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12">
                                        <p className='feature-heading'>Bonus Features</p>
                                    </div>
                                    <div className="col-12">

                                        <div className="feature-list">
                                            {
                                                variant.bonus_features?.map((feature, index) => {
                                                    return <div
                                                        key={index}
                                                        className={
                                                            feature.isPresent ? "row feature-item feature-item-present" : "row feature-item"
                                                        }>

                                                        <div className="col-1 text-right">
                                                            <i className={feature.icon + " feature-icon"}></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <span className='feature-text bold-600'>{feature.title}</span>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="row mt-2">
                                    <div className="col-12">
                                        <p className='feature-heading'>Modules Covered
                                            <button data-toggle="modal" data-target="#curriculumModal" style={{
                                                fontSize: '0.8rem',
                                                float: 'right',
                                                color: '#8710d8',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                fontWeight: 'bold',
                                                border: 0,
                                                background: 'none',

                                            }}> Curriculum</button>
                                        </p>
                                    </div>
                                    <div className="col-12">

                                        <div className="feature-list">
                                            {
                                                variant.modules?.map((feature, index) => {
                                                    return <div
                                                        key={index}
                                                        className={
                                                            feature.isPresent ? "row feature-item feature-item-present" : "row feature-item"
                                                        }>
                                                        <div className="col-1 text-right">
                                                            <i className={"fa fa-check-circle feature-icon"}></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <span className='feature-text bold-600'>{feature.title}</span>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12">
                                        <p className='feature-heading'>Special Features</p>
                                    </div>
                                    <div className="col-12">

                                        <div className="feature-list">
                                            {
                                                variant.special_features?.map((feature, index) => {
                                                    return <div
                                                        key={index}
                                                        className={
                                                            feature.isPresent ? "row feature-item feature-item-present" : "row feature-item"
                                                        }>
                                                        <div className="col-1 text-right">
                                                            <i className={"fa fa-check-circle feature-icon"}></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <span className='feature-text bold-600'>{feature.title}</span>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-5 mt-4">
                                        <div className="price-container">
                                            <span className='course-price text-center'>

                                                {
                                                    country !== "India" ? `$ ${numberWithCommas(variant.price_us.group)}` : `₹ ${numberWithCommas(variant.price_in.group)}`
                                                }
                                                {/* <sup>*</sup> */}
                                                <span style={{
                                                    fontSize: '0.9rem'
                                                }}>/month</span>
                                                <br />
                                                <span className='variant-name' style={{
                                                    fontSize: '0.7rem',
                                                }}>
                                                    ({variant.name})
                                                </span>
                                            </span>

                                            <button
                                                data-toggle="modal"
                                                data-target="#checkoutModal"
                                                className='btn btn-primary enroll-btn'>{enrollmentRequest?._id ? `View Details` : `Save your Seat`}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        }
                    </div>

                    <div className="course-feature-container-bg-white">
                        <div className="col-12">
                            <p className='h4 bold-700 text-black'>
                                Course Curriculum
                            </p>
                        </div>
                        <br />

                        <div className="row">
                            <Curriculum modules={course.modules} />
                        </div>
                    </div>

                    <div className="course-feature-container-bg-white">
                        <Programs title={"our upcoming Events"} dontShowCourse={course.name} />
                    </div>



                    <div className="modal" id="enrollCourseModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        {/* {
                            state.isLoggedIn ?
                                state.user?.is_mobile_verified ? <EnrollCourseModal
                                    country={country}
                                    setEnrolled={setEnrolled}
                                    enrolled={enrolled}
                                    course={course} /> : <MobileVerificationModal /> : <LoginModal />
                        } */}
                        {
                            <ContactModal
                                title={"Need Help Choosing ?"}
                                description="Talk to our team to find the right course for your career."
                                course={course}
                            />
                        }
                    </div>
                    <div className="modal" id="curriculumModal" tabIndex="-1" role="dialog" aria-labelledby="curriculumModal" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">

                            <div className="modal-content" style={{
                                height: '90vh'
                            }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{course.name} : Curriculum</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{
                                    overflowY: 'scroll'
                                }}>
                                    <Curriculum modules={course.modules} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="modal" id="checkoutModal" tabIndex="-1" role="dialog" aria-labelledby="checkoutModal" aria-hidden="true">
                        {
                            state.isLoggedIn ? state?.user.is_mobile_verified ? <div className={"modal-dialog modal-xl modal-dialog-centered"} role="document">

                                <div className="modal-content" style={{
                                    // height: '70vh'
                                }}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{course?.name} (Plan: {variant?.name})</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            variant && <Checkout state={state} course={course} variant={variant} enrollmentRequest={enrollmentRequest} setEnrollmentRequest={setEnrollmentRequest} country={country} />
                                        }
                                    </div>
                                </div>

                            </div> : <MobileVerificationModal /> : <LoginModal />
                        }
                    </div>
                </> : <>
                    <div className='row' style={{
                        height: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <h1 className='h1 text-center mt-4 mb-4'>This course is not present</h1>
                        </div>
                    </div>
                </>
            }
        </div >
    )
}
