import React from 'react'
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Contact from '../../Components/Contact/Contact';
import Description from '../../Components/Description/Description';
import Header from '../../Components/Header/Header'
import Programs from '../../Components/Programs/Programs';
import Registration from '../../Components/Registration/Registration'
import Context from '../../Context';
import sendLogs from '../../utils/telemetry';
import "./LandingScreen.css"
import { ToastContainer } from 'react-toastify';

export default function LandingScreen() {
    const { state, setState } = React.useContext(Context);
    const [redirect, setRedirect] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        setState({ ...state, currentScreen: "" });
        if (state.isLoggedIn) {
            setRedirect(true);
        } else {
            setRedirect(false);
        }

        sendLogs("Landing Page Visit", true, state.user || {}, "Started Viewing Landing Page", null);
        return () => {
            return null;
        };
    }, []);

    React.useEffect(() => {
        const isContactUs = searchParams.get('contact_us');
        if (isContactUs === "true") {
            const planEle = document.getElementById("footer");

            window.scrollTo({
                behavior: "smooth",
                top: planEle.offsetTop,
            });
        }
    }, [searchParams])

    return (
        <div>
            <ToastContainer />
            {redirect ? <Navigate to="/home" /> : null}
            <Header />

            <div className='landing-component'>
                <Registration />
            </div>
            <hr />

            <div className='landing-component'>
                <Description />
            </div>
            <hr />

            <div className='main-container' id="programs">
                <Programs showSpinner={false} />
            </div>
            <hr />

            <Contact />

        </div>
    )
}
