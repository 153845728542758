import axios from "axios";
import config from "./config";

const getCountryFromIP = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}/users/getCountryFromIP`)
        if (response.data.response.data.status !== 'fail') {
            return response.data.response.data.country;
        } else {
            return "India";
        }

    } catch (err) {
        console.log(err);
        return "India";
    }
}

export default getCountryFromIP;