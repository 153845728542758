import React from 'react'
import Context from '../../Context';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from '../../utils/config';
export default function EnrollStaticCourseModal(props) {

    const { state } = React.useContext(Context);
    const [isEnrolled, setIsEnrolled] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [contest, setContest] = React.useState("");
    const [resumeFile, setResumeFile] = React.useState(null);

    const handleEnrollment = async () => {
        if (!checked) {
            toast.error("Please accept the terms and conditions", config.toastrOption);
            return;
        }

        if (props.course === "interview_prep") {
            if (!contest) {
                toast.error("Please enter a contest or company name", config.toastrOption);
                return;
            }

            const formData = new FormData();
            formData.append('resume', resumeFile);
            formData.append('contest', contest);

            try {
                setIsLoading(true);
                const response = await axios.post(`${config.baseUrl}/courses/enroll/interview-program`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${state.token}`
                    }
                });

                setIsLoading(false);
                setIsEnrolled(true);
                props.setEnrolled(true);
                toast.success("You have successfully enrolled in the course", config.toastrOption);
            } catch (err) {
                toast.error("Something went wrong", config.toastrOption);
            }
        } else {
            let url;
            if (props.course === "Introduction to CSS Animations") {
                url = "css-animation-master-class"
            } else if (props.course === "Introduction to MongoDB") {
                url = "mongodb-master-class"
            } else if (props.course === "Advanced Python Masterclass") {
                url = "advanced-python-master-class"
            } else {
                url = "frontend-master-class"
            }
            try {
                setIsLoading(true);
                const response = await axios.post(`${config.baseUrl}/courses/enroll/${url}`, {}, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${state.token}`
                    }
                });

                setIsLoading(false);
                setIsEnrolled(true);
                props.setEnrolled(true);
                toast.success("You have successfully enrolled in the course.", config.toastrOption);
            } catch (err) {
                toast.error("Something went wrong", config.toastrOption);
            }
        }
    }



    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{
                        props.course == "interview_prep" ? "Interview Preparation Program" : props.course
                    }</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEnrolled && !props.enrolled ? <>
                            <div className='row'>
                                <div className="col-12">
                                    <p className='p-small'>Selected Program: <span className='bold-600' style={{
                                        color: '#000',
                                        position: "absolute",
                                        left: "40%"
                                    }}>{
                                            props.course == "interview_prep" ? "Interview Preparation Program" : props.course
                                        }</span></p>
                                    <p className='p-small'>Price: <span className='bold-600' style={{
                                        color: '#000',
                                        position: "absolute",
                                        left: "40%"
                                    }}>{
                                            0 + "/-"}
                                    </span></p>
                                    {/* <p className='p-small'>Duration: <span className='bold-600' style={{
                                        color: '#000',
                                        position: "absolute",
                                        left: "40%"
                                    }}>{state?.selectedVariant?.duration}</span></p> */}

                                    {props.course === "interview_prep" && <div className="form-group">
                                        <label className='p-small'>Interested in (contest / company)</label>
                                        <input className='form-control bold-600' onChange={(e) => {
                                            setContest(e.target.value);
                                        }} placeholder='Please enter the name of the contest/company' />
                                    </div>}

                                    {
                                        props.needResume && <div className="form-group">
                                            <label className='p-small'>Resume (Optional, only accepts pdf, doc, docx, png, jpeg)</label>
                                            <input className='form-control-file bold-600' onChange={e => {
                                                if (e.target.files[0].type === "application/pdf" || e.target.files[0].type === "application/msword" || e.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "application/png") {
                                                    setResumeFile(e.target.files[0]);
                                                } else {
                                                    toast.error("Please upload a valid file", config.toastrOption);
                                                }
                                            }} type={"file"} />
                                        </div>
                                    }

                                    <div className="form-group checkbox">
                                        <label className='p-small' style={{
                                            color: '#000',
                                            fontSize: "0.8rem"
                                        }}>
                                            <input
                                                type="checkbox"
                                                id="rterms"
                                                value="Agreed-to-Terms"
                                                name="rterms"
                                                required
                                                checked={checked}
                                                onChange={() => setChecked(!checked)}
                                            />I've read and agree to Skill Stack's written {" "}
                                            <Link target={"_blank"} to="/privacy-policy">Privacy Policy</Link> and {" "}
                                            <Link target={"_blank"} to="/terms-conditions">Terms & Conditions.</Link></label>
                                        <div className="help-block with-errors">
                                        </div>
                                    </div>
                                    {/* <p className='p-small bold-600' style={{
                                        fontSize: '0.7rem',
                                        color: "black"
                                    }}>* Final course price may change after the scholarship + eligibility test.</p> */}
                                </div>
                            </div></> : <div className='row'>
                            <div className='col-md-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5 className='h5'>You are enrolled in this course</h5>
                                        <p className='p-small'>Thank you for choosing Skills Stack, you will get a confirmation email within 6 hours. Please write to us if you need more help.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    !isEnrolled && !props.enrolled ? <div className="modal-footer text-center">

                        <button type="button" onClick={handleEnrollment} className="btn btn-primary">
                            Submit Enrollment
                            {
                                isLoading && <span className="spinner-border" style={{ height: "1rem", width: "1rem", marginLeft: "10px" }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </span>
                            }
                        </button>
                    </div> : ""
                }
            </div>
        </div>
    )
}
