import React from 'react'
import Code from '../../Components/Code/Code'
import Spinner from '../../Components/Spinners/Spinner';
import { ToastContainer, toast } from 'react-toastify'
import config from '../../utils/config';
import axios from 'axios';
import sendLogs from '../../utils/telemetry';

export default function CSSAnimationBlog() {

	const [blog, setBlog] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(true);

	const loadData = async () => {
		try {
			setIsLoading(true);
			const resp = await axios.get(`${config.baseUrl}/blogs/css-animation`);
			if (resp.data.response.status === 200) {
				setBlog(resp.data.response.data);
				setIsLoading(false);
			} else {
				toast.error(resp.data.response.message, config.toastrOption);
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error("Something went wrong", config.toastrOption);
		}
	}

	React.useEffect(() => {
		loadData();
		document.title = "Skills Stack - CSS Animation | Blog ";
		sendLogs("CSS Animation Blog Page", true, {}, "CSS Animation Blog Page", null);
	}, []);

	const sendToTop = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	return (
		<div className="main-container">
			<Spinner show={isLoading} />
			<ToastContainer />
			<div className="container">
				<button onClick={sendToTop} className='btn-fixed'>
					<i className='text-white fa fa-arrow-up'></i>
				</button>
				<div className="row">
					<div className="col-md-12 text-center">
						<h2>CSS Animation Masterclass</h2>
					</div>
					<div className="col-12 mt-3">
						<p className='p text-black'>
							This blog contains the resources for the CSS Animation Masterclass conduected by <b>Skills Stack</b> on 14th August 2022.
						</p>
					</div>
					<div className="col-12 mt-3">
						<h3 className='text-black'>Table of content</h3>
						<ul className='p-small'>
							{
								blog && blog.map((item, index) => {
									return <li key={index}>
										<a href={`#${item.path}`}>{item.name}</a>
									</li>
								})
							}
						</ul>
					</div>
					<div className="col-12 mt-3">
						<h4 className='text-black'>Resources</h4>
					</div>
					{
						blog && blog.map((item, index) => {
							return <div key={index} className="col-12 mt-3 mb-3">
								<h5 id={item.path} className='text-black'>{item.name}</h5>
								<Code config={{
									language: item.language,
									code: item.code,
									theme: 'vs-dark',
									readOnly: true
								}} >
									{item.code}
								</Code>
							</div>
						})
					}
				</div>
			</div>
		</div>
	)
}
