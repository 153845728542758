import React from 'react'

export default class AdsenseAd extends React.Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return <div>
            Ad Here
            <ins className="adsbygoogle"
                style={{
                    display: "block",
                }}
                data-ad-client="ca-pub-6496198867788298"
                data-ad-slot="6497834160"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    }
}
