import React from "react";
import "./Navigation.css";
import { Link } from "react-router-dom";
import Context from "../../Context";
import { localStorageKeys } from "../../utils/config";
import initialState from "../../Context/initialState";
import sendLogs from "../../utils/telemetry";

export default function Navigation() {
	const { state, setState } = React.useContext(Context);

	return (
		<nav className="navbar nav-custom sticky-top navbar-expand-md background-primary">
			<Link className="navbar-brand text-dark" to="/">
				<img src={require("../../assets/images/logo-white-transparent.png")} alt="Skills Stack Logo" className="logo-image" />
			</Link>
			{/* <form className="form-inline search-form">
          <input
            className="form-control search-input"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button className="nav-search-button" type="submit">
            <i className="material-icons">search</i>
          </button>
        </form> */}
			<button
				className="navbar-toggler navbar-toggler-right text-dark"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNavDropdown"
				aria-controls="navbarNavDropdown"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<i className="material-icons text-white">menu</i>
			</button>
			<div
				className="collapse navbar-collapse justify-content-end"
				id="navbarNavDropdown"
			>
				<ul className="navbar-nav">

					{/* <li className="nav-item dropdown">
						<a className="nav-link text-white dropdown-toggle" style={{
							color: '#000',
							fontWeight: '600'
						}} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="fa fa-code"></i> Coding Playground
						</a>
						<div className="dropdown-menu background-primary" style={{
							fontSize: '0.9rem'
						}} aria-labelledby="navbarDropdown">

							<a
								className="dropdown-item"
								href="https://skillsstack.in/playground/c"
							>
								<span className={"page-link"}>C Playground</span>
							</a>
							<a
								className="dropdown-item"
								href="https://skillsstack.in/playground/cpp"
							>
								<span className={"page-link"}>C++ Playground</span>
							</a>
							<a
								className="dropdown-item"
								href="https://skillsstack.in/playground/java"
							>
								<span className={"page-link"}>Java Playground</span>
							</a>

							<a
								className="dropdown-item"
								href="https://skillsstack.in/playground/javascript"
							>
								<span className={"page-link"}>Javascript Playground</span>
							</a>
							<a
								className="dropdown-item"
								href="https://skillsstack.in/playground/python"
							>
								<span className={"page-link"}>Python Playground</span>
							</a>

						</div>
					</li> */}

					<li className="nav-item">

						<a
							className="nav-link nav-link-icon text-dark"
							href="https://skillsstack.in/playground/c"
						>
							<i className="fa fa-code"></i>
						</a>
					</li>

					<li className="nav-item">
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link text-dark"
							to="/home"
						>
							<span className={state.currentScreen === 'home' ? "active-page-link" : "page-link"}>Home</span>
						</Link>
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link nav-link-icon active text-dark"
							to="/home"
						>
							<i className="material-icons">home</i>
						</Link>
					</li>

					<li className="nav-item">
						<a
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link text-dark"
							target="_blank"
							role="button"
							onClick={
								() => {
									window.open('https://studio-beta.skillsstack.in/practice/cpp', '_blank');
								}
							}
						>
							<span className={"page-link"}>Practice</span>
						</a>
						<a
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link nav-link-icon active text-dark"
							onClick={
								() => {
									window.open('https://studio-beta.skillsstack.in/practice/cpp', '_blank');
								}
							}
						>
							<i className="material-icons">book</i>
						</a>
					</li>

					{
						state.isLoggedIn && <li className="nav-item">
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link"
								to="/sessions"
							>
								<span className={state.currentScreen === 'sessions' ? "active-page-link" : "page-link"}>Sessions</span>
							</Link>
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link nav-link-icon"
								to="/sessions"
							>
								<i className="material-icons">info</i>
							</Link>
						</li>
					}

					{/* {
						!state.isLoggedIn && <li className="nav-item">
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link"
								to="/corporate-training"
							>
								<span className={state.currentScreen === 'corporate-training' ? "active-page-link" : "page-link"}>Corporate Training</span>
							</Link>
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link nav-link-icon"
								to="/corporate-training"
							>
								<i className="material-icons">info</i>
							</Link>
						</li>
					} */}


					{
						!state.isLoggedIn && <li className="nav-item">
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link"
								to="/?contact_us=true"
							>
								<span className="page-link">Contact Us</span>
							</Link>
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link nav-link-icon"
								to="/sessions"
							>
								<i className="material-icons">info</i>
							</Link>
						</li>
					}

					<li className="nav-item">
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link text-dark"
							to="/discussion"
						>
							<span className={state.currentScreen === 'discussion_board' ? "active-page-link" : "page-link"}>Discussion Board</span>

						</Link>
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link nav-link-icon text-dark"
							to="/discussion"
						>
							<i className="material-icons">announcement</i>
						</Link>
					</li>

					{/* <li className="nav-item">
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link text-dark"
							to="/blogs"
						>
							<span className={state.currentScreen === 'discussion_board' ? "active-page-link" : "page-link"}>Blogs</span>

						</Link>
						<Link
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
							className="nav-link nav-link-icon text-dark"
							to="/blogs"
						>
							<i className="material-icons">announcement</i>
						</Link>
					</li> */}

					<li className="nav-item dropdown">
						<a className="nav-link text-white dropdown-toggle" style={{
							color: '#000',
							fontWeight: '600'
						}} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Our Programs
						</a>
						<div className="dropdown-menu background-primary" style={{
							fontSize: '0.9rem'
						}} aria-labelledby="navbarDropdown">
							<Link
								className="dropdown-item"
								to="/course/full-stack-web-developement-program"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<span className={"page-link"}>Full Stack Program</span>

							</Link>
							<Link
								className="dropdown-item"
								to="/workshop/mock-interview-program"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<span className={"page-link bold-500"}>Interview Program</span>

							</Link>
							<Link
								className="dropdown-item"
								to="/workshop/machine-learning-bootcamp/"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<span className={"page-link bold-500"}>ML Bootcamp</span>

							</Link>
						</div>
					</li>

					{!state.isLoggedIn ? (
						<li className="nav-item">
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link"
								to="/login"
							>
								<span className={state.currentScreen === 'login' ? "active-page-link" : "page-link"}>Login</span>
							</Link>
							<Link
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
								className="nav-link nav-link-icon"
								to="/login"
							>
								<i className="material-icons">person_pin</i>
							</Link>
						</li>
					) : (

						<li className="nav-item dropdown">
							<a className="nav-link text-white dropdown-toggle" style={{
								color: '#000',
								fontWeight: '600'
							}} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Account
							</a>
							<div className="dropdown-menu background-primary" style={{
								fontSize: '0.9rem',
							}} aria-labelledby="navbarDropdown">
								<Link
									data-toggle="collapse"
									data-target=".navbar-collapse.show"
									className="dropdown-item"
									to="/profile"
								>
									<span className={"page-link"}>Profile</span>
								</Link>



								<Link
									onClick={() => {
										localStorage.removeItem(localStorageKeys.userDetails);

										sendLogs("User Logout", true, {
											user: state.user.email,
											username: state.user.name,
										}, "User Logged Out", null);

										setState({
											...initialState
										});

									}}
									data-toggle="collapse"
									data-target=".navbar-collapse.show"
									className="dropdown-item"
									to="/login"
								>
									<span className={"page-link"}>Logout</span>

								</Link>

							</div>
						</li>

					)}

					{/* <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Account
              </Link>
              <div
                className="dropdown-menu navbar-dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/">
                  Login
                </Link>
              </div>
            </li> */}
					{/* <li className="nav-item">
              <div className="search-form-nav">
                <input
                  className="form-control search-input"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="nav-search-button" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </li> */}
				</ul>
			</div>
		</nav>
	);
}