const { toast } = require("react-toastify");

console.log(process.env.NODE_ENV)
const config = {
    baseUrl: "https://api.skillsstack.in",
    // baseUrl: "http://localhost:5000",
    toastrOption: {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    },
    firebaseConfig: {
        apiKey: "AIzaSyBHYrqhyXIBPZDTYGbW47IQ27TkS-4sDQ0",
        authDomain: "skills-stack.firebaseapp.com",
        projectId: "skills-stack",
        storageBucket: "skills-stack.appspot.com",
        messagingSenderId: "100547665678",
        appId: "1:100547665678:web:7a5c3b204581d50757415f",
        measurementId: "G-GYD45SJM6S"
    },
    localStorageKeys: {
        userDetails: "skill-stream-offline-user"
    }
}

module.exports = config;