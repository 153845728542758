import React from 'react'
import './AskQuestionFrame.css'
import axios from 'axios'
import config from '../../../utils/config'
import Context from '../../../Context'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../../../Components/Spinners/Spinner'
import { Navigate } from 'react-router'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor';

export default function AskQuestionFrame() {
    const [question, setQuestion] = React.useState('')

    const [description, setDescription] = React.useState('')
    const [tags, setTags] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false);
    const [category, setCategory] = React.useState("");
    const [categories, setCategories] = React.useState([
        "Web Development", 
        "Mobile Development",
        "Data Science",
        "Machine Learning",
        "Artificial Intelligence",
        "Cloud Computing",
        "Blockchain",
        "Cryptocurrency",
        "Gaming",
        "Databases",
        "Software Engineering",
        "Other"
    ]);

    const [redirect, setRedirect] = React.useState(false);
    const { state } = React.useContext(Context);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const data = {
                title: question,
                content: description,
                tags: tags.split(',').map(tag => tag.trim()),
                category,
            }
            const resp = await axios.post(`${config.baseUrl}/posts/`, data, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })

            if (resp.data.response.status === 200) {
                toast.success(resp.data.response.message);
                setQuestion('');
                setDescription('');
                setTags('');
                setCategory('');
                // setLink({ url: '', title: '' });
                // setImage({ url: '', title: '' });
                setRedirect(true);
            }
            setIsLoading(false);
        } catch (err) {
            toast.error("Could not post question. Please try again later.", config.toastrOption);
            setIsLoading(false);
        }
    }

    return (
        <div className='container-fluid'>
            <ToastContainer />
            <Spinner show={isLoading} />
            {
                redirect && <Navigate to='/discussion' />
            }
            <div className="card ask-question-card" role="document">
                <div className="card-header text-center">
                    <p className="h4" style={{
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        color: '#212529',
                        textAlign: 'center'
                    }} id="exampleModalLabel">Start a discussion !</p>
                </div>
                <div className="ask-question-card">

                    <div className="form-group">
                        <label htmlFor="question">Question <small className='text-muted'><br />Be specific and imagine you're asking a question to another person</small></label>
                        <input value={question} onChange={(e) => {
                            setQuestion(e.target.value)
                        }} type="text" className="form-control" id="question" placeholder="Enter your question" />
                    </div>

                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div className="form-group">
                                <label htmlFor="category">Category
                                    <small className='text-muted'><br />Select a category that best describes your question</small>
                                </label>
                                <select value={category} onChange={(e) => {
                                    setCategory(e.target.value)
                                }} className="form-control" id="category">
                                    {categories.map((category, index) => {
                                        return <option key={index} value={category}>{category}</option>
                                    })}
                                </select>

                            </div>
                        </div>

                        <div className='col-md-6 col-sm-12'>
                            <div className="form-group">
                                <label htmlFor="tags">Tags
                                    <small className='text-muted'><br />Separate tags with a comma</small>
                                </label>
                                <input type="text" className="form-control" id="tags" placeholder="Enter tags e.g. git, java, c++" value={tags} onChange={(e) => {
                                    setTags(e.target.value)
                                }} />
                            </div>
                        </div>
                    </div>

                    <div id='description-form-group' className="form-group">
                        <label htmlFor="question">Description <small className='text-muted'>(Supports markdown)<br />Include all the information someone would need to answer your question</small> </label>
                        <MarkdownEditor setContent={setDescription} />
                    </div>

                    <button onClick={(e) => {
                        handleSubmit(e)
                    }} type="button" className="btn btn-primary btn-block">Submit</button>

                </div>

            </div>
        </div>
    )
}
