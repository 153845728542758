import React from 'react'
import Context from '../../../Context'
import AskQuestionFrame from '../AskQuestionFrame/AskQuestionFrame';
import LoginModal from '../../../Modals/LoginModal/LoginModal';

export default function AskQuestionScreen() {

    const { state, setState } = React.useContext(Context);

    React.useEffect(() => {
        document.title = 'Ask Question | Skills Stack';
    }, [])

    return (
        <div className="main-container">
            {
                !state.isLoggedIn ?
                    <LoginModal /> : <AskQuestionFrame />
            }
        </div>
    )
}
