import axios from 'axios';
import React from 'react'
import { Link } from 'react-router-dom';
import config from '../../utils/config';
import Spinner from '../Spinners/Spinner';

export default function Contact() {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');

    const setMessageForm = (isError, message) => {
        setIsError(isError);
        setErrorMessage(message);
        setIsSuccess(!isError);
        setSuccessMessage(message);
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault()
        setIsLoading(false);
        if (!name) {
            setMessageForm(true, 'Name is required');
            return;
        }
        if (!email) {
            setMessageForm(true, 'Email is required');
            return;
        }
        if (!message) {
            setMessageForm(true, 'Message is required');
            return;
        }
        if (!checked) {
            setMessageForm(true, 'Please agree to the terms and conditions');
            return;
        }

        const body = { name, email, message, terms: checked };

        axios.post(`${config.baseUrl}/landing/message`, body).then(res => {

            if (res.status === 200) {
                setMessageForm(false, 'Request Submitted Successfully');
            } else {
                setMessageForm(true, res.data.message);
            }
        }).catch(err => {
            setMessageForm(true, err.response.data.message || 'Something went wrong');
        })


        setMessageForm(false, 'Thank you for registering, we will get back to you soon.');
    }


    return (
        <div id="contact" className="form-3">
            <Spinner show={isLoading} />
            <div className='landing-component-all'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text-container">
                                <h2 className='text-white'>Contact Details</h2>
                                <p style={{
                                    color: '#fff',
                                }}>
                                    For registration questions please get in touch using the contact
                                    details below. For any questions use the form.
                                </p>
                                <h3 style={{
                                    color: '#fff',
                                }}>Our Offices</h3>
                                <ul style={{
                                    color: '#fff',
                                }} className="list-unstyled li-space-lg">

                                    <li className="media">
                                        <div className="media-body">
                                            <i className="fas fa-map-marker-alt text-white"></i
                                            >&nbsp; 123A/54 Swaroop Nagar, Kanpur - 208015
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-body">
                                            <i className="fas fa-map-marker-alt text-white"></i
                                            >&nbsp; 6655 Pattonsburg Dr, Charlotte, North Carolina, US - 28213
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-body">
                                            <i className="fas text-white fa-mobile-alt" style={{
                                                color: '#8710d8',
                                            }}></i
                                            >&nbsp; <a className='text-white' href='tel:+17047805351'> +1 (704)-780-5351</a> &nbsp;&nbsp;<i
                                                className="fas text-white fa-mobile-alt"
                                            ></i> <a className='text-white' href='tel:+917985081204'>+91 7985081204</a>
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-body">
                                            <i className="fab fa-whatsapp"></i
                                            >&nbsp; <a className='text-white' href='https://wa.me/+17047805351'> +1 (704)-780-5351</a> &nbsp;&nbsp;<i
                                                className="fab fa-whatsapp"
                                            ></i
                                            > <a className='text-white' href='https://wa.me/+917985081204'>+91 7985081204</a>
                                        </div>
                                    </li>
                                    <li className="media">

                                        <div className="media-body">
                                            <i className="fas text-white fa-envelope"></i> <a className="light-gray text-white" href="mailto:team@skillsstack.in"
                                            >team@skillsstack.in</a
                                            >
                                            <i className="fas text-white fa-globe"></i
                                            ><a className="light-gray text-white" href="https://skillsstack.in"
                                            >www.skillsstack.in</a
                                            >
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <form onSubmit={handleSubmit} id="contactForm" data-toggle="validator" data-focus="false">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={"form-control-input" + (name ? ' notEmpty' : '')}
                                        id="cname"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <label className="label-control" htmlFor="cname">Name</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className={"form-control-input" + (email ? ' notEmpty' : '')}
                                        id="cemail"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label className="label-control" htmlFor="cemail">Email</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        className={"form-control-textarea" + (message ? ' notEmpty' : '')}
                                        id="cmessage"
                                        required
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                    <label className="label-control" htmlFor="cmessage">Your message</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div style={{
                                    color: '#fff',
                                }} className="form-group checkbox">
                                    <input
                                        type="checkbox"
                                        id="cterms"
                                        value="Agreed-to-Terms"
                                        required
                                        checked={checked}
                                        onChange={() => setChecked(!checked)}
                                    />I've read and agree to Skill Stack's written {" "}
                                    <Link style={{
                                        color: '#fff',
                                    }} to="/privacy-policy">Privacy Policy</Link> and {" "}
                                    <Link style={{
                                        color: '#fff',
                                    }} to="/terms-conditions">Terms & Conditions.</Link>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="form-control-submit-button">
                                        SUBMIT
                                    </button>
                                </div>
                                <div className="form-message">
                                    {
                                        isError && <div id="rmsgSubmit" className="h3 text-center hidden text-capitalize">{errorMessage}</div>
                                    }
                                    {
                                        isSuccess && <div id="rmsgSubmit" className="h3 text-center hidden text-capitalize">{successMessage}</div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
