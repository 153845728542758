import React from 'react'
import { Navigate, useParams } from 'react-router'
import config, { localStorageKeys } from '../../utils/config'
import axios from 'axios'
import Context from '../../Context'
import { ToastContainer, toast } from "react-toastify";

export default function MimicScreen() {
    const params = useParams()
    const { state, setState } = React.useContext(Context)
    const [redirect, setRedirect] = React.useState(false)

    const loadData = async (email) => {
        try {
            const resp = await axios.post(`${config.baseUrl}/users/mimic/${email}`, {}, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
            if (resp.status === 200) {
                console.log(resp.data)
                localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                    token: resp.data.response.data.token,
                    sessionStartTime: new Date().getTime()
                }));

                toast.success("Login Successful", config.toastrOption);
                setState({
                    ...state,
                    isLoggedIn: true,
                    user: resp.data.response.data.user,
                    isGuest: false,
                    token: resp.data.response.data.token,

                });

                // setTimeout(() => {
                //     setRedirect(true);
                // }, 3000);
            }
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        const email = params.email;
        if (email) {
            loadData(email);
        }
    }, [params]);

    return (
        <div> <ToastContainer />
            {
                redirect ? <Navigate to="/" /> : null
            }
        </div>
    )
}
