import React from 'react'
import AdsenseAd from '../../Components/AdsenseAd/AdsenseAd'

export default function () {
  return (
    <div>
        <AdsenseAd />
        <AdsenseAd />
    </div>
  )
}
