import React from 'react'
import './CampusAmbassadorProgram.css'

export default function CampusAmbassadorProgram() {

	const loadUrl = (url) => {
		window.open("https://forms.gle/VfDEJPDKFEDKhhKh7", '_blank');
	}

	return (
		<div id='amb' style={{
			backgroundColor: "#fff",
		}}>
			<div className='container'>
				<div className="row">
					<div className="col-12 text-center">
						<h2>Campus Ambassador Program</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-12">
						<div className="col-12">
							<p className='p para'>
								We are looking for enthusiastic and passionate students to be a part of our <b>Campus Ambassador Program</b>. If you are a student who is looking to increase your socail circle, meet new people and learn new skills, this is the perfect opportunity for you. You will be responsible for promoting our products and services to your peers and other students in your college.
							</p>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<h5>About the Program</h5>
							</div>
							<div className="card-body">


								<p className='p'>
									It is a <b>state-of-the-art internship program</b> that will help you find success as a professional. Campus ambassadors represent Skills Stack and are given the responsibility of hosting events such as hackathons, competitions, inductions, or webinars as well.
								</p>
							</div>
						</div>
					</div>


					<div className="col-12">
						<div className="card right-border">
							<div className="card-header">
								<h5>Eligibility Criteria</h5>

							</div>
							<div className="card-body">
								<ul>
									<li>
										The first one is that you must be enrolled in a B.Tech, M.Tech, BCA, or MCA degree program.
									</li>
									<li>The second requirement is to be an active contributor to college events or involved in college activities. Even if this criterion is not met, it is enough if you are a part of a club, society, or community on your college campus.</li>
								</ul>
							</div>
						</div>

					</div>
				</div>


				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="card-title">
									<h5>Responsibilities:
									</h5>
								</div>
							</div>
							<div className="card-body">
								If you choose to represent Skills Stack on your college campus, your day-to-day responsibilities include:

								<ul>
									<li>
										Executing all tasks/activities given by Skills Stack in your college (this will involve tasks to be executed on your college campus)
									</li>

									<li>
										Introduce our various events & programs to your college peers.
									</li>

									<li>
										Posting content regarding Skills Stack on your social media feeds (the content will be provided by Skills Stack)
									</li>
								</ul>

							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card right-border">
							<div className="card-header">
								<div className="card-title">
									<h5>Process of Becoming a Campus Ambassador
									</h5>
								</div>
							</div>
							<div className="card-body">
								Here are the steps involved in becoming a campus ambassador:

								<ul>
									<li>
										First, you must go to <a target={"_blank"} href='https://forms.gle/VfDEJPDKFEDKhhKh7'>the registration page (click here)</a> and register with your academic information and other details.
									</li>

									<li>
										Then, you must wait for your application to become shortlisted.
									</li>

									<li>
										Once shortlisted, you will be asked to attend a Google Meet or over-the-call interview with Skills Stack’s representatives.
									</li>

									<li>
										If you are selected, then you will be sent your offer letter and be given the details of your joining and your immediate tasks.
									</li>
									<li>
										Before starting, you will also be given an induction session that will clear your doubts and introduce you to the responsibilities of a campus ambassador.

									</li>
									<li>
										After all the formalities are complete, you will officially be representing Skills Stack on your campus and you can start your tasks.
									</li>
								</ul>

							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header">
								<div className="card-title">
									<h5>Career Benefits of Skills Stack Campus Ambassador Program
									</h5>
								</div>
							</div>
							<div className="card-body">
								Becoming a member of Skills Stack has many benefits. Other than becoming a part of India’s best upcoming edTech platform, you will also be amplifying your social impact by bringing together your fellow students for the purpose of learning new skills and acing interviews. There will also be a reward for all your effort in the guise of a Campus Ambassador Certificate along with various gifts and goodies by Skills Stack which will improve your employability and credibility.

								<br />
								<br />
								Here are some more benefits that the program brings:
								<br />
								<br />


								<ol>


									<li>
										Become the brand ambassador for Skills Stack and use your creativity to stand out from the crowd.
									</li>
									<li>
										Gain leadership skills and increase your network.
									</li>

									<li>
										Get valuable guidance and mentorship from industry professionals and domain experts.
									</li>
									<li>
										Get Skills Stack premium courses for free.
									</li>


									<li>
										Organize seminars, events, and competitions, thus earning credibility as a host, coordinator, and organizer.									</li>
									<li>
										Gain recommendations from experts and essential figures in the industry.
									</li>

									<li>
										Enhance both your technical and interpersonal skills.
									</li>
									<li>
										Become influential and a master of engagement.

									</li>


								</ol>

							</div>
						</div>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-md-4 offset-md-4 col-12">
						<button onClick={loadUrl} className='btn btn-primary-outline btn-block'>Apply Now</button>
					</div>
				</div>
			</div>
		</div>
	)
}
