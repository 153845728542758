import React from "react"
import { Link } from "react-router-dom"
import Context from "../../Context";

export default function TermsandConditions() {
    const { state, setState } = React.useContext(Context);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `Skills Stack - Terms and Conditions`;

        setState({
            ...state,
            currentScreen: "Terms and Conditions"
        })
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-4">

                    <div>
                        <h2 className="text-center mb-4"><strong>Terms and Conditions</strong></h2>

                        <p className='p-small'>Last updated: August 13, 2022</p>
                        <p className='p-small'>Thank you for choosing <b>Skills Stack</b> as your learning partner.</p>

                        <p>These terms and conditions outline the rules and regulations for the use of <strong>Skills Stack</strong>'s Website, located at https://skillsstack.in/.</p>

                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use <strong>Skills Stack</strong> if you do not agree to take all of the terms and conditions stated on this page.</p>

                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                        <h3><strong>Cookies</strong></h3>

                        <p>We employ the use of cookies. By accessing <strong>Skills Stack</strong>, you agreed to use cookies in agreement with the <strong>Skills Stack</strong>'s Privacy Policy. </p>

                        <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

                        <h3><strong>License</strong></h3>

                        <p>Unless otherwise stated, <strong>Skills Stack</strong> and/or its licensors own the intellectual property rights for all material on <strong>Skills Stack</strong>. All intellectual property rights are reserved. You may access this from <strong>Skills Stack</strong> for your own personal use subjected to restrictions set in these terms and conditions.</p>

                        <p>You must not:</p>
                        <ul>
                            <li>Republish material from <strong>Skills Stack</strong></li>
                            <li>Sell, rent or sub-license material from <strong>Skills Stack</strong></li>
                            <li>Reproduce, duplicate or copy material from <strong>Skills Stack</strong></li>
                            <li>Redistribute content from <strong>Skills Stack</strong></li>
                        </ul>

                        <p>This Agreement shall begin on the date hereof. </p>

                        <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. <strong>Skills Stack</strong> does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of <strong>Skills Stack</strong>,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, <strong>Skills Stack</strong> shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

                        <p><strong>Skills Stack</strong> reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

                        <p>You warrant and represent that:</p>

                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                        </ul>

                        <p>You hereby grant <strong>Skills Stack</strong> a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

                        <h3><strong>Hyperlinking to our Content</strong></h3>

                        <p>The following organizations may link to our Website without prior written approval:</p>

                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                        </ul>

                        <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

                        <p>We may consider and approve other link requests from the following types of organizations:</p>

                        <ul>
                            <li>commonly-known consumer and/or business information sources;</li>
                            <li>dot.com community sites;</li>
                            <li>associations or other groups representing charities;</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>accounting, law and consulting firms; and</li>
                            <li>educational institutions and trade associations.</li>
                        </ul>

                        <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of <strong>Skills Stack</strong>; and (d) the link is in the context of general resource information.</p>

                        <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

                        <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to <strong>Skills Stack</strong>. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

                        <p>Approved organizations may hyperlink to our Website as follows:</p>

                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                        </ul>

                        <p>No use of <strong>Skills Stack</strong>'s logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

                        <h3><strong>iFrames</strong></h3>

                        <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

                        <h3><strong>Content Liability</strong></h3>

                        <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

                        <h3><strong>Your Privacy</strong></h3>

                        <p>Please read our <Link to={"/privacy-policy"}>Privacy Policy</Link></p>
                    </div>

                    <div id="jobguarantee">
                        <h3 ><strong>Job Guarantee: Cashback Program</strong></h3>

                        <ul >
                            {/* <li>Students selected after qualifying our <a href="#eligibility_for_refund">scholarship + eligibility exam</a> will be entitled to Cashback program.</li> */}
                            <li>Students selected after qualifying our scholarship + eligibility exam will be entitled to the Cashback program.</li>
                            <li>In order to be eligible for our Job Guarantee Cashback Program, it is mandatory to complete the program in the first attempt within 5 months from the commencement date.</li>
                            <li> You must meet the criteria listed for <a href="#placement_eligibility">placement eligibility</a></li>
                            <li>You must not engage in any malpractice or plagiarism during the course of the program.</li>
                        </ul>

                        <p><strong>If we are unable to get you placed within 6 months from the program completion date, then we will refund your course fee (excluding 18% GST). The refund payment processing will take around 20-25 working days.</strong></p>

                        Please Note-
                        <ul>
                            <li>
                                For students pursuing UG/PG, under the Job Guarantee Program, we begin your recruitment process in accordance with the university policies. If you are still a full-time student and are not allowed to work but you have completed our requirements for the Job Guarantee Program, we defer your placements till you have graduated. Upon graduation, Skills Stack will reconnect and assure your placements. Under such a situation, your 6 month period will start after your successful UG/PG program completion. Meanwhile, as you pursue your UG/PG, we may provide you with internship opportunities after the successful completion of course assignments.
                            </li>
                            <li>
                                Non Eligibility Criteria for the Cashback
                                <ul>
                                    <li>
                                        if you decline an offer from Skills Stack
                                    </li>
                                    <li>
                                        if you accept an offer during the placement period from other sources or get selected through another mechanism apart from Skills Stack
                                    </li>
                                    <li>
                                        behaviour issues – not turning up for interviews, unprofessional behaviour with the recruiting companies or our placement team.
                                    </li>
                                    <li>
                                        The interviews will be scheduled with the candidate's consent. It is mandatory for the candidate to complete the process requirements such as submitting mock assignments, appearing for aptitude tests etc. on time and attending scheduled interviews. You will not be eligible for the refund If you fail to attend 2 or more interview process.
                                    </li>
                                    <li>
                                        if you are not interested in applying for jobs
                                    </li>
                                    <li>
                                        If the number of recruitment processes (tests/assignments, interview rounds, face to face, telephonic, virtual) provided from Skills Stack is 5 or more and the candidate is unable to get to an offer.
                                    </li>
                                </ul>
                            </li>
                            <li>These terms and conditions may be modified. The most recent changes or revisions will appear on the website.</li>
                        </ul>
                    </div>

                    {/* <div id="eligibility_for_refund">
                        <br />
                        <br />
                        <br />
                    </div>

                    <div>
                        <h3><strong>Scholarship + Eligibility Exam</strong></h3>
                        <p>This exam is to check the student's basic mathematics concepts and analytical reasoning abilities, as this is the most important </p>
                    </div> */}

                    <div id="placement_eligibility">
                        <br />
                        <br />
                        <br />
                    </div>
                    <div >
                        <h3 ><strong>Placement Eligibility</strong></h3>
                        <p>The logic behind having <b>placement eligibility criteria</b>- The learner needs to understand that the successful placement can not happen if they have not focused on their learning.  The current score taken into  consideration for placement connects is a cut off of 60%  in our program. Unless criteria is fulfilled it will be a futile effort to sit for interviews as we have seen multiple cases of back to back interview rejections without any offer  for learners with less than 60% score. Those learners have had to go through revision classes to end up getting placed finally. This will take additional time and energy, hence we support all our learners to score this 60% in the first attempt itself and it is very much achievable. We even provide  3 attempts for all exams and we consider only the highest marks (out of 3) for evaluation purposes. Also, the learners not matching the 60% criteria may be given an extra chance (free of cost) to improve their scores and be eligible for the placement process.</p>


                        <p><b>Placement Training-</b> In 1:1 mentorship program, after the completion of all modules in the program, the shortlisted learners are connected with industry coaches for 1:1 coaching/ mentoring and soft skills training.  The learners work with the coaches for filling their knowledge gaps and building their project portfolio (atleast 2 Capstone Projects), improving their resume and preparing for interviews. We also arrange free of cost soft skills and Aptitude training for our shortlisted learners (if required). The learners are required to be regular and perform well in the placement training.  The Placement training is offered for learners who have opted for career services through either 1 on 1 mentorship program or signing the Income Share Agreement (ISA) with us. </p>

                        <p><b>Job Connect-</b> We connect the learners with potential employers post their successful completion of the program.  After completing the personalized coaching and soft skill training, the learners are connected to a curated set of employers including startups, Mid Size Organizations and Fortune 500 companies. We will try to consider your employment preference e.g. industry, location, and salary package. However, it purely depends on the Industry expert's feedback and learner's performance during the program.
                            As a policy, we aim to provide a minimum of 3 lakhs for freshers/any professional (for B.Tech or MCA, 2.25 lakhs for other graduates) and 15% salary hike for working professionals. However, market conditions might require an initial internship process for 3-6 months and then obtain the final package (for freshers/learners currently not working/ for freshers who graduated and not having any job). In cases of working professionals, sometimes career switch at the same package could also happen.</p>
                    </div>

                    <br />

                    <h3><strong>Reservation of Rights</strong></h3>

                    <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

                    <h3><strong>Removal of links from our website</strong></h3>

                    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

                    <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

                    <h3><strong>Disclaimer</strong></h3>

                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

                    <ul>
                        <li>limit or exclude our or your liability for death or personal injury;</li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                    </ul>

                    <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

                    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                </div>
            </div>
        </div>
    )
}