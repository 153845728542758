import axios from 'axios';
import React from 'react'
import { toast, ToastContainer } from 'react-toastify';
import config from '../../utils/config';
import { Link } from "react-router-dom";
import InterviewPreperationCard from './InterViewPreperation/InterviewPreperationCard';
import MasterClass from './MasterClass/MasterClass';
import MasterClassCard from './MasterClass/MasterClassCard';

export default function Programs({ title, dontShowCourse, dontShowStatic, showMasterclass=true, showSpiner=true }) {

    const [courses, setCourses] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);

    const masterclasses = []

    const staticCourses = [
        {
            title: "Full Stack Development Internship",
            description: "The Full MERN Stack Development Internship is a 60 day program developed to learn job ready web development skills from industry experts. Become a Full Stack Developer and learn how to build applications such as Instagram, Amazon, Quora, IMDB, and lots more. In this course you will build a real-world project with modern front-end and back-end technologies like HTML5, CSS3, JavaScript, NodeJS, MongoDB and React.",
            footer: "A 60 day workshop from industry expert to master Full Stack Development",
            image: "fs-winter-internship.png",
            link: "/workshop/full-stack-summer-internship",
            youtube: "https://www.youtube.com/watch?v=Fejrjr7Wlzw"
        },
        {
            title: "Machine Learning Bootcamp",
            description: "The course will nurture and transform you into a highly-skilled professional with an in-depth knowledge of various algorithms and techniques, such as regression, classification, supervised and unsupervised learning, Natural Language Processing.",
            footer: "A 10 day workshop from industry expert to master Machine Learning",
            image: "ml-card.png",
            link: "/workshop/machine-learning-bootcamp",
            youtube: "https://www.youtube.com/watch?v=Fejrjr7Wlzw"
        },
        {
            title: "Advanced Python Masterclass",
            description: "Learn the Basic Python concepts such as loops, lists and tuples. Get a chance to learn python modules such as numpy. A 2 hour masterclass to kickstart your machine learning and data science career.",
            footer: "Free Session on Advanced Python Concepts",
            image: "python_masterclass-card.png",
            link: "/masterclass/advanced-python-masterclass",
            youtube: "https://www.youtube.com/watch?v=Fejrjr7Wlzw"
        },
        {
            title: "Exclusive React Bootcamp",
            description: "From high-tech MNC’s to your upcoming start-ups, React is a preferrable choice for many to develop interactive mobile or web applications. This masterclass will walk you through the basic concepts and principles of React by taking a Project Oriented Approach.",
            footer: "A 10 day workshop from industry expert to master ReactJS",
            image: "react-workshop-card.png",
            link: "/workshop/exclusive-react-bootcamp",
            youtube: "https://www.youtube.com/watch?v=Fejrjr7Wlzw"
        },
        {
            title: "Frontend Development Masterclass",
            description: "Get a chance to learn basic of Frontend Development and get started on your professional journey of becoming Full Stack Developer. A 3 hour masterclass from the best of the industry.",
            footer: "Free Session on Basics of Frontend Development",
            image: "front-end-masterclass-card.png",
            link: "/course/frontend-masterclass",
            youtube: ""
        },
        {
            title: "Introduction to MongoDB",
            description: "Get a chance to learn basic of MongoDB and get started on your professional journey of becoming Full Stack Developer. A 2.5 hours masterclass from the best of the industry.",
            footer: "Free Session on Basics of NoSQL Database",
            image: "mongodb-bootcamp-card.png",
            link: "/course/mongodb-masterclass",
            youtube: "https://www.youtube.com/watch?v=SMaU2Z_IHus"
        },
        {
            title: "Introduction to CSS Animations",
            description: "Get a chance to learn CSS Animations, transitions and Transformations. After this class you will be able to create any animation you can image. A 2 hours masterclass from the best of the industry.",
            footer: "Free Session on CSS Animations",
            image: "css-animation-bootcamp-card.png",
            link: "/course/css-animation-masterclass",
            youtube: "https://www.youtube.com/watch?v=h_CNJIH0l_g"
        }
    ]

    const getAllCourses = async () => {
        try {
            setIsLoading(true);
            const resCourse = await axios.get(`${config.baseUrl}/courses/top`);

            setCourses(resCourse.data.response.data);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("Error getting courses", config.toastrOption);
        }
    }

    React.useEffect(() => {
        getAllCourses();
    }, []);


    return (
        <div className="container-fluid">
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <p className="course-heading-message text-capitalize">{title || "Our Programs"}</p>
                </div>
            </div>
            {
                courses.length > 0 && <div className="row ">
                    {
                        courses.map((course, index) => {
                            return dontShowCourse && course.name == dontShowCourse ? <div key={index}></div> : (<div className="col-lg-3 col-md-4 col-sm-6 course col-xs-12" key={index} >
                                <Link className="card mb-4 shadow-sm course-card" to={course.is_workshop ? `/workshop/${course.slug}` : `/course/${course.slug}`} key={index}>
                                    <img src={course?.card_image ? course?.card_image?.startsWith("http") ? course.card_image : require(`../../assets/images/${course.card_image}`) : course.image} alt={course.name} className="card-img-top course-image" style={{
                                        borderBottom: "1px solid #e5e5e5"
                                    }} />
                                    <div className="card-body">
                                        <h5 className="card-title course-card-title">{course.name}</h5>
                                        <p className="card-text course-card-text">{course.description}</p>
                                    </div>
                                    {
                                        course.card_footer && <div className="card-footer">
                                            <small className="text-muted">{course.card_footer}</small>
                                        </div>
                                    }
                                </Link>
                            </div>
                            )
                        })

                    }

                    {/* <div className="col-lg-3 col-md-4 col-sm-6 course col-xs-12">
                        <Link className="card mb-4 shadow-sm course-card" to={`/course/interview-preparation-program`}>
                            <InterviewPreperationCard />
                        </Link>
                    </div> */}

                </div>
            }

            {masterclasses.length > 0 && showMasterclass && <><div className="row mt-4">
                <div className="col-12">
                    <p className="course-heading-message text-capitalize">{"Our Upcoming Masterclasses"}</p>
                </div>
            </div>

                {
                    masterclasses.length > 0 && <div className="row ">
                        {
                            masterclasses.map((course, index) => {
                                return dontShowStatic && dontShowStatic == course.title ? <div key={index}></div> : (
                                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 course col-xs-12">
                                        <Link className="card mb-4 shadow-sm course-card" to={course.link}>
                                            <MasterClassCard title={course.title} description={course.description} footer={course.footer} image={course.image} youtube={course.youtube} />
                                        </Link>
                                    </div>
                                )
                            })
                        }

                        {/* <div className="col-lg-3 col-md-4 col-sm-6 course col-xs-12">
                        <Link className="card mb-4 shadow-sm course-card" to={`/course/interview-preparation-program`}>
                            <InterviewPreperationCard />
                        </Link>
                    </div> */}

                    </div>
                }
            </>}



            <div className="row mt-4">
                <div className="col-12 mt-4">
                    <p className="course-heading-message text-capitalize">{"Our Completed Events "}</p>
                </div>
            </div>

            <div className="row">
                {
                    staticCourses.map((course, index) => {
                        return dontShowStatic && dontShowStatic == course.title ? <div key={index}></div> : (
                            <div key={index} className="col-lg-3 col-md-4 col-sm-6 course col-xs-12">
                                <Link className="card mb-4 shadow-sm course-card" to={course.link}>
                                    <MasterClassCard title={course.title} description={course.description} footer={course.footer} image={course.image} youtube={course.youtube} />
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
