import React from 'react'
import './Countdown.css'

export default function Countdown(props) {

    const [days, setDays] = React.useState(0)
    const [hours, setHours] = React.useState(0)
    const [minutes, setMinutes] = React.useState(0)
    const [seconds, setSeconds] = React.useState(0)

    React.useEffect(() => {

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        let today = new Date(),
            dd = String(today.getDate()).padStart(2, "0"),
            mm = String(today.getMonth() + 1).padStart(2, "0"),
            yyyy = today.getFullYear(),
            nextYear = yyyy,
            dayMonth = "06/30/",
            birthday = dayMonth + yyyy;

        today = mm + "/" + dd + "/" + yyyy;
        
        if (today > birthday) {
            birthday = dayMonth + nextYear;
        }
        //end
        const countDown = new Date(birthday).getTime();

        const x = setInterval(function () {

            const now = new Date().getTime();
            const distance = countDown - now;

            setDays(Math.floor(distance / (day)));
            setHours(Math.floor((distance % (day)) / (hour)));
            setMinutes(Math.floor((distance % (hour)) / (minute)));
            setSeconds(Math.floor((distance % (minute)) / second));

            if (distance < 0) {
                // document.getElementById("headline").innerText = "It's my birthday!";
                // document.getElementById("countdown").style.display = "none";
                // document.getElementById("content").style.display = "block";
                // clearInterval(x);
            }
        }, 0)


    }, [props])

    return (
        <div id='countdowntimer' className=''>
            <h1><span className='best-seller-badge mr-2'>Early Bird Offer</span></h1>

            <div id="countdown">
                <li><span className="days">{days}</span>days</li>
                <li><span className="hours">{hours}</span>Hours</li>
                <li><span className="minutes">{minutes}</span>Minutes</li>
                <li><span className="seconds">{seconds}</span>Seconds</li>
            </div>
        </div>
    )
}
