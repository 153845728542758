import React from 'react'
import { Navigate } from 'react-router';
import Context from '../../../Context';
import sendLogs from '../../../utils/telemetry';
import { AddMinutesToDate, formatAMPM, SubtractMinutesToDate } from '../../../utils/time_functions';

export default function SessionCard({ index, session, course }) {

    const { state, setState } = React.useContext(Context);
    const [redirectToVideo, setRedirectToVideo] = React.useState(false);
    const [selectedSession, setSelectedSession] = React.useState(null);

    const openURL = (url, isSession = false, session, isLocalVideo) => {
        if (isLocalVideo) {
            setRedirectToVideo(true);
            setSelectedSession(session);
            return;
        }
        if (isSession) {

            sendLogs("Start Session", true, {
                user: state.user ? state.user.firebase_id : "Anonymous",
                username: state.user ? state.user.name : "Anonymous",
                session: {
                    session_id: session._id,
                    topic: session.topic,
                    is_demo: session.is_demo_session || false,
                },
            }, "Started session", null);


        } else {
            sendLogs("View Session", true, {
                user: state.user ? state.user.firebase_id : "Anonymous",
                username: state.user ? state.user.name : "Anonymous",
                session: {
                    session_id: session._id,
                    topic: session.topic,
                    is_demo: session.is_demo_session || false,
                },
            }, "Started Viewing session recording", null);
        }


        window.open(url, '_blank');
    }

    const sessionDate = SubtractMinutesToDate(new Date(session.start_datetime), 0);
    return (
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 session-card mb-2" key={index} >
            {
                redirectToVideo && <Navigate to={`/video/session/${selectedSession._id}`} />
            }
            <div className="card session-detail-card" key={index}>
                <div className="card-body session-detail-card-body">
                    <p title={session.topic} className="session-card-title">{session.topic}</p>
                    <hr />
                    {
                        course ? <p className="session-card-text">{course}</p> : <p className="session-card-text"><i className='fa fa-user'></i> &nbsp;&nbsp;&nbsp;{session.instructor.name}</p>
                    }
                    <hr />
                    <p className="session-card-text"><i className='fa fa-clock'></i> &nbsp;&nbsp;&nbsp;{sessionDate.toDateString()}, {formatAMPM(sessionDate)}</p>

                </div>

                {
                    AddMinutesToDate(sessionDate, session.duration) <= new Date() ? <div className="session-card-footer">
                        {session?.sessions_video_link ? <button onClick={() => openURL(session.sessions_video_link, false, session, session.is_local_video)} target="_blank" className="mt-end btn btn-primary">View Session <i className="fa fa-eye" aria-hidden="true"></i></button> :
                            <button className="mt-end btn btn-secondary"><i className="fa fa-eye" aria-hidden="true"></i></button>}
                    </div> : <div className="session-card-footer">
                        {session?.session_link && SubtractMinutesToDate(sessionDate, 10) <= new Date() ? <button onClick={() => {
                            openURL(session.session_link, true, session)
                        }} className="mt-end btn btn-primary">Start Session <i className="fa fa-eye" aria-hidden="true"></i></button> :
                            <button title='Session Will be unlocked 10 minutes before the start time' className="mt-end btn btn-secondary"><i className="fa fa-lock" aria-hidden="true"></i></button>}
                    </div>
                }
            </div>
        </div>
    )
}
