import axios from 'axios';
import React from 'react'
import { useParams } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import Context from '../../Context';
import LoginModal from '../../Modals/LoginModal/LoginModal';
import config from '../../utils/config';
import './VideoScreen.css'

export default function VideoScreen() {
    const params = useParams();
    const [session, setSession] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [video, setVideo] = React.useState(null);
    const { state, setState } = React.useContext(Context);


    const loadSessionDetails = async () => {
        try {
            const response = await axios.get(`${config.baseUrl}/sessions/video/${params.id}`, {
                headers: {
                    "Authorization": `Bearer ${state.token}`
                }
            })
            if (response.data.response.status === 200) {
                setSession(response.data.response.data);
                if (!response.data.response.data) {
                    toast.error('No session found', config.toastrOption);
                }
                setLoading(false);

            } else {
                setLoading(false);
                console.log("Error", response.data.response);
                toast.error(response.data.response.text, config.toastrOption);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something Went Wrong !", config.toastrOption);
        }
    }

    React.useEffect(() => {
        if (state.token && params.id) {
            loadSessionDetails();
        }
    }, [state.token])

    return (
        <div className='main-container'>

            <ToastContainer />
            {
                state.isLoggedIn ? session ? <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h5> {session && session.topic }</h5>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    <video crossOrigin='same-origin' onContextMenu={(e) => {
                                        e.preventDefault();
                                    }} className='video-player' disablePictureInPicture controls controlsList='nodownload'>
                                        <source src={`${config.baseUrl}/sessions/video/stream/${session.sessions_video_link}`} type="video/mp4"></source>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>: <p>
                    You are not authorized to view this video
                </p>: <LoginModal />
            }
        </div>
    )
}
