function SubtractMinutesToDate(date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
}

function AddMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

module.exports = {
    SubtractMinutesToDate: SubtractMinutesToDate,
    AddMinutesToDate: AddMinutesToDate,
    formatAMPM: formatAMPM
}