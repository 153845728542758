import React, { useEffect } from 'react'
import { useState } from 'react'
import CourseReview from './CourseReview'

/**
 * 
 * @param {{
 *    reviewsDB: []
 * }} param0 
 * @returns 
 */
export default function CourseReviews({ reviewsDB, reverse }) {

    const reviews = [
        {
            id: 1,
            reviewer: "Sameer Ahmad",
            rating: 5,
            review: "One of the best courses I have ever attended. Clear, fun, very interesting and the coaches are very supportive and friendly. Truly enjoyed the whole journey of Full stack development. Thanks to Skills Stack.",
            createdAt: "2022-10-15T10:42:52.190Z",
            image: "images/reviewers/sameer.jpg"
        },
        {
            id: 2,
            reviewer: "Rahul Kishan Awasthi",
            rating: 5,
            review: "Thank you so much Skills Stack, All my mentors, Instructors and whole Skills Stack team, for your guidance in my way to success. You guys helped me in my hard times and turned me into a Fullstack developer.",
            createdAt: "2022-04-30T10:42:52.190Z",
            image: "images/reviewers/rahul_kishan.png"
        },
        {
            id: 3,
            reviewer: "Arpit Jain",
            rating: 5,
            review: "On a scale of 0-10, I’d definitely give Skills Stack a 10! What I liked most about the course was the individual mentorship concept and the frequent mock interviews. The mentorship sessions helped a lot with my communication skills and increased my confidence.",
            createdAt: "2022-11-30T10:42:52.190Z",
            image: "images/reviewers/arpit_jain.png"
        },
        {
            id: 4,
            reviewer: "Shivani Rastogi",
            rating: 5,
            review: "After my course from Skills Stack, I got to know the importance of discipline, resilience, passion and grit to be successful in my career. Skills Stack doesn't just teach you how to code, it prepares you for a better career ahead.",
            createdAt: "2022-11-30T10:42:52.190Z",
            image: "images/reviewers/shivani.png"
        },
        {
            id: 7,
            reviewer: "Ravi Panasuriya",
            rating: 5,
            review: "I would like to thank skills stack so much for this amazing opportunity, it doesn't come around often. The introduction course gave me a head-start towards a future in an I have tried several courses to learn web development on Youtube but this is by far the best I have come across. It makes learning some of the most complex things easy and fun. The structuring of the course is very impressive.",
            createdAt: "2022-12-03T10:42:52.190Z",
            image: "images/reviewers/ravi_panasuriya.jpg"
        },
        {
            id: 6,
            reviewer: "Asad Mohd",
            rating: 5,
            review: "Thank you so much Skills Stack, All my mentors, Instructors and whole Skills Stack team, for your guidance in my way to success.",
            createdAt: "2023-01-20T10:42:52.190Z",
            image: "images/reviewers/asad.webp"
        },
    ]

    const [showReview, setShowReview] = useState([])

    useEffect(() => {
        if (reviewsDB) {
            setShowReview(() => reviewsDB.reverse())
        }
    }, [reviewsDB])

    return (
        <div className="reviews-container" >
            {reviewsDB ? showReview.map((review, index) => {
                return <CourseReview review={{
                    id: index,
                    reviewer: review.name,
                    rating: review.rating,
                    review: review.review1 + " " + review.review2,
                    createdAt: review.date.split(" "),
                    image: null
                }} key={index} />
            }) : reviews.reverse().map((review, index) => {
                return <CourseReview review={review} key={index} />
            })}
        </div>
    )
}
