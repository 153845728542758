import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./Curriculum.css"
import CustomizedAccordions from './CustomizedAccordions';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


export default function Curriculum({ modules }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                // alignItems: 'center',
                justifyContent: 'space-evenly',
            }}>
                <Tabs style={{
                    width: '100%',
                    backgroundColor: '#fff',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                }} variant='scrollable' scrollButtons={"auto"} value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                        modules.map((module, index) => {
                            return <Tab
                                key={index}
                                style={{
                                    fontSize: '0.85rem',
                                    backgroundColor: value === index ? '#8710d8' : '#fff',
                                    color: value === index ? '#fff' : '#000',
                                    fontFamily: "Montserrat",
                                    fontWeight: 'bold',
                                    // padding: '0rem 1.5rem',
                                    marginLeft: '0.2rem',
                                }}
                                label={module.name}
                                wrapped={true}
                                value={index}
                            />
                        })
                    }
                </Tabs>
            </Box>
            {
                modules.map((module1, index) => {
                    return <TabPanel
                        key={index}
                        className="curriculum-section-container" value={value} index={index}>
                        {
                            module1.extra_data && <p>
                                <i className='fa fa-exclamation-triangle'></i> <span style={{
                                    fontSize: '0.9rem',
                                    fontFamily: "Montserrat",
                                    fontWeight: 'bold',
                                    marginLeft: '0.5rem',
                                }} className='text-black'>{module1.extra_data}</span>
                                <br />
                            </p>
                        }
                        <CustomizedAccordions sections={module1.sections} />
                    </TabPanel>
                })
            }
        </Box>

    )

}
