import React from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import './DiscussionBoardScreen.css'
import Context from '../../../Context'
import { Navigate } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../../../Components/Spinners/Spinner'
import axios from 'axios'
import config from '../../../utils/config'
import sendLogs from '../../../utils/telemetry'

export default function DiscussionBoardScreen() {
    const { state, setState } = React.useContext(Context)
    const [posts, setPosts] = React.useState([])
    // const [search, setSearch] = React.useState('')
    const [redirect, setRedirect] = React.useState(false)
    const [index, setIndex] = React.useState(1)
    const [isLoading, setIsLoading] = React.useState(false)
    const [numOfPosts, setNumOfPosts] = React.useState(0)
    const [limit, setLimit] = React.useState(5)
    const [category, setCategory] = React.useState('')
    const location = useLocation()

    function useQuery() {
        const { search } = location;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()

    const loadPosts = async () => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/posts/latest?index=${index}&category=${category}`);
            if (resp.data.response.status === 200) {
                setPosts(resp.data.response.data.posts)
                setNumOfPosts(resp.data.response.data.numOfPosts)
                setLimit(resp.data.response.data.limit)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                toast.error("Something went wrong", config.toastrOption)
            }
        } catch (err) {
            toast.error("Something went wrong", config.toastrOption)
            setIsLoading(false)
        }
    }

    const loadPostsOfTag = async (tag) => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/posts/tag/${tag}?index=${index}&category=${category}`);
            if (resp.data.response.status === 200) {
                setPosts(resp.data.response.data.posts)
                setNumOfPosts(resp.data.response.data.numOfPosts)
                setLimit(resp.data.response.data.limit)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                toast.error("Something went wrong", config.toastrOption)
            }
        } catch (err) {
            toast.error("Something went wrong", config.toastrOption)
            setIsLoading(false)
        }
    }


    React.useEffect(() => {
        setState({ ...state, currentScreen: 'discussion_board' })
        document.title = 'Discussion Board | Skills Stack'
        sendLogs("Discussion Page Visit", true, state.user || {}, "Discussion Session Page", null);

        return () => {
            setRedirect(false)
            return null
        }
    }, [])


    React.useEffect(() => {
        if (query.get('tag')) {
            loadPostsOfTag(query.get('tag'))
        } else {
            loadPosts()
        }
    }, [index, query, category, location])

    const handleAskQuestion = () => {
        setRedirect(true)
    }

    const getLastActivity = (lastActivity) => {
        const date = new Date(lastActivity)
        const now = new Date()
        const diff = now - date
        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
        const diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
        const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))

        if (diffInYears > 0) {
            return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`
        } else if (diffInMonths > 0) {
            return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`
        } else if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
        } else {
            return `${Math.floor(diff / (1000 * 60 * 60))} hour${Math.floor(diff / (1000 * 60 * 60)) > 1 ? 's' : ''} ago`
        }
    }

    return (
        <div className="main-container">
            <div className='container-fluid'>
                <ToastContainer />
                <Spinner show={isLoading} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className="card-header bg-white">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='text-black h1 latest-title'>Latest Topics

                                            <Link to='ask' className='btn btn-primary btn-sm float-right'>Ask a Question</Link>

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row mt-3">
                                    <div className="offset-md-2 col-md-4 col-12">
                                        <div className="form-group">
                                            <input type="search" className="form-control search-input" id="search" placeholder="Search" />
                                        </div>

                                    </div>
                                    <div className="col-md-4 offset-md-4 col-12">
                                        <div className="float-right" role="group" aria-label="Basic example" >
                                            <button type="button" className="btn btn-primary-outline">Left</button>
                                            <button type="button" className="btn btn-primary-outline">Middle</button>
                                            <button type="button" className="btn btn-primary-outline">Right</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='card-body' style={
                                { padding: "0.5rem" }
                            }>
                                <br />
                                <div className='posts-table table-responsive'>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Question</th>
                                                <th>Category</th>
                                                <th>Comments</th>
                                                <th>Views</th>
                                                <th>Likes</th>
                                                <th>Unlikes</th>
                                                <th>Last Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                posts.map((post, index) => {
                                                    return <tr className='table-row' key={index}>
                                                        <td>
                                                            <Link className='post-title-link' to={post.slug}>
                                                                <h5>{post.title}</h5>
                                                            </Link>
                                                            <span>
                                                                <small className='text-muted'>
                                                                    Asked by <Link to='#'>{post.user.name}</Link>
                                                                </small>
                                                            </span>
                                                            <div>
                                                                {
                                                                    post.tags.slice(0, 3).map((tag, index) => {
                                                                        return <Link onClick={() => {
                                                                            setIndex(1)
                                                                        }} to={`?tag=${tag}`} key={index} className='badge badge-tag'>{tag}</Link>
                                                                    })
                                                                }
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <button onClick={() => {
                                                                if (category !== post.category) {
                                                                    setCategory(post.category)
                                                                } else {
                                                                    setCategory("")
                                                                }
                                                            }} className='badge badge-category'>{post.category}</button>
                                                        </td>
                                                        <td>
                                                            <span className='badge badge-success'>{post.numOfComments}</span>
                                                        </td>
                                                        <td>
                                                            <span className='badge badge-primary'>{post.numOfViews}</span>
                                                        </td>
                                                        <td>
                                                            <span className='badge badge-danger'>{post.numOfLikes}</span>
                                                        </td>
                                                        <td>
                                                            <span className='badge badge-warning'>{post.numOfDislikes}</span>
                                                        </td>
                                                        <td>
                                                            <small className='text-muted'>
                                                                <i className='mdi mdi-clock-outline'></i>
                                                                {getLastActivity(post.update_date)}
                                                            </small>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='posts-cards'>
                                    {
                                        posts.map((post, index) => {
                                            return <div className='card' key={index}>
                                                <div className='card-body'>
                                                    <p className='card-text text-muted'>
                                                        <span className='text-badge badge badge-success'>
                                                            {post.numOfComments} Comments
                                                        </span>
                                                        <span className='text-badge badge badge-primary'>
                                                            {post.numOfViews} Views
                                                        </span>
                                                        <span className='text-badge  badge badge-danger'>
                                                            {post.numOfLikes} Likes
                                                        </span>
                                                        <span className='text-badge badge badge-warning'>
                                                            {post.numOfDislikes} Unlike
                                                        </span>
                                                    </p>
                                                    <h5 className='card-title post-title'>
                                                        <Link className='post-title-link' to={post.slug}>{post.title}</Link>
                                                    </h5>
                                                    <p className='card-text'>
                                                        <small className='text-muted'>
                                                            Asked by <Link to='#'>{post.user.name}</Link>
                                                        </small>
                                                        <span className='text-muted'>
                                                            <i className='material-icons'></i>
                                                            {getLastActivity(post.update_date)}
                                                        </span>
                                                    </p>
                                                    <div className='card-tags'>
                                                        {
                                                            post.tags.slice(0, 3).map((tag, index) => {
                                                                return <Link onClick={() => {
                                                                    setIndex(1)
                                                                }} to={`?tag=${tag}`} key={index} className='badge badge-tag'>{tag}</Link>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>

                                <nav className='pagination-container float-right' aria-label="...">
                                    <ul className="pagination">
                                        <li className={index === 1 ? "page-item disabled" : "page-item"}>
                                            <button onClick={() => {
                                                setIndex(index - 1)
                                            }} className="page-link">Previous</button>
                                        </li>
                                        {
                                            Array(Math.ceil(numOfPosts / limit)).fill(0).map((_, i) => {
                                                return <li key={i} className={i === index - 1 ? "page-item active" : "page-item"}>
                                                    <button onClick={() => {
                                                        setIndex(i + 1)
                                                    }} className="page-link">{i + 1}</button>
                                                </li>
                                            })
                                        }
                                        <li className={index === Math.ceil(numOfPosts / limit) ? "page-item disabled" : "page-item"}>
                                            <button onClick={() => {
                                                setIndex(index + 1)
                                            }} className="page-link" href="#">Next</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    redirect && <Navigate to="/discussion/ask" />
                }

            </div>
        </div>
    )
}
