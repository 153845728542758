import axios from 'axios';
import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../../../Components/Spinners/Spinner';
import config from '../../../utils/config';
import Context from '../../../Context';
import './QuestionScreen.css';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-highlight'
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor';
import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';

export default function QuestionScreen() {
    const { state } = React.useContext(Context);
    const params = useParams();
    const [question, setQuestion] = React.useState(null);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [comment, setComment] = React.useState('');
    const [isEditComment, setIsEditComment] = React.useState(false);
    const [commentToEdit, setCommentToEdit] = React.useState('');
    const [isEditPost, setIsEditPost] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);


    React.useEffect(() => {
        document.title =  "Discussion Board | Skills Stack"
    }, [])

    const fetchPost = async (id) => {
        setIsLoading(true);
        try {
            const headers = state.isLoggedIn ? {
                'Authorization': `Bearer ${state.token}`
            } : {};
            const response = await axios.get(`${config.baseUrl}/posts/${id}`, { headers });
            // console.log(response.data);
            // console.log(response.data.response.data.post.comments);
            setQuestion(response.data.response.data.post);
            setIsEditable(response.data.response.data.editable);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    React.useEffect(() => {
        fetchPost(params.id);
    }, [params.id, state.isLoggedIn]);

    const handleAddComment = async () => {
        setIsLoading(true);
        try {
            const headers = state.isLoggedIn ? {
                'Authorization': `Bearer ${state.token}`
            } : {};
            const response = await axios.post(`${config.baseUrl}/posts/${question._id}/comments`, {
                content: comment
            }, { headers });

            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                toast.success('Comment added successfully!', config.toastrOption);
                setComment('');
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    const likePost = async () => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.post(`${config.baseUrl}/posts/${question._id}/likes`, {}, { headers });
            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                // toast.success('Post liked successfully!', config.toastrOption);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    const disLikePost = async () => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.post(`${config.baseUrl}/posts/${question._id}/unlikes`, {}, { headers });
            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                // toast.success('Post disliked successfully!', config.toastrOption);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
        } catch (error) {
            console.log(error.response);
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    const likeComment = async (commentId) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.post(`${config.baseUrl}/posts/${commentId}/likes/comment`, {}, { headers });
            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                // toast.success('Comment liked successfully!', config.toastrOption);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    const disLikeComment = async (commentId) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.post(`${config.baseUrl}/posts/${commentId}/unlikes/comment`, {}, { headers });
            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                // toast.success('Comment disliked successfully!', config.toastrOption);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Something went wrong! Please try again later.', config.toastrOption);
        }
    }

    const handleEditComment = async (comment, index) => {
        const commentsExceptEditableComment = question.comments.filter((comment, i) => i !== index);
        const newQuestion = {
            ...question,
            comments: [...commentsExceptEditableComment]
        }

        setQuestion(newQuestion);
        setIsEditComment(true);
    }

    const handleDeleteComment = async (index) => {
        try {
            setIsLoading(true);
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.delete(`${config.baseUrl}/posts/${question._id}/comments/${question.comments[index]._id}`, { headers });
            if (response.data.response.status === 200) {
                await fetchPost(question.slug);
                toast.success('Comment deleted successfully!', config.toastrOption);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
            setIsLoading(false);
        } catch(err) {  
            console.log(err);
            toast.error("Couldn't delete comment", config.toastrOption);
            setIsLoading(false);
        }
    }

    const handleDeletePost = async () => {      
        try {
            setIsLoading(true);
            const headers = {
                'Authorization': `Bearer ${state.token}`
            }
            const response = await axios.delete(`${config.baseUrl}/posts/${question._id}`, { headers });
            if (response.data.response.status === 200) {
                toast.success('Post deleted successfully!', config.toastrOption);
                setRedirect(true);
            } else {
                toast.error('Something went wrong! Please try again.', config.toastrOption);
            }
            setIsLoading(false);
        } catch(err) {
            console.log(err);
            toast.error("Couldn't delete post", config.toastrOption);
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        if (question) {
            document.title = `${question.title} | Skills Stack`
        }
    }, [question])

    return (
        <div className="main-container">
            <div className='container'>
                <ToastContainer />
                {
                    redirect ? <Navigate to='/discussion' /> : null
                }
                <Spinner show={isLoading} />
                {
                    question && <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4 className='card-title question-title'>{question.title}</h4>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <p className='card-text'>
                                                <small className='text-muted'>
                                                    <span className='text-qsn-head'>Created by:</span>
                                                    <Link to={'#'} className='text-qsn-desc'>
                                                        {/* <img className='user-avatar' alt={question.user.name} src={`${config.baseUrl}/${question.user.avatar}`} /> */}
                                                        {question.user.name}
                                                    </Link>
                                                </small>
                                            </p>
                                        </div>
                                        <div className='col-md-4'>
                                            <p className='card-text'>
                                                <small className='text-muted'>
                                                    <span className='text-qsn-head'>Created on:</span>
                                                    <span className='text-qsn-desc'>{new Date(question.create_date).toLocaleString()}</span>
                                                </small>
                                            </p>
                                        </div>
                                        <div className='col-md-4'>
                                          
                                            {
                                                state?.isLoggedIn && <p className='icon-buttons-container'>
                                                    <button onClick={likePost} className={`icon-button text-badge badge ${question.isLiked ? 'badge-primary' : ''}`}>
                                                        <i className='icon fa fa-thumbs-up'></i> {question.numOfLikes}
                                                    </button>
                                                    <button onClick={disLikePost} className={`icon-button text-badge badge ${question.isDisliked ? 'badge-danger' : ''}`}>
                                                        <i className='icon fa fa-thumbs-down'></i> {question.numOfDislikes}
                                                    </button>
                                                    <button className='icon-button text-badge badge badge-success'>
                                                        <i className='icon fa fa-eye'></i> {question.numOfViews}
                                                    </button>
                                                    {
                                                        isEditable && <>
                                                            {/* <button onClick={() => {
                                                                setIsEditPost(true);
                                                            }} className='icon-button text-badge badge badge-info'>
                                                                <i  className='icon fa fa-edit'></i> {question.numOfComments}
                                                            </button> */}
                                                            <button data-toggle="modal" data-target="#deleteConfirmationModal" className='icon-button text-badge badge background-primary text-white'>
                                                                <i className='icon fa fa-trash'></i> {question.numOfComments}
                                                            </button>
                                                        </>

                                                    }
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body preview-container'>
                                    <div className='preview-body'>
                                        <ReactMarkdown id="ele" className='markdown-question-container' skipHtml={true} children={question.content} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} />
                                    </div>
                                </div>
                                <hr className='background-primary' />
                                <div className='card-body'>
                                    <div className='row comments-container'>
                                        <div className='col-md-12'>

                                            {
                                                question.comments.map((comment, index) => {
                                                    return (
                                                        <div className='card' key={index}>
                                                            <div className='card-body'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <p className='card-text'>
                                                                            <small className='text-muted'>
                                                                                <span className='fa fa-user'> </span> { " "} &nbsp;
                                                                                <Link to={'#'} className='text-qsn-desc'>
                                                                                    {/* <img className='user-avatar' alt={question.user.name} src={`${config.baseUrl}/${question.user.avatar}`} /> */}
                                                                                    {comment.user.name}
                                                                                </Link>
                                                                            </small>
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <p className='card-text'>
                                                                            <small className='text-muted'>
                                                                                <span className='text-qsn-desc'>{new Date(comment.create_date).toLocaleString()}</span>
                                                                            </small>
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <p className='icon-buttons-container'>
                                                                            <button onClick={() => { likeComment(comment._id) }} className={`icon-button text-badge badge ${comment.isLiked ? 'badge-primary' : ''}`}>
                                                                                <i className='icon fa fa-thumbs-up'></i> {comment.likes.length}
                                                                            </button>
                                                                            <button onClick={() => { disLikeComment(comment._id) }} className={`icon-button text-badge badge ${comment.isDisliked ? 'badge-danger' : ''}`}>
                                                                                <i className='icon fa fa-thumbs-down'></i> {comment.dislikes.length}
                                                                            </button>
                                                                            {
                                                                                comment.isEditable && <>
                                                                                    {/* <button onClick={() => {
                                                                                        handleEditComment(comment, index)
                                                                                    }} className='icon-button text-badge badge badge-info'>
                                                                                        <i className='icon fa fa-edit'></i> {question.numOfComments}
                                                                                    </button> */}
                                                                                    <button onClick={() => {
                                                                                        handleDeleteComment(index)
                                                                                    }} className='icon-button text-badge badge background-primary text-white'>
                                                                                        <i className='icon fa fa-trash'></i> {question.numOfComments}
                                                                                    </button>
                                                                                </>

                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='preview-body'>
                                                                    <ReactMarkdown id="ele" className='markdown-comment-container' skipHtml={true} children={comment.content} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div className='card-header'>
                                                <h4 className='card-title'>Comments</h4>
                                            </div> */}
                                            <div className='card new-comment-card'>
                                                <div className='card-body new-comment-card-body'>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <MarkdownEditor setContent={setComment} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-footer'>
                                                    <button onClick={handleAddComment} className='btn btn-primary btn-block'>Add Comment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >

            <div className="modal" id="deleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="deleteConfirmationModal" aria-hidden="true">
                <ConfirmationModal onCancelCloseModal={true} onCancel={null} title={"Are you sure ?"} onSuccess={handleDeletePost} message="Are you sure, you want to delete this post ?" />
            </div>


        </div>
    )
}
