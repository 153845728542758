import axios from 'axios'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import InlineSpinner from '../../Components/Spinners/InlineSpinner'
import config from '../../utils/config'

export default function ContactModal({ title, description, course }) {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [mobile, setMobile] = React.useState('')
    const [gradyear, setGradyear] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')

    const handleSubmit = async () => {
        try {
            if (!name.match(/^[a-zA-Z ]+$/)) {
                toast.error("Please enter a valid name", config.toastrOption);
                setErrorMessage("Please enter a valid name");
                return;
            }
            if (
                !email.match(
                    /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
            ) {
                toast.error("Please enter a valid email", config.toastrOption);
                setErrorMessage("Please enter a valid email");
                return;
            }
            if (!mobile || !mobile.match(/^[6-9]{1}[0-9]{9}$/)) {
                toast.error("Please enter a valid phone number", config.toastrOption);
                setErrorMessage("Please enter a valid phone number");
                return;
            }
            if (!gradyear || gradyear > 2025 || gradyear < 2010) {
                toast.error("Please enter a valid graduation year", config.toastrOption);
                setErrorMessage("Please enter a valid graduation year");
                return;
            }

            setIsLoading(true)
            const response = await axios.post(`${config.baseUrl}/landing/message`, {
                name,
                email,
                message: "I am interested in " + course.name + " need assisstance, year: " + gradyear,
                phone: mobile,
                terms: true
            })
            if (response.data.response.status === 200) {
                toast.success("Request Sent Successfully", {
                    ...config.toastrOption,
                    autoClose: 10000
                });
                setName('')
                setEmail('')
                setMobile('')
                setGradyear('')
            } else {
                toast.error(response.data.response.text, config.toastrOption);
            }
            setIsLoading(false)
        } catch (error) {
            toast.error("Something went wrong", config.toastrOption);
            setIsLoading(false)
        }
    }

    return (
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {title}
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className='p-small'>{description}</p>
                    <div className='form'>
                        <div className="form-group">
                            <input
                                type="text"
                                className={"form-control-input" + (name ? ' notEmpty' : '')}
                                id="name"
                                name="name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label className="label-control" htmlFor="name">Name</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className={"form-control-input" + (email ? ' notEmpty' : '')}
                                id="email"
                                name="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label className="label-control" htmlFor="email">Email</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input
                                type="number"
                                className={"form-control-input" + (mobile ? ' notEmpty' : '')}
                                id="mobile"
                                name="mobile"
                                required
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                            />
                            <label className="label-control" htmlFor="mobile">Phone Number</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input
                                type="number"
                                className={"form-control-input" + (gradyear ? ' notEmpty' : '')}
                                id="gradyear"
                                name="gradyear"
                                required
                                min={2015}
                                max={2030}
                                value={gradyear}
                                onChange={(e) => setGradyear(e.target.value)}
                            />
                            <label className="label-control" htmlFor="gradyear">Graduation Year</label>
                            <div className="help-block with-errors"></div>
                        </div>

                    </div>
                    <div className="form-group">
                        <button onClick={handleSubmit} disabled={isLoading} className='btn btn-primary btn-block'>Request a callback !
                            {isLoading && <InlineSpinner />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
