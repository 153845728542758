import React from 'react'
import propTypes from 'prop-types'

export default function AddLinkModal(props) {
    const [url, setUrl] = React.useState('')
    const [title, setTitle] = React.useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        props.setLink({ url, title })
        document.getElementById('modal-close-button').click()
    }

    return (
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add a hyperlink</h5>
                    <button id='modal-close-button' type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="link-url">URL</label>
                            <input value={url} type="url" required={true} onChange={(e) => setUrl(e.target.value)} className="form-control" id="link-url" placeholder="Enter URL" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="link-title">Title
                            &nbsp; <small>(Add a text for your hyperlink)</small></label>
                            <input value={title} type="text" required={true} onChange={(e) => setTitle(e.target.value)} className="form-control" id="link-title" placeholder="Enter title" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Add Hyperlink</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

AddLinkModal.propTypes = {
    setLink: propTypes.func.isRequired
}