import React from "react";
import './Footer.css';
import { Link } from 'react-router-dom'
export default function Footer() {

	return (
		<div id="footer">
			<div className="footer" >
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-3">
							<div className="footer-col first">
								<h5>About Skills Stack</h5>
								<p className="p-small">
									We're passionate about mentoring people and helping them grow
									their careers.
								</p>
								<ul className="list-unstyled li-space-lg p-small">
									<li className="media">
										<i className="fas fa-square"></i>
										<div className="media-body">
											<Link target={"_blank"} rel="noreferrer" to='/campus-ambassador-program'>
												Campus Ambassador Program
											</Link>
										</div>
									</li>
								</ul>
							</div>
						</div>

						<div className="col-md-3">
							<div className="footer-col second">
								<h5>Our Policies</h5>
								<ul className="list-unstyled li-space-lg p-small">
									<li className="media">
										<i className="fas fa-square"></i>
										<div className="media-body">
											<Link target={"_blank"} rel="noreferrer" to='/privacy-policy'>Privacy Policy</Link>
										</div>
									</li>
									<li className="media">
										<i className="fas fa-square"></i>
										<div className="media-body">
											<Link target={"_blank"} rel="noreferrer" to='/refund-cancellation-policy'>Refund & Cancellation Policy</Link>
										</div>
									</li>
								</ul>
							</div>
						</div>

						<div className="col-md-3">
							<div className="footer-col second">
								<h5>Terms of Use</h5>
								<ul className="list-unstyled li-space-lg p-small">
									<li className="media">
										<i className="fas fa-square"></i>
										<div className="media-body">
											<Link target={"_blank"} rel="noreferrer" to='/terms-conditions'>Read our Terms and Conditions</Link>
										</div>
									</li>
									<li className="media">
										<i className="fas fa-square"></i>
										<div className="media-body">
											<Link target={"_blank"} rel="noreferrer" to='/terms-conditions'>Full Stack Cashback Program</Link>
										</div>
									</li>
								</ul>
							</div>
						</div>


						<div className="col-md-3">
							<div className="footer-col fourth">
								<h5>Social Media</h5>
								<p className="p-small">For news & updates follow us</p>
								{/* <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a> */}
								<a href="https://twitter.com/SkillsStack" target={"_blank"}>
									<i className="fab fa-twitter"></i>
								</a>
								<a href="https://www.instagram.com/skills_stack/" target={"_blank"}>
									<i className="fab fa-instagram" ></i>
								</a>
								{/* <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="copyright">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<p className="p-small">
								Copyright © 2022
								<a href="https://skillsstack.in"> Skills Stack</a> - All rights
								reserved


							</p>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
