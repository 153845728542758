import axios from 'axios'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Context from '../../../Context'
import EnrollCourseModal from '../../../Modals/EnrollCourseModal/EnrollCourseModal'
import EnrollStaticCourseModal from '../../../Modals/EnrollStaticCourseModal/EnrollStaticCourseModal'
import LoginModal from '../../../Modals/LoginModal/LoginModal'
import MobileVerificationModal from '../../../Modals/MobileVerificationModal/MobileVerificationModal'
import config from '../../../utils/config'
import getCountryFromIP from '../../../utils/locations'
import sendLogs from '../../../utils/telemetry'
import CourseReviews from '../../CourseReviews/CourseReviews'
import Spinner from '../../Spinners/Spinner'
import Programs from '../Programs'
import './styles.css'

export default function MasterClass(props) {

    const [isLoading, setIsLoading] = React.useState(false)
    const { state, setState } = React.useContext(Context);
    const [enrolled, setEnrolled] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [country, setCountry] = React.useState('');

    const handleEnroll = () => {
        // setState({ ...state, selectedCourse: null, selectedVariant: null });
    }

    const loadData = async () => {
        const countryD = await getCountryFromIP();
        setCountry(countryD);
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);

        if (state.token) {
            axios.get(`${config.baseUrl}/courses/enroll/frontend-master-class`, {
                headers: {
                    'Authorization': `Bearer ${state.token}`
                }
            })
                .then(res => {
                    if (res.data.response.data) {
                        setEnrolled(true);
                    } else {
                        setEnrolled(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [state.token])

    React.useEffect(() => {
        if (searchParams.get('modal') === 'true') {
            document.getElementById("enrollmentModalBtn").click();
            setSearchParams(searchParams.delete('modal'));
        }
        sendLogs("Masterclass Page Visit", true, state.user || {}, "MasterClass Session Page", null);
        loadData();
        document.title = "Frontend Master Class | Skills Stack";
        return () => {
            sendLogs("Left Masterclass Page", false, state.user || {}, {}, null);
            return null
        }
    }, [])

    const highlights = [
        {
            name: "Format",
            value: "Live and Online"
        },
        {
            name: "Duration",
            value: "2 Hours"
        },
        {
            name: "Date",
            value: "14 August 2022"
        },
        {
            name: "Time",
            value: "7 PM - 9 PM"
        }
    ]

    const courseDetails = [
        "Free masterclass from the industry expert",
        "100% online learning, no physical classes",
        "Learn basics of the Frontend Development using HTML, CSS and JavaScript",
        "e-certificate of Completion",
        "Learn from industry experts working in top IT Companies"
    ]

    const prerequisites = [
        "Preparing for any upcoming interview",
        "Preparing for any upcoming recruitment drives",
        "Preparing for HackWithInfy, TCS Digital and other competitions",
        "Preparing for FAANG Companies"
    ]

    const jobOpportunities = [
        "Software Developer",
        "Full Stack Developer",
        "Software Engineer",
        "Front end Developer",
        "Many more..."
    ]

    const overview = [
        "This course is designed to help you prepare for any upcoming interview, recruitment drive, HackWithInfy, TCS Digital and other competitions.", "It includes 4 mock interviews, 2 free session on resume building and optimization, special mock interviews for HackWithInfy, TCS Digital and other competitions.", "Special interview series for FAANG Companies.", "It is a great way to get placed with top IT companies with the help of our free Interview Preparation Course, which includes 4 free mock interview sessions from top IT experts and one free session on how to make your resume stand out from the crowd.",
    ]

    return (
        <div className='container-fluid' style={{
            margin: 0,
            padding: 0,
        }}>
            <ToastContainer />
            <Spinner show={isLoading} />
            <>
                <div className="row course-feature-container-bg-white">
                    {/* <div className="col-lg-4 col-md-4">
                        <img className='card-img-top interview-course-image' src={require("../../../assets/images/front-end-masterclass-card.png")} alt={`Course :Interview Preparation Program`} />
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12">

                        <h1 className='interview-course-name h1'>Frontend Development Masterclass</h1>
                        <p className='interview-course-description p'>Get a chance to learn basic of Frontend Development and get started on your professional journey of becoming Full Stack Developer. A 3 hour masterclass from the best of the industry.</p>

                        <div className="ratings p-small text-white">
                            <i className="material-icons rating-color">star</i>
                            <i className="material-icons rating-color">star</i>
                            <i className="material-icons rating-color">star</i>
                            <i className="material-icons rating-color">star</i>
                            <i className="material-icons rating-color">star</i>
                            {/* <span className="review-count text-black">(15 Reviews)</span> */}
                            <span className="review-count text-black">55 students</span>
                        </div>
                    </div>
                </div>
            </>

            <>
                <div className="row highlights-container">
                    {
                        highlights.map((highlight, index) => {
                            return <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                                <p className='heading'>{highlight.value}</p>
                                <p className='muted-text'>{highlight.name}</p>
                            </div>

                        })
                    }
                </div>

                <div className="highlights-container-mobile">
                    {
                        highlights.map(highlight => {
                            return <div key={highlight.name} className="row">
                                <div className="col-6">
                                    <p className='muted-text'>{highlight.name}</p>
                                </div>
                                <div className="col-6">
                                    <p className='heading'>{highlight.value}</p>
                                </div>
                            </div>
                        })
                    }
                </div>

                <div className="row course-feature-container-bg-color">
                    <div className="col-12 mt-3">
                        <div className="h3 bold-600">Key features</div>
                    </div>
                    <div className="col-lg-8 mt-4">
                        <div className="row">
                            {
                                courseDetails.map((detail, index) => {
                                    return <div key={index} className="col-md-6 mt-3">
                                        <p><li>{detail}</li></p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-3 offset-md-1 aim-container">
                        <div className="aim-text">
                            <p className='p-small'>“Our aim is simple: We strive to create high-impact, hands-on experiences that prepare students for meaningful and productive careers.”</p>

                            <p className='p-small bold-600'>- The Skills Stack Team</p>
                            <img className='img-aim' src={require("../../../assets/images/logo-black-transparent.png")} alt="Skills Stack" />
                        </div>
                    </div>
                </div>

                <div className="row course-feature-container-bg-white">
                    <div className="col-md-6 mt-4">
                        <p className='h4 bold-600'>Who Is This Program For?</p>
                        <ul>
                            {
                                prerequisites.map(prereq => {
                                    return <li key={prereq} className='pl-4 mt-3'>{prereq}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="col-md-6 pl-4 mt-4">
                        <p className='h4 bold-600'>Job Opportunity</p>
                        <ul>
                            {
                                jobOpportunities.map(prereq => {
                                    return <li key={prereq} className='pl-4 mt-3'>{prereq}</li>
                                })
                            }
                        </ul>
                    </div>


                </div>

                <div className="course-feature-container-bg-color">
                    <div className="col-12 mt-3">
                        <div className="h3 bold-600">What our students say ?</div>
                    </div>

                    <CourseReviews />
                </div>

                <div className="course-feature-container-bg-white">
                    <div className="row course-description-container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-6 mt-4">
                                    {/* {
                                        overview.map((detail, index) => {
                                            return (
                                                <li key={index} className='p-small'>{detail}</li>
                                            )
                                        })
                                    } */}
                                    <img className='img-fluid' src={country == "India" ? require("../../../assets/images/front-end-masterclass.png") : require("../../../assets/images/front-end-masterclass.png")} />
                                </div>
                                <div className="col-md-6 mt-auto mb-auto">
                                    <div className="price-container">
                                        <span className='course-price text-center'>
                                            {
                                                `Free`
                                            }
                                            <br />
                                        </span>
                                        <button data-toggle="modal" disabled id='enrollmentModalBtn' onClick={handleEnroll} data-target="#enrollCourseModal"
                                            className='btn btn-primary enroll-btn'>
                                                Session Has Ended
                                                {/* {enrolled ? `You are already enrolled` : `Click to Enroll !`} */}
                                        </button>
                                    </div>
                                </div>

                                <div className="modal" id="enrollCourseModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    {/* {
                                        state.isLoggedIn ?
                                            <EnrollStaticCourseModal needMobile={true} course={"Frontend Development Masterclass"} needResume={false} setEnrolled={setEnrolled} enrolled={enrolled} /> : <LoginModal />
                                    } */}

                                    {
                                        state.isLoggedIn ?
                                            state.user?.is_mobile_verified ? <EnrollStaticCourseModal course={"Frontend Development Masterclass"} needResume={false} setEnrolled={setEnrolled} enrolled={enrolled} /> : <MobileVerificationModal />
                                            : <LoginModal />
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="course-feature-container-bg-color">
                    <Programs title={"our upcoming Events"} dontShowStatic={"Frontend Development Masterclass"} />
                </div>

            </>

        </div>
    )
}
