import React from 'react'
import './Spinner.css'
import propTypes from 'prop-types'

function Spinner(props) {
    return (props.show && <div className='spinner-container'>
        {/* <img className='img-spinner' src={require("../../assets/images/logo-black-transparent.png")} /> */}
        <span className="main-spinner spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </span>
    </div>
    )
}

Spinner.propTypes = {
    show: propTypes.bool.isRequired
}

export default Spinner

