import axios from 'axios';
import React from 'react'
import { Link } from 'react-router-dom';
import config, { toastrOption } from '../../utils/config';
import Spinner from '../Spinners/Spinner';
import { toast } from 'react-toastify';

export default function Registration() {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');

    const setMessage = (isError, message) => {
        setIsError(isError);
        setErrorMessage(message);
        setIsSuccess(!isError);
        setSuccessMessage(message);
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault()
        setIsLoading(false);
        if (!name) {
            setMessage(true, 'Name is required');
            return;
        }
        if (!email) {
            setMessage(true, 'Email is required');
            return;
        }
        if (!phone) {
            setMessage(true, 'Phone is required');
            return;
        }
        if (!checked) {
            setMessage(true, 'Please agree to the terms and conditions');
            return;
        }

        const body = { name, email, phone, terms: checked };

        axios.post(`${config.baseUrl}/landing/register`, body).then(res => {

            if (res.status === 200) {
                setMessage(false, 'Request Submitted Successfully');
            } else {
                setMessage(true, res.data.message);
            }
        }).catch(err => {
            console.log(err)
            setMessage(true, err.response.data.message || 'Something went wrong');
        })


        setMessage(false, 'Thank you for your message, we will get back to you soon.');
        toast.success("Message Sent!", toastrOption)
    }

    return (
        <div id="register" className="form-1">
            <br />
            <br />
        
            <Spinner show={isLoading} />
            <div className="container-fluid registration-container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-2">
                        <div className="text-container">
                            <h2 className='p-black'>Join Us !</h2>
                            <p style={{
                                color: '#000',
                            }}>
                                We at Skills Stack aim to provide a Placement Assistance Program
                                to upskill the students to get better opportunities in the
                                industry right at the Fresher's level. It's easy to register for
                                the course, just fill out the form and our experts will contact you.
                            </p>
                            <ul style={{
                                color: '#000',
                            }} className="list-unstyled li-space-lg">

                                <li className="media">
                                    <i style={{
                                        color: '#000',
                                    }} className="fas fa-square"></i>
                                    <div className="media-body">
                                        <strong>Your information is safe with us</strong> and will not be used for
                                        marketing
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-container">
                            <form
                                id="registrationForm"
                                data-toggle="validator"
                                data-focus="false"
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={"form-control-input" + (name ? ' notEmpty' : '')}
                                        id="rname"
                                        name="rname"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <label className="label-control" htmlFor="rname">Complete name</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className={"form-control-input" + (email ? ' notEmpty' : '')}
                                        id="remail"
                                        name="remail"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label className="label-control" htmlFor="remail"
                                    >Email address</label
                                    >
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={"form-control-input" + (email ? ' notEmpty' : '')}
                                        id="rphone"
                                        name="rphone"
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <label className="label-control" htmlFor="rphone">Phone number</label>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div style={{
                                    color: '#000',
                                    fontWeight: 'bold',
                                }} className="form-group checkbox">
                                    <input
                                        type="checkbox"
                                        id="rterms"
                                        value="Agreed-to-Terms"
                                        name="rterms"
                                        required
                                        checked={checked}
                                        onChange={() => setChecked(!checked)}
                                    />I've read and agree to Skill Stack's written {" "}
                                    <Link style={{
                                        color: '#000',
                                        textDecoration: 'underline',
                                    }} to="/privacy-policy">Privacy Policy</Link> and {" "}
                                    <Link style={{
                                        color: '#000',
                                        textDecoration: 'underline',
                                    }} to="/terms-conditions">Terms & Conditions.</Link>
                                    <div className="help-block with-errors"></div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="form-control-submit-button">
                                        Request a Callback
                                    </button>
                                </div>
                                <div className="form-message">
                                    {
                                        isError && <div id="rmsgSubmit" className="h3 text-center hidden text-capitalize">{errorMessage}</div>
                                    }
                                    {
                                        isSuccess && <div id="rmsgSubmit" className="h3 text-center hidden text-capitalize">{successMessage}</div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
