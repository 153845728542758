import React from "react";
import { BrowserRouter } from "react-router-dom";
import { } from 'react-router'
// import Footer from "./Components/Footer/Footer";
// import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Navigation from "./Components/Navigation/Navigation";
import Context from "./Context";
import initialState from "./Context/initialState";
import config from "./utils/config";
import axios from "axios";

export default function App() {
	const [state, setState] = React.useState(initialState);
	
	return (
		<BrowserRouter>
			<Context.Provider value={{ state, setState }}>
				<Navigation />
				<Main />
			</Context.Provider>
		</BrowserRouter>
	);
}
