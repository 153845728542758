import React from 'react'
import Context from '../../Context';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Spinner from '../../Components/Spinners/Spinner';
import config from '../../utils/config';
import axios from 'axios';
import './SessionScreen.css';
import LoginModal from '../../Modals/LoginModal/LoginModal';
import SessionCard from './SessionCard/SessionCard';
import sendLogs from '../../utils/telemetry';
import { Alert } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

export default function SessionScreen() {
    const [sessions, setSessions] = React.useState([]);
    const { state, setState } = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(false);

    const loadSessions = async () => {
        try {
            setIsLoading(true)
            const resp = await axios.get(`${config.baseUrl}/sessions`, {
                headers: {
                    "Authorization": `Bearer ${state.token}`
                }
            });
            setSessions(resp.data.response.data);
            setIsLoading(false)
        } catch (error) {
            console.log(error.response.data.message);
            setIsLoading(false);
            toast.error(error.response.data.message || "Error getting Sessions", config.toastrOption);
        }
    }

    React.useEffect(() => {
        if (state.isLoggedIn) {
            loadSessions();
        }
        sendLogs("Session Page Visit", true, state.user || {}, "Visited Session Page", null);
    }, [state.isLoggedIn])

    React.useEffect(() => {
        setState({ ...state, currentScreen: "sessions" });
        document.title = "Skills Stack Sessions";
        return () => {
            return null;
        }
    }, [])

    return (
        <div className="main-container">
            {
                state.isLoggedIn ? <div className='container-fluid'>
                    <Spinner show={isLoading} />
                    <ToastContainer />

                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='h3'>Your Sessions</h1>
                        </div>
                    </div>

                    <Alert style={{
                        backgroundColor: "#8710d8",
                        color: "white",
                        fontFamily: "Montserrat",
                    }} severity="info" icon={<InfoOutlined style={{
                        color: "white",
                    }} />}>
                        Want to become our Campus Ambassador ? <Link to="/campus-ambassador-program" style={{
                            color: "white",
                            textDecoration: "underline",
                        }}>Click Here</Link>
                    </Alert>

                    <div className="row mt-3">
                        {
                            sessions.length > 0 ? sessions.map((allSessions, index) => {
                                return <div className="col-12 mb-4">
                                    <div className="card">
                                        <div className="card-header background-primary ">
                                            <h5 className="card-title text-white">{allSessions.group.name}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {
                                                    allSessions.sessions.map((session, index1) => {
                                                        return <SessionCard key={index + "" + index1} course={allSessions.group.course.name} session={session} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }) : <>
                                <div className="col-12 empty-container">
                                    <p className="no-session-message">No sessions scheduled</p>
                                </div>
                                <div className="col-12 empty-container">
                                    <Link to={'/sessions/demo'} className="demo-session-message">Check our Demo Sessions</Link>
                                </div>
                            </>
                        }
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12 mt-4">
                            <p className='p-large'>
                                Have a question ? Check our discussion forum, ask question and get help from the Skills Stack Community.
                            </p>
                            <Link to={"/discussion"} className='btn btn-primary'>Go to Discussion Forum {" "}  {" "}<i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div> : <LoginModal />
            }

        </div>
    )
}
