import React from 'react'
import Context from '../../Context'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import config from '../../utils/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import InlineSpinner from '../../Components/Spinners/InlineSpinner';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import sendLogs from '../../utils/telemetry';

export default function MobileVerificationModal({ onSuccess }) {
    const { state, setState } = React.useContext(Context)
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [confirmRes, setConfirmRes] = React.useState(null);
    const [verificationCode, setVerificationCode] = React.useState('');
    const [isVerified, setIsVerified] = React.useState(false);

    React.useEffect(() => {
        setPhoneNumber(state.user.mobile)
        setConfirmRes(null);
        setIsVerified(false);
        setVerificationCode('');
        return () => {
            return null
        }
    }, [])



    const sendCode = async () => {
        const phone = phoneNumber;
        // if (!phone.match(/^\+91\d{10}$/)) {
        //     toast.error("Please enter a valid phone number", config.toastrOption);
        //     return;
        // }
        setVerificationCode('');
        setConfirmRes(null);
        const auth = getAuth()
        let appVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'normal'
        }, auth);
        setIsLoading(true);


        signInWithPhoneNumber(auth, phone, appVerifier)
            .then((confirmationResult) => {
                setConfirmRes(confirmationResult)
                appVerifier.clear();
                setIsLoading(false);
                toast.success("Code sent successfully", config.toastrOption);
                sendLogs("Mobile Verification", true, {
                    "phone": phone,
                    user: state.user
                }, "Mobile Verification Code Sent", null);
            }).catch((error) => {
              
                if (error.code === 'auth/invalid-phone-number') {
                    toast.error("Please enter a valid phone number, and try again !", config.toastrOption);
                } else if (error.code === 'auth/user-disabled') {
                    toast.error("User is disabled", config.toastrOption);
                } else if (error.code === 'auth/user-not-found') {
                    toast.error("User not found", config.toastrOption);
                } else if (error.code === 'auth/missing-phone-number') {
                    toast.error("Phone number is missing, please enter your phone number", config.toastrOption);
                } else if (error.code === 'auth/too-many-requests') {
                    toast.error("Too many requests recieved from this account, please try again later.", config.toastrOption);
                } else if (error.code === 'auth/captcha-check-failed') {
                    toast.error("Captcha check failed, please refresh the page and try again !", config.toastrOption);
                } else if (error.code === 'auth/invalid-verification-code') {
                    toast.error("Invalid verification code", config.toastrOption);
                } else if (error.code === 'auth/missing-verification-code') {
                    toast.error("Missing verification code", config.toastrOption);
                } else if (error.code === 'auth/invalid-verification-id') {
                    toast.error("Invalid verification id", config.toastrOption);
                } else if (error.code === 'auth/missing-verification-id') {
                    toast.error("Missing verification id", config.toastrOption);
                } else
                    toast.error("Something went wrong", config.toastrOption);

                sendLogs("Mobile Verification", false, {
                    "phone": phone,
                    user: state.user
                }, "Mobile Verification Code Send Failed", error);

                setIsLoading(false);
                appVerifier.clear();
            });
    }

    const verifyCode = async () => {
        try {
            if (!verificationCode) {
                toast.error("Please enter the verification code sent to your mobile", config.toastrOption);
                return;
            }
            setIsLoading(true);
            const res = await confirmRes.confirm(verificationCode);
            if (res.user) {
                setIsLoading(false);
                toast.success("Verification successful", config.toastrOption);

                sendLogs("Mobile Code Verification ", true, {
                    "phone": phoneNumber,
                    user: state.user
                }, "Mobile Verification Code Verification Success", null);

                const resp = await axios.put(`${config.baseUrl}/users/updateUser`, {
                    mobile: phoneNumber,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + state.token
                    }
                })

                if (resp.status === 200) {
                    toast.success("Mobile number updated successfully", config.toastrOption);
                    setState({ ...state, user: resp.data.response.data })
                    setPhoneNumber('');
                    setVerificationCode('');
                    setConfirmRes(null);
                    setIsVerified(true);
                    if (onSuccess) onSuccess();
                } else {
                    sendLogs("Mobile Code Verification Backend Failed", false, {
                        "phone": phoneNumber,
                        user: state.user
                    }, "Mobile Verification Code Verification Failed", resp.data);
                    toast.error("Something went wrong", config.toastrOption);
                }
            } else {
                sendLogs("Mobile Code Verification Firebase Failed", false, {
                    "phone": phoneNumber,
                    user: state.user
                }, "Mobile Verification Code Verification Failed", res);
                toast.error("Verification failed", config.toastrOption);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err)
            setConfirmRes(null);
            if (err.code === 'auth/invalid-verification-code') {
                toast.error("Invalid verification code, please try again !", config.toastrOption);
            } else {
                toast.error("Verification failed", config.toastrOption);
            }
            setIsLoading(false);
            sendLogs("Mobile Code Verification ", false, {
                "phone": phoneNumber,
                user: state.user
            }, "Mobile Verification Code Verification Failed", err);
        }
    }

    return (
        <div className="modal-dialog modal-dialog-centered" role="document">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Verify your Mobile number to continue</h5>
                    <button type="button" id='sign-in-button' className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        isVerified ? <>
                            <div className="p-small">Your mobile number has been verified successfully, you can close this dialog box.</div>
                        </> : <>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Enter your mobile number</label>
                                {/* <input value={phoneNumber} onChange={(e) => {
                                    setPhoneNumber(e.target.value)
                                }} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="8888888888" /> */}
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    type="text"
                                    className="form-control"
                                    defaultCountry='IN'
                                    id="exampleInputEmail1"
                                    numberInputProps={{
                                        style: {
                                            border: 0,
                                            outline: 0
                                        },

                                    }}
                                    onChange={(value) => {
                                        setPhoneNumber(value)
                                    }}
                                />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your mobile number with anyone else.</small>
                            </div>
                            {
                                confirmRes !== null ? <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Enter the code</label>
                                    <input value={verificationCode} onChange={(e) => {
                                        setVerificationCode(e.target.value)
                                    }} type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter the code" />
                                </div> : null
                            }
                            <div id='recaptcha-container'></div>
                            <br />

                            {!confirmRes ? <button disabled={phoneNumber && phoneNumber.length > 10 ? isLoading ? true : false : true} onClick={sendCode} className='btn btn-primary' >Send Verification Code {
                                isLoading && <InlineSpinner />
                            }</button> : <button onClick={verifyCode} className='btn btn-primary' type="button">Verify Code {
                                isLoading && <InlineSpinner />
                            }</button>}

                        </>
                    }
                </div>
            </div>
        </div>
    )
}
