import React from 'react'
import { svgs } from '../../assets/images/svgs'
import "./CorporateTraining.css"

export default function CorporateTraining() {

    const programs = [
        {
            title: "Data Science",
            image: "data_science.webp",
        },
        {
            title: "Mobile App Development",
            image: "mobile_development.webp",
        },
        {
            title: "Web Development",
            image: "webdev.webp",
        },
        {
            title: "DevOps: Docker & Kubernetes",
            image: "devops.png",
        },
        {
            title: "Digital Marketing",
            image: "digital_marketing.webp",
        },
        {
            title: "Cloud Computing",
            image: "cloud_computing.webp",
        },
        {
            title: "Database Management",
            image: "database.webp",
        },
        {
            title: "MERN Stack",
            image: "mern_stack.jpg",
        },
        {
            title: "MEAN Stack",
            image: "mean_stack.jpeg",
        },
        // {np
        {
            title: "Project Management & Agile",
            image: "project_management.webp",
        },
        {
            title: "Big Data & Hadoop",
            image: "bigdata.webp",
        },
        {
            title: "Amazon Web Services",
            image: "aws.png",
        }
    ]

    return (
        <div id='corporate-training' >
            <div className='container-fluid header'>
                <div className="container header-container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>Transform Your Workforce</h1>
                            <p className='p-large'>Learn emerging skills quickly with custom curriculum designed as per your needs.</p>

                            <button className='btn btn-primary'>Learn More</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-container">
                <div className="container">
                    <div className="row highlights-container">
                        <div className="col-lg-3 col-md-3 col-sm-6 highlight-container">
                            <img src={svgs.learner} className="highlight-svg-image" />
                            <div className="highlight-text-container">
                                <p className='heading'>500+</p>
                                <p className='muted-text'>Satisfied Professionals</p>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 highlight-container">
                            <img src={require("../../assets/images/header-session-image.webp")} className="highlight-svg-image" />
                            <div className="highlight-text-container">
                                <p className='heading'>500+</p>
                                <p className='muted-text'>Sessions Completed</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 highlight-container">
                            <img src={svgs.expert} className="highlight-svg-image" />
                            <div className="highlight-text-container">
                                <p className='heading'>30+</p>
                                <p className='muted-text'>Industry Experts & Mentors</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 highlight-container">
                            <img src={require("../../assets/images/instructor-led.png")} className="highlight-svg-image" />
                            <div className="highlight-text-container">
                                <p className='heading'>Instructor Lead</p>
                                <p className='muted-text'>Trainings</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container desc-container">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                Why Companies prefer Skills Stack ?
                            </h2>
                        </div>

                        <div className="col-md-8">
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>High engagement and outcome-centric learning
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Customized curriculum built with industry leaders, for industry leaders
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Hands-on exercises and industry use cases
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Assignments and project work review and feedback from industry SMEs
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Strong reporting to track learning and calculate training ROI for managers
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Pre-configured Cloud Labs for practice
                            </h5>
                            <h5>
                                <i className="fas fa-check-circle mr-2"></i>Day 1 production ready on the completion of the training
                            </h5>

                        </div>
                        <div className="col-md-3 offset-md-1 aim-container  ">
                            <div className="aim-text">
                                <p className='p-small'>“Our aim is simple: We strive to create high-impact, hands-on experiences that prepare professionals for meaningful and productive careers.”</p>

                                <p className='p-small bold-700 text-black'>- The Skills Stack Team</p>
                                <img className='img-aim' src={require("../../assets/images/logo-black-transparent.png")} alt="Skills Stack" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="container-fluid bg-light-purple">

                <div className="container desc-container">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                Programs Offered
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        {
                            programs.map((program, index) => {
                                return (
                                    <div className="col-md-3 col-sm-6 mt-3" key={index}>
                                        <div className="card">
                                            <img src={require("../../assets/images/" + program.image)} className="card-img-top program-image" />
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                fontSize: '0.8rem',
                                                fontWeight: '600',
                                                padding: '0.7rem 1.5rem',
                                                color: '#8710d8',
                                                // backgroundColor: '#f0f0f0'
                                            }}>
                                                <span>{program.title}
                                                </span>
                                                {/* <i className="material-icons">
                                                    keyboard_arrow_right
                                                </i> */}
                                                <i className="fa fa-arrow-right">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>

    )
}
