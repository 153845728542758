import axios from 'axios'
import React from 'react'
import Context from '../../Context'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config, { localStorageKeys } from '../../utils/config';
import { Navigate } from 'react-router-dom';
import InlineSpinner from '../../Components/Spinners/InlineSpinner';
import sendLogs from '../../utils/telemetry';
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

export default function LoginModal({ hideCross }) {
    const { state, setState } = React.useContext(Context);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [isGooleSignInLoading, setIsGoogleSignInLoading] = React.useState(false);
    const [showpassword, setShowpassword] = React.useState(false);
    const [userNotFoundErrorCount, setUserNotFoundErrorCount] = React.useState(0);
    const [isResetPassword, setIsResetPassword] = React.useState(false);

    React.useEffect(() => {
        return () => {
            setIsLoading(false);
            return null;
        }
    }, [])

    const iOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    React.useEffect(() => {
        if (userNotFoundErrorCount === 2) {
            // document.getElementById("hiddenBtn").click();
        }
    }, [userNotFoundErrorCount])

    const clickSignUp = () => {
        document.getElementById("signUp").click();
    }

    const handleGoogleAuthLogin = async () => {
        const provider = new GoogleAuthProvider();

        const auth = getAuth();
        setIsGoogleSignInLoading(true);
        try {
            const result = await signInWithPopup(auth, provider)
            const credential = GoogleAuthProvider.credentialFromResult(result);
            // The signed-in user info.
            const user = result.user;
            const body = {
                name: user.displayName,
                email: user.email,
                firebase_uid: user.uid,
            }

            const resp = await axios.post(`${config.baseUrl}/users/loginWithGoogle`, body);
            if (resp.status === 200) {
                // console.log(resp.data.response.data);
                localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                    token: resp.data.response.data.token,
                    sessionStartTime: new Date().getTime()
                }));

                sendLogs("Login with google", true, resp.data.response.data, "User Logged In with google", null);


                toast.success("Login Successful", config.toastrOption);
                setState({
                    ...state,
                    isLoggedIn: true,
                    user: resp.data.response.data.user,
                    isGuest: false,
                    token: resp.data.response.data.token,
                });

                setTimeout(() => {
                    setIsGoogleSignInLoading(false);
                }, 3000);
            } else {
                throw new Error("Error in login with google");
            }

        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error?.customData?.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            setIsGoogleSignInLoading(false);
            if (errorCode === "auth/network-request-failed") {
                sendLogs("login by google failed, sending to Register Page", false, { email: email }, { errorMessage, errorCode, error, credential }, null);
                setIsGoogleSignInLoading(false);
                document.getElementById("signUp").click();
                return;
            }
            toast.error("Could not login with Google", config.toastrOption);
            sendLogs("login by google failed", false, { email: email }, { errorMessage, errorCode, error, credential }, null);
            setIsGoogleSignInLoading(false);
        }
        setIsGoogleSignInLoading(false);
    }



    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        try {
            const firebaseUser = await signInWithEmailAndPassword(
                getAuth(),
                email,
                password
            );
            // if (!firebaseUser.user.emailVerified) {
            //     throw new Error("Email has not been verified.");

            // } else {
            const resp = await axios.post(`${config.baseUrl}/users/login`, {
                email: email,
                password: password,
                firebaseUser
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                }
            })
            if (resp.status === 200) {
                toast.success("Login Successful", config.toastrOption);
                localStorage.setItem(localStorageKeys.userDetails, JSON.stringify({
                    token: resp.data.response.data.token,
                    sessionStartTime: new Date().getTime()
                }));

                sendLogs("Login", true, resp.data.response.data, "User Logged In", null);


                setState({
                    ...state,
                    isLoggedIn: true,
                    user: resp.data.response.data.user,
                    isGuest: false,
                    token: resp.data.response.data.token,
                    firebaseUser: firebaseUser.user,
                });
            }
            // }

            setIsLoading(false);

        } catch (error) {
            console.log(error.message)
            setIsLoading(false);
            let errorMessage = ""
            if (error.message === "Firebase: Error (auth/wrong-password).")
                errorMessage = "Wrong password"
            else if (error.message === "Firebase: Error (auth/user-not-found).") {
                setUserNotFoundErrorCount(userNotFoundErrorCount + 1);
                errorMessage = "This email is not registered with us, please create an account."
            } else if (error.message === "Firebase: Error (auth/invalid-email).")
                errorMessage = "Invalid email"
            else if (error.message === "Email has not been verified.")
                errorMessage = "Email has not been verified."
            else if (error.message === "Firebase: Error (auth/too-many-requests).")
                errorMessage = "Too many requests from this account in a short period of time, please try again later"
            else if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).")
                errorMessage = "Too many requests from this account in a short period of time, please try again later"
            else
                errorMessage = error.response?.data?.message || "Something went wrong"
            setIsLoading(false);
            sendLogs("Login", false, { errorMessage, email, password, error: error }, "User Login Failed", {});

            toast.error(errorMessage, config.toastOptions);
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        } else if (e.target.name === "password") {
            setPassword(e.target.value);
        }
    };

    return (
        <div className="modal-dialog modal-dialog-centered" role="document">
            <ToastContainer />
            {
                redirect && <Navigate to={`/register?redirect=${window.location.pathname}`} />
            }
            <div className="modal-content">
                {
                    userNotFoundErrorCount !== 2 ? <>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Login to Continue</h5>
                            {
                                hideCross ? <></> : <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            }

                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit} className="form-signin">
                                <div className="form-group">
                                    <label htmlFor="inputEmail">Email address</label>
                                    <input onChange={handleChange} value={email} type="email" id="inputEmail" className="form-control" placeholder="Email address" name='email' required autoFocus />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputPassword">Password</label>
                                    <input onChange={handleChange} value={password} type={
                                        showpassword ? "text" : "password"
                                    } id="inputPassword" className="form-control" name='password' placeholder="Password" required />
                                </div>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input" id="showpass" onChange={(e) => {
                                        setShowpassword(!showpassword);
                                    }} /> <label className="form-check-label" style={{
                                        fontWeight: "normal"
                                    }} htmlFor="showpass">Show Password </label>
                                </div>
                                {/* <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="rememberMe" />
                            <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                        </div> */}
                                <button disabled={isLoading} className="btn btn-primary btn-block" type="submit">
                                    Login with Email <i style={{
                                        color: "white",
                                        marginLeft: "5px"
                                    }} className="fas fa-envelope"></i>
                                    {
                                        isLoading && <InlineSpinner />
                                    }
                                </button>
                                {
                                    !iOS() && <button
                                        type="button"
                                        role={"button"}
                                        className="btn btn-primary btn-block"
                                        disabled={isLoading}
                                        style={{
                                            backgroundColor: "#db4437",
                                        }}
                                        onClick={handleGoogleAuthLogin}
                                    >
                                        Sign in / Sign up with google <i style={{
                                            color: "white",
                                            marginLeft: "5px"
                                        }} className="fab fa-google"></i> {isGooleSignInLoading ? <InlineSpinner /> : null}
                                    </button>
                                }

                                <button
                                    type="button"
                                    data-dismiss="modal" 
                                    onClick={() => {
                                        setIsResetPassword(true);
                                        // setSendVerificationEmail(false);
                                    }}
                                    className="btn btn-primary btn-block btn-black"
                                >
                                    Forgot Password ?
                                </button>

                                <button id="signUp" data-dismiss="modal" onClick={() => {
                                    setRedirect(true)
                                }} aria-label="Close" style={{
                                    fontWeight: 'bold',
                                }} className='btn btn-black btn-block' to={`/register?redirectTo=${window.location.href}`}>Create an account !</button>
                            </form>
                        </div>
                    </> :
                        <ConfirmationModal onCancelCloseModal={false} onCancel={() => {
                            setUserNotFoundErrorCount(0);
                        }} title={"Want to Sign up ?"} onSuccess={() => {
                            setRedirect(true);
                        }} message="You are trying to login with email, which is not registered with us. Do you want to continue with creating a new account with us ?" />

                }
            </div>
            {
                isResetPassword && <Navigate to={`/login?resetPassword=true&email=${email}`} />
            }
        </div>
    )
}
