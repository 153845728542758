
import { getAnalytics, logEvent } from "firebase/analytics";
const { default: axios } = require("axios");
const config = require("./config");

const sendLogs = async (type, isSuccess, data, message, error) => {
    try {
        const log = {
            type,
            time: new Date().toLocaleString(),
            success: isSuccess,
            data,
            message,
            error
        }
        const analytics = getAnalytics();
        logEvent(analytics, JSON.stringify(log));

  
        axios.post(`${config.baseUrl}/users/telemetry`, { event: log });
        // console.log(resp.data);
    } catch (err) {
        console.log(err);
    }
}

export default sendLogs;