import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../utils/config';
import sendLogs from '../../utils/telemetry';
import InlineSpinner from '../Spinners/InlineSpinner';
import Spinner from '../Spinners/Spinner';

export default function Checkout({ state, course, variant, country, enrollmentRequest, setEnrollmentRequest, coupon, couponData, setOpenDrawer }) {

    const [checked, setChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const numberWithCommas = (x) => {
        return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleEnrollment = async (amount, isOneTimePayment) => {
        try {
            if (!checked) {
                toast.error("Please accept the terms and conditions", config.toastrOption);
                return;
            }

            sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Start Creating Enrollment", null);


            setIsLoading(true)
            const response = await axios.post(`${config.baseUrl}/enrollments`, {
                course_id: course._id,
                mentorship_type: variant,
                country: country,
                isOneTimePayment: isOneTimePayment,
                couponCode: coupon,
            }, {
                headers: {
                    Authorization: `Bearer ${state.token}`
                }
            })
            if (response.data.response.status === 200) {
                // setSelectedProgram(''); 
                setEnrollmentRequest(response.data.response.data)
                if (setOpenDrawer) setOpenDrawer(false)
                sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Finish Creating Enrollment", null);
                sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Start Creating Razorpay Order", null);
                try {
                    const fetchRazorpayOrder = await axios.post(`${config.baseUrl}/payments/razorpay/order`, {
                        amount: amount * 100,
                        currency: `${country === 'India' ? 'INR' : 'USD'}`,
                    }, {
                        headers: {
                            Authorization: `Bearer ${state.token}`
                        }
                    })

                    sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Finish Creating Razorpay Order", null);

                    handlePay(response.data.response.data._id, fetchRazorpayOrder.data.response.data.order, fetchRazorpayOrder.data.response.data.key, response.data.response.data);

                } catch (err) {
                    toast.error(err.response.data.response.message || "Payment Gateway not responding !", config.toastrOption);

                    sendLogs("Checkout Modal", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Error While Creating Razorpay Order", err);
                    return;

                }
                // handlePay(response.data.response.data._id, amount
            } else {
                toast.error(response.data.response.text, config.toastrOption);
                sendLogs("Checkout Modal", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Error While Creating Enrollment", response.data.response);

            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong", config.toastrOption);
            setIsLoading(false)
            sendLogs("Checkout Modal", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Error While Creating Enrollment", { error, response: error.response });
        }
    }


    const handleEMIPayment = async (amount, currency, enrollmentRequestId) => {
        try {
            const fetchRazorpayOrder = await axios.post(`${config.baseUrl}/payments/razorpay/order`, {
                amount: amount * 100,
                currency: currency,
            }, {
                headers: {
                    Authorization: `Bearer ${state.token}`
                }
            })

            handlePay(enrollmentRequestId, fetchRazorpayOrder.data.response.data.order, fetchRazorpayOrder.data.response.data.key, enrollmentRequest);

        } catch (err) {
            console.log(err.response);
            toast.error(err.response.data.response.message || "Payment Gateway not responding !", config.toastrOption);
            return;
        }
    }

    const handlePay = (enrollmentRequestId, orderObject, key, enrollment = null) => {
        sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Start Paying", null);

        const rzp1 = new window.Razorpay({
            key: key,
            name: "Skills Stack",
            description: `Payment for ${course.name} ${variant.name}`,
            amount: orderObject.amount,
            currency: orderObject.currency,
            order_id: orderObject.id,
            prefill: {
                name: state.user.name,
                email: state.user.email,
                contact: state.user.mobile,
            },
            handler: async (response) => {
                try {
                    toast.success("Payment Successful", config.toastrOption);
                    await sendOrder(enrollmentRequestId, response.razorpay_payment_id);
                    sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Success Payment", null);
                } catch (err) {
                    toast.error("Payment Failed", config.toastrOption);
                    sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Payment Failed", null);
                }
            },
            modal: {
                ondismiss: () => {
                    toast.error("Payment Cancelled !", config.toastrOption);
                    setEnrollmentRequest(enrollment);
                    sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Closed Payment Modal", null);
                },
            },
            theme: {
                color: "#8710d8",
            },
        });
        rzp1.open();
    };

    const sendOrder = async (enrollmentRequestId, paymentId) => {
        try {
            setIsLoading(true);
            const response = await axios.post(`${config.baseUrl}/payments/razorpay/capture`, {
                payment_id: paymentId,
                enrollment_request_id: enrollmentRequestId
            }, {
                headers: {
                    Authorization: `Bearer ${state.token}`
                }
            })
            if (response.data.response.status === 200) {
                toast.success("Your Payment has been sucessful !", config.toastrOption);
                setEnrollmentRequest(response.data.response.data.enrollment_request);
                sendLogs("Checkout Modal", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Sent new Order after Payment", null);
            } else {
                toast.error("Your payment has been captured !", config.toastrOption);
                sendLogs("Checkout Modal", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Failed new Order after Payment", response.data.response);
            }

        } catch (error) {
            console.log(error.response)
            toast.error("Something went wrong", config.toastrOption);
            sendLogs("Checkout Modal", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Could not Send Order", error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDownloadCertificate = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${config.baseUrl}/enrollments/certificate/download/${enrollmentRequest._id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${state.token}`,
                    "Content-Type": "application/json",
                    "Response-Type": "blob",
                }
            })
            if (response.status === 200) {
                response.data.arrayBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Certificate.pdf`;
                    link.click();
                    link.remove();
                    toast.success("Certificate Downloaded !", config.toastrOption);
                    sendLogs("Download Certificate", true, { user: state.user || {}, course: course.name, variant: variant.name }, "Downloaded Certificate", null);
                })

            } else {
                toast.error("Could not download certificate !", config.toastrOption);
                sendLogs("Download Certificate", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Could not download certificate", response.data.response);
            }
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong", config.toastrOption);
            sendLogs("Download Certificate", false, { user: state.user || {}, course: course.name, variant: variant.name }, "Could not download certificate", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="container">
            <ToastContainer />
            <Spinner show={isLoading} />
            <div className="row">
                {
                    !enrollmentRequest ? <>
                        <div className="col-12">
                            <div className="form-group checkbox">
                                <label className='p-small' style={{
                                    color: '#000',
                                    fontSize: "0.8rem"
                                }}>
                                    <input
                                        type="checkbox"
                                        id="rterms"
                                        value="Agreed-to-Terms"
                                        name="rterms"
                                        required
                                        checked={checked}
                                        onChange={() => setChecked(!checked)}
                                    />I've read and agree to Skill Stack's written {" "}
                                    <Link target={"_blank"} to="/privacy-policy">Privacy Policy</Link> and {" "}
                                    <Link target={"_blank"} to="/terms-conditions">Terms & Conditions.</Link></label>
                                <div className="help-block with-errors">
                                </div>
                            </div>
                        </div>
                        {
                            course.is_workshop ? <>
                                <div className="col-md-6">
                                    <div className="card variant-card redirect-card">
                                        <button className='btn btn-primary btn-block' onClick={() => {
                                            // handleCheckout();
                                            handleEnrollment(country === 'India' ?
                                                variant.price_in.group - (couponData ? couponData.discount : 0) :
                                                variant.price_us.group - (couponData ? couponData.discount : 0), true);
                                        }}>Pay {country === 'India' ? '₹' : '$'} {country === 'India' ?
                                            numberWithCommas(variant.price_in.group - (couponData ? couponData.discount : 0)) :
                                            numberWithCommas(variant.price_us.group - (couponData ? couponData.discount : 0))}
                                            {
                                                isLoading && <InlineSpinner />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </> : <>
                                <div className="col-md-6">
                                    <div className="card variant-card redirect-card">
                                        <div className="card-body">
                                            <span className='variant-name bold-600'>Payment in EMIs</span>
                                            <div className="star-container">

                                                <li className='p-small bold-600'>
                                                    0% interest rate
                                                </li>

                                                <li className='p-small bold-600'>
                                                    Pay {country === 'India' ? '₹' : '$'} {country === 'India' ? numberWithCommas(variant.price_in.group) : numberWithCommas(variant.price_us.group)} for {variant.numOfMonths} months
                                                </li>
                                            </div>
                                            <button className='btn btn-primary btn-block mt-4' onClick={() => {
                                                // handleCheckout();
                                                // handleEnrollment()
                                                handleEnrollment(country === 'India' ? variant.price_in.group : variant.price_us.group, false);
                                            }}>Select EMI Option
                                                {
                                                    isLoading && <InlineSpinner />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card variant-card redirect-card">
                                        <div className="card-body">
                                            <span className='variant-name bold-600'>One Time Payment</span>
                                            <div className="star-container">
                                                <li className='p-small bold-600'>
                                                    10% Addition Discount
                                                </li>
                                                <li className='p-small bold-600'>
                                                    Pay {country === 'India' ? '₹' : '$'} {country === 'India' ?
                                                        numberWithCommas(((variant.price_in.group * variant.numOfMonths) + variant.numOfMonths) * 0.9) :
                                                        numberWithCommas(((variant.price_us.group * variant.numOfMonths) + variant.numOfMonths) * 0.9)} once
                                                </li>

                                            </div>
                                            <button className='btn btn-primary btn-block mt-4' onClick={() => {
                                                // handleCheckout();
                                                handleEnrollment(country === 'India' ?
                                                    ((variant.price_in.group * variant.numOfMonths) + variant.numOfMonths) * 0.9 :
                                                    ((variant.price_us.group * variant.numOfMonths) + variant.numOfMonths) * 0.9, true);
                                            }}>Select One Time Payment
                                                {
                                                    isLoading && <InlineSpinner />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </> : <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title" style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontSize: '1.3rem',
                                    fontWeight: 600
                                }}>
                                    Course Enrollment Details
                                    {
                                        enrollmentRequest.certificate && <span role={"button"} onClick={handleDownloadCertificate} className='btn btn-primary' style={{
                                            fontSize: '0.8rem',
                                        }} > Certificate <i className='fa fa-download ml-2'></i></span>
                                    }
                                </h4>
                            </div>
                            <div className="card-body text-black">
                                <div className="col-12">
                                    <span className=''>Enrollment Date</span>
                                    <span className="float-right font-weight-bold">{new Date(enrollmentRequest?.create_date).toDateString()}</span>
                                </div>
                                <div className="col-12 mt-2">
                                    <span className=''>Course Plan</span>
                                    <span className="float-right font-weight-bold">{enrollmentRequest?.mentorship_type}</span>
                                </div>
                                {
                                    !course.is_workshop && <div className="col-12 mt-2">
                                        <span className=''>Course Duration</span>
                                        <span className="float-right font-weight-bold">{enrollmentRequest?.course_duration} Months</span>
                                    </div>
                                }
                                <div className="col-12 mt-2">
                                    <span className=''>Total Fee</span>
                                    <span className="float-right font-weight-bold">{enrollmentRequest.course_total_fee_currency === "INR" ? '₹' : "$"} {numberWithCommas(enrollmentRequest?.course_total_fee)}</span>
                                </div>
                                <div className="col-12 mt-2">
                                    <span className=''>Fee Paid</span>
                                    <span className="float-right font-weight-bold">{enrollmentRequest.course_total_fee_currency === "INR" ? '₹' : "$"} {numberWithCommas(enrollmentRequest?.course_total_fee_paid)}</span>
                                </div>
                                {
                                    !course.is_workshop && <div className="col-12 mt-2">
                                        <span className=''>Monthly EMI</span>
                                        <span className="float-right font-weight-bold">{enrollmentRequest.course_total_fee_currency === "INR" ? '₹' : "$"} {numberWithCommas(enrollmentRequest?.course_montly_fee)}</span>
                                    </div>

                                }
                                <div className="col-12 mb-3 mt-3">

                                    <div className="card-header border ">
                                        <h4 className="card-title">
                                            Payment Details
                                        </h4>
                                    </div>
                                    <div className="table-responsive">
                                        <table className='table table-hover table-bordered text-center'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Sr. No.
                                                    </th>
                                                    <th>
                                                        Due Date
                                                    </th>
                                                    <th>
                                                        Mode
                                                    </th>
                                                    <th>
                                                        Amount
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            {
                                                course.is_workshop ? <tbody>
                                                    {
                                                        enrollmentRequest?.payments?.map((payment, index) => {
                                                            return <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{new Date(payment.date).toDateString()}</td>
                                                                <td className='text-uppercase'>{payment.paymentMode}</td>
                                                                <td>{payment.currency === "INR" ? '₹' : "$"} {numberWithCommas(payment.amount)}</td>
                                                                <td>
                                                                    <span className='best-seller-badge'>Paid</span>
                                                                </td>
                                                                <td>-</td>
                                                            </tr>
                                                        })
                                                    }
                                                    {
                                                        enrollmentRequest?.course_total_fee !== enrollmentRequest?.course_total_fee_paid && enrollmentRequest?.course_duration > 0 && new Array(enrollmentRequest.course_duration - enrollmentRequest.payments.length).fill({}).map((_, index) => {
                                                            return <tr key={index}>
                                                                <td>{enrollmentRequest.payments.length + index + 1}</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>{enrollmentRequest.course_total_fee_currency === "INR" ? '₹' : "$"} {numberWithCommas(enrollmentRequest?.course_total_fee - enrollmentRequest?.course_total_fee_paid)}</td>
                                                                <td>
                                                                    {
                                                                        index === 0 && <span className='badge badge-warning' style={{
                                                                            cursor: 'pointer',
                                                                            color: 'black',
                                                                            padding: '5px 10px',
                                                                            borderRadius: '5px'
                                                                        }}>Pending</span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <span role={"button"} onClick={() => {
                                                                        handleEMIPayment(enrollmentRequest?.course_total_fee - enrollmentRequest?.course_total_fee_paid, enrollmentRequest.course_total_fee_currency, enrollmentRequest._id);
                                                                    }} className='best-seller-badge'>Pay Now</span>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody> : <tbody>
                                                    {
                                                        enrollmentRequest?.payments?.map((payment, index) => {
                                                            return <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{new Date(payment.date).toDateString()}</td>
                                                                <td className='text-uppercase'>{payment.paymentMode}</td>
                                                                <td>{payment.currency === "INR" ? '₹' : "$"} {numberWithCommas(payment.amount)}</td>
                                                                <td>
                                                                    <span className='best-seller-badge'>Paid</span>
                                                                </td>
                                                                <td>
                                                                    -
                                                                </td>
                                                            </tr>
                                                        })
                                                    }

                                                    {
                                                        enrollmentRequest?.course_total_fee !== enrollmentRequest?.course_total_fee_paid && enrollmentRequest?.course_duration > 0 && new Array(enrollmentRequest.course_duration - enrollmentRequest.payments.length).fill({}).map((_, index) => {
                                                            return <tr key={index}>
                                                                <td>{enrollmentRequest.payments.length + index + 1}</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>{enrollmentRequest.course_total_fee_currency === "INR" ? '₹' : "$"} {numberWithCommas(enrollmentRequest?.course_montly_fee)}</td>
                                                                <td>
                                                                    {
                                                                        index === 0 && <span className='badge badge-warning' style={{
                                                                            cursor: 'pointer',
                                                                            color: 'black',
                                                                            padding: '5px 10px',
                                                                            borderRadius: '5px'
                                                                        }}>Pending</span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        index === 0 && <span role={"button"} onClick={() => {
                                                                            handleEMIPayment(enrollmentRequest.course_montly_fee, enrollmentRequest.course_total_fee_currency, enrollmentRequest._id);
                                                                        }} className='best-seller-badge'>Pay Now</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}
