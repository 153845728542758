import axios from "axios";
import React from "react";
import Context from "../../Context";
import config from "../../utils/config";
import './HomeScreen.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Spinner from "../../Components/Spinners/Spinner";
import SessionCard from "../SessionsScreen/SessionCard/SessionCard";
import Programs from "../../Components/Programs/Programs";
import sendLogs from "../../utils/telemetry";

export default function HomeScreen() {
    const { state, setState } = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sessions, setSessions] = React.useState([]);
    const [assignments, setAssignments] = React.useState([]);
    const [quizes, setQuizes] = React.useState([]);



    React.useEffect(() => {
        setState({ ...state, currentScreen: "home" });
        document.title = "Skills Stack Dashboard";
        sendLogs("Home Page Visit", true, state.user || {}, "Started Viewing Home Page", null);

        return () => {
            return null;
        }
    }, [])

    const getDashboardData = async () => {
        try {
            setIsLoading(true);
            const res = await axios.get(`${config.baseUrl}/users/dashboard`, {
                headers: {
                    "Authorization": `Bearer ${state.token}`
                }
            });
            setSessions(res.data.response.data.sessions);
            setAssignments(res.data.response.data.assignments);
            // setQuizes(res.data.response.data.quizes);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error("Error getting dashboard data", config.toastrOption);
        }
    }


    React.useEffect(() => {
        if (state.isLoggedIn) {
            getDashboardData();
        }
    }, [state.isLoggedIn]);

    return (
        <div className="main-container">
            <div className="container-fluid homepage-container">

                <ToastContainer />
                <Spinner show={isLoading} />
                <div className="row">
                    <div className="col-md-12">
                        <p className="h2">{state.isLoggedIn ? `Welcome back ${state.user?.name.split(" ")[0]},` : `Welcome to Skills Stack`}</p>
                    </div>
                </div>

                {
                    state.isLoggedIn && <div>
                        {
                            sessions && sessions.length > 0 && <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <span className="course-heading-message">Your Upcoming Sessions</span>
                                        <Link to={'/sessions'} className="course-heading-button"> <span className="course-heading-text">View All</span> <i className="fas fa-arrow-circle-right"></i></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        sessions.length > 0 ? sessions.map((allSessions, index) => {
                                            return allSessions.sessions.map((session, index) => {
                                                return (
                                                    <SessionCard index={index} course={allSessions.group.course.name} session={session} />
                                                )
                                            })

                                        }) : <div className="col-12 empty-container">
                                            <p className="no-session-message">No upcoming sessions</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            assignments && assignments.length > 0  && <div className="container-fluid mb-4">
                                <div className="row mt-4">
                                    <div className="col-md-12 mb-4">
                                        <span className="course-heading-message">Your Upcoming Assignments</span>
                                        {/* <Link to={'/sessions'} className="course-heading-button"> <span className="course-heading-text">View All</span> <i className="fas fa-arrow-circle-right"></i></Link> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        assignments.length > 0 ? assignments.map((allSessions, ind) => {
                                            // return allSessions.assignments.map((session, index) => {
                                            //     const startDate = SubtractMinutesToDate(new Date(session.start_datetime), 330);
                                            //     const dueDate = SubtractMinutesToDate(new Date(session.due_datetime), 330);
                                            //     return (
                                            //         <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 session-card" key={index} >
                                            //             <div className="card session-detail-card" key={index}>
                                            //                 <div className="card session-detail-card" key={index}>
                                            //                     <div className="card-body session-detail-card-body">
                                            //                         <p title={session.topic} className="session-card-title">{session.title}</p>
                                            //                         <hr />
                                            //                         <p className="session-card-text">{allSessions.group.course.name}</p>
                                            //                         <hr />
                                            //                         <p className="session-card-text"><i className="far fa-clock" aria-hidden="true"></i> {startDate.toDateString()}, {formatAMPM(startDate)}</p>
                                            //                         <hr />
                                            //                         <p className="session-card-text"><i className="fas fa-clock" aria-hidden="true"></i> {dueDate.toDateString()}, {formatAMPM(dueDate)}</p>

                                            //                     </div>

                                            //                     <div className="session-card-footer">
                                            //                         {session?.document_link && startDate <= new Date() ? <a href={session.document_link} target="_blank" className="mt-end btn btn-primary">View Assignment <i className="fa fa-unlock" aria-hidden="true"></i></a> :
                                            //                             <button className="mt-end btn btn-secondary"><i className="fa fa-lock" aria-hidden="true"></i></button>}
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     )
                                            // })

                                        }) : <div className="col-12 empty-container">
                                            <p className="no-session-message">No upcoming assignments</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {
                            quizes && quizes.length > 0 && <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="course-heading-message">Your Upcoming Quizzes</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        quizes.length > 0 ? sessions.map((allSessions, index) => {
                                            return allSessions.sessions.map((session, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 session-card" key={index} >
                                                        <div className="card session-detail-card" key={index}>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{session.topic}</h5>
                                                                <p className="card-text">{allSessions.group.course.name}</p>
                                                                <p className="card-text">{new Date(session.start_datetime).toLocaleString()}</p>
                                                                {session?.session_link ? <a href={session.session_link} target="_blank" className="mt-end btn btn-primary">View Session</a> :
                                                                    <button disabled className="mt-end btn btn-primary">View Session</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                        }) : <div className="col-12 empty-container">
                                            <p className="no-session-message">No upcoming quizzes</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }


                <Programs />


            </div >
        </div>
    );
}
