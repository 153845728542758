import React from 'react'
import propTypes from 'prop-types'
import { toast, ToastContainer } from 'react-toastify'
import config from '../../utils/config'
import Context from '../../Context'
import axios from 'axios'
import InlineSpinner from '../../Components/Spinners/InlineSpinner'
export default function AddImageModal(props) {

    const [url, setUrl] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [isNewImage, setIsNewImage] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)
    const [image, setImage] = React.useState(null)
    const [imageName, setImageName] = React.useState('')
    const { state } = React.useContext(Context)


    const uploadImage = async (e) => {
        setIsLoading(true)
        try {
            if (!image) {
                toast.error('Please select an image', config.toastrOption)
                return
            }
            const formData = new FormData()
            formData.append('image', image)
            const resp = await axios.post(`${config.baseUrl}/posts/image/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${state.token}`,
                }
            })
            if (resp.data.response.status === 200) {
                // toast.success("Image Uploaded Successfully", config.toastrOption);
                // setUrl()
                props.setImage({ url: `${config.baseUrl}/images/posts/${resp.data.response.data.imageName}`, title })
                document.getElementById('add-image-modal-close-button').click()
                setTitle('');
                setImage(null);
            } else {
                toast.error("Image Upload Failed", config.toastrOption);
            }
        } catch (error) {
            toast.error("Image Upload Failed", config.toastrOption);
        }
        setIsLoading(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isNewImage) {
            uploadImage()
        } else {
            props.setImage({ url, title })
            document.getElementById('add-image-modal-close-button').click()
        }
    }

    return (
        <div className="modal-dialog modal-dialog-centered" role="document">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="AddImageModalLabel">Add an Image</h5>
                    <button id='add-image-modal-close-button' type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group form-check">
                            <input type="checkbox" onChange={(e) => {
                                setIsNewImage(e.target.checked)
                            }} className="form-check-input" id="have-the-image" value={isNewImage} checked={isNewImage} />
                            <label className="form-check-label" htmlFor="have-the-image">Don't have the image URL ?</label>
                        </div>
                        {
                            !isNewImage ? <div className="form-group">
                                <label htmlFor="link-url">URL</label>
                                <input value={url} type="url" required={true} onChange={(e) => setUrl(e.target.value)} className="form-control" id="link-url" placeholder="Enter URL" />
                            </div> : <div className='form-group'>
                                <label htmlFor="image-title">Image</label>
                                <input  type={'file'} required onChange={(e) => {
                                    if (e.target.files[0].type.includes('image')) {
                                        setImage(e.target.files[0])
                                    } else {
                                        toast.warning('Please select an image', config.toastrOption)
                                    }
                                }} className="form-control" id="image-title" accept="image/*" placeholder="Enter Image Title" />
                            </div>
                        }

                        <div className="form-group">
                            <label htmlFor="link-title">Title
                               &nbsp; <small>(Add a text for your image, like alt for image)</small>
                            </label>
                            <input value={title} type="text" required={true} onChange={(e) => setTitle(e.target.value)} className="form-control" id="link-title" placeholder="Enter title" />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Add Image {
                            isLoading && <InlineSpinner />
                        } </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

AddImageModal.propTypes = {
    setImage: propTypes.func.isRequired
}